<script lang="ts" setup>
import { useAttrs } from 'vue';
import Header from './Header.vue';

const props = withDefaults(defineProps<{
    internalHeader?: string,
    externalHeader?: string,
    internalIcon?: any,
    externalIcon?: any,
    size?: 'sm' | 'md' | 'lg'
}>(), {
    internalHeader: undefined,
    externalHeader: undefined,
    internalIcon: undefined,
    externalIcon: undefined,
    size: 'md'
});

const attrs = useAttrs();
</script>

<template>
    <div class="text-neutral-900 dark:text-neutral-300">
        <Header
            v-bind="{
                title: externalHeader,
                icon: externalIcon,
                size
            }">
            <template #actions>
                <slot name="actions" />
            </template>    
        </Header>
        <div
            :class="attrs.class"
            class="dark:from-neutral-750 dark:to-neutral-850 flex grow flex-col rounded bg-gradient-to-b from-white to-neutral-50 p-4 shadow">
            <slot name="internal-header" />
            <slot />
            <slot name="footer" />
        </div>
    </div>
</template>
