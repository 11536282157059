
import { type ChartType, type Plugin } from 'chart.js/auto';
import type { Chart } from 'chart.js/dist';
import { getRelativePosition } from 'chart.js/helpers';

type MouseLineOptions = {
    x: number | false;
    color: string
}

export const mouseLine: Plugin<'line', MouseLineOptions> = {
    id: 'mouseLine',
    afterEvent: (chart, { event }, options) => {

        const inChartArea = event.x && event.y ? (
            event.x >= chart.chartArea.left
            && event.y >= chart.chartArea.top
            && event.x <= chart.chartArea.right
            && event.y <= chart.chartArea.bottom
        ) : false;

        if(inChartArea) {
            const canvasPosition = getRelativePosition(event, chart as unknown as Chart);

            const value = chart.scales.x.getValueForPixel(canvasPosition.x);

            if(value) {
                options.x = chart.scales.x.getPixelForValue(value);
            }
        }
        else {
            options.x = false;
        }
    },
    afterDatasetsDraw: (chart, _, options: MouseLineOptions) => {
        if(options.x) {
            chart.ctx.beginPath();
            chart.ctx.strokeStyle = options.color;
            chart.ctx.lineWidth = 2;
            chart.ctx.moveTo(options.x, chart.chartArea.bottom);
            chart.ctx.lineTo(options.x, chart.chartArea.top);
            chart.ctx.stroke();
        }            
    }
};

// Chart.register(mouseLine);

// Define the plugin with its options type
declare module 'chart.js' {
    export interface PluginOptionsByType<TType extends ChartType> {
        mouseLine: MouseLineOptions
    }
}