<script lang="ts" setup>
import { ArrowDownIcon, ArrowUpIcon, PlusIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { InputField } from '@vue-interface/input-field';
import { InputList } from '@vue-interface/input-list';
import type { MgDataVariable } from 'types';
import { ref, watchEffect } from 'vue';

const props = defineProps<{
    item: MgDataVariable,
    index: number,
    errors: any,
}>();

const itemRef = ref<MgDataVariable>(props.item);

if(!itemRef.value.options) {
    itemRef.value.options = [];
}

function add() {
    itemRef.value.options?.push({ value: null, label: '' });
}

function up(index: number) {
    if(index === 0) {
        return;
    }

    const item = itemRef.value.options?.splice(index, 1);
    itemRef.value.options?.splice(index - 1, 0, ...item!);
}

function down(index: number) {
    if(!itemRef.value.options) {
        return;
    }

    if(index === itemRef.value.options.length - 1) {
        return;
    }

    const item = itemRef.value.options?.splice(index, 1);
    
    itemRef.value.options?.splice(index + 1, 0, ...item!);
}

watchEffect(() => {
    itemRef.value.defaultLabel = itemRef.value.defaultLabel ?? '';
});

</script>

<template>
    <div>
        <div
            class="my-2 flex items-center gap-x-2">
            <div class="flex w-full gap-2">
                <InputField
                    disabled
                    label="Default Value"
                    class="w-full"
                    :model-value="itemRef.defaultValue" />
                <InputField
                    :id="`options.mg_data_variables.${index}.defaultLabel`"
                    v-model="itemRef.defaultLabel"
                    label="Default Label"
                    :name="`options.mg_data_variables.${index}.defaultLabel`"
                    :error="errors[`options.mg_data_variables.${index}.defaultLabel`]"
                    class="w-full" />
            </div>
            <button
                class="btn btn-sm mt-6 btn-neutral-300 dark:btn-neutral-900"
                @click.prevent="add()">
                <PlusIcon
                    class="h-6 w-6" />
            </button>
        </div>
        <InputList :items="item.options">
            <template #default="{ index: optionsIndex, item: optionsItem, remove: optionsRemove }">
                <div class="my-4 flex items-center gap-2">
                    <div class="flex w-full gap-2">
                        <InputField
                            :id="`options.mg_data_variables.${index}.options.${optionsIndex}.value`"
                            v-model="optionsItem.value"
                            :label="`Value ${optionsIndex + 1}`"
                            :name="`options.mg_data_variables.${index}.options.${optionsIndex}.value`"
                            :error="errors[`options.mg_data_variables.${index}.options.${optionsIndex}.value`]"
                            class="w-full" />
                        <InputField
                            :id="`options.mg_data_variables.${index}.options.${optionsIndex}.label`"
                            v-model="optionsItem.label"
                            :label="`Label ${optionsIndex + 1}`"
                            :name="`options.mg_data_variables.${index}.options.${optionsIndex}.label`"
                            :error="errors[`options.mg_data_variables.${index}.options.${optionsIndex}.label`]"
                            class="w-full" />
                    </div>
                    <div class="mt-6 flex gap-2">
                        <button
                            class="btn btn-xs btn-neutral-300 dark:btn-neutral-900"
                            @click.prevent="up(optionsIndex)">
                            <ArrowUpIcon
                                class="h-5 w-5" />
                        </button>
                        <button
                            class="btn btn-xs btn-neutral-300 dark:btn-neutral-900"
                            @click.prevent="down(optionsIndex)">
                            <ArrowDownIcon
                                class="h-5 w-5" />
                        </button>
                        <button
                            class="btn btn-xs btn-red-500"
                            @click.prevent="optionsRemove()">
                            <TrashIcon
                                class="h-5 w-5" />
                        </button>
                    </div>
                </div>
            </template>
        </InputList>
    </div>
</template>