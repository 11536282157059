<script lang="ts" setup generic="T">
import type { Component, FunctionalComponent } from 'vue';
import Header from './Header.vue';

const props = defineProps<{
    badges?: string[],
    description?: string,
    icon?: Component|FunctionalComponent|string,
    title?: string
}>();
</script>

<template>
    <Header
        size="lg"
        class="border-b border-neutral-200 dark:border-neutral-700 pb-5 mb-8"
        v-bind="props">
        <template #icon>
            <slot name="icon" />
        </template>
        <template #title>
            <slot name="title" />
        </template>
        <template #description>
            <slot name="description" />
        </template>
        <template #badges>
            <slot name="badges" />
        </template>
        <template #actions>
            <slot name="actions" />
        </template>
    </Header>

    <div class="flex flex-col gap-y-10">
        <slot />
    </div>
</template>