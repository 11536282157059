<script lang="ts" setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { Motion, Presence } from 'motion/vue';
import { ref } from 'vue';
import { echo } from '../plugins/Echo';

const maintenanceMode = ref(false);

echo.channel('server')
    .listen('MaintenanceModeEnabled', () => {
        maintenanceMode.value = true;
    })
    .listen('MaintenanceModeDisabled', () => {
        maintenanceMode.value = false;
    });
</script>
  
<template>
    <Presence>
        <Motion
            v-if="maintenanceMode"
            class="sticky top-0 z-10 w-full bg-amber-500"
            :initial="{ y: '-100%' }"
            :animate="{ y: 0 }"
            :exit="{ y: '-100%', position: 'absolute' }">
            <div class="mx-auto flex h-14 items-center justify-center gap-x-2 text-white">
                <ExclamationTriangleIcon class="h-6 w-6" />
                The server is currently unavailable while in maintenance mode.
            </div>
        </Motion>
    </Presence>
</template>