<script setup lang="ts">
import { Link } from '@inertiajs/vue3';
import AdminNavigation from '../components/AdminNavigation.vue';
import { route } from '../composables/routes';
import Layout, { type LayoutProps } from './Layout.vue';

const props = defineProps<LayoutProps>();
</script>

<template>
    <Layout v-bind="props">
        <template #navigation>
            <AdminNavigation
                :auth-user="authUser"
                :breadcrumbs="breadcrumbs"
                :can-view-any="canViewAny"
                :can-view="canView">
                <template #default>
                    <Link
                        v-if="canViewAny.instance"
                        :href="route('instances.index')"
                        :class="{'active': $page.url.startsWith(`/admin/instances`)}">
                        Instances
                    </Link>
                    <Link
                        v-if="canViewAny.agency"
                        :href="route('agencies.index')"
                        :class="{'active': $page.url.startsWith(`/admin/agencies`)}">
                        Agencies
                    </Link>
                    <Link
                        v-if="authUser.super_admin"
                        :href="route('users.index')"
                        :class="{'active': $page.url.startsWith(`/admin/users`)}">
                        Users
                    </Link>
                    <Link
                        v-if="canViewAny.audit"
                        :href="route('activity')"
                        :class="{'active': $page.url.startsWith(`/admin/activity`)}">
                        Activity
                    </Link>
                </template>
            </AdminNavigation>
        </template>
        <slot />
    </Layout> 
</template>