<script lang="ts" setup>
import { BuildingOfficeIcon, ClipboardDocumentListIcon, EnvelopeIcon, ExclamationCircleIcon, InformationCircleIcon, LockOpenIcon, PencilSquareIcon, PlusCircleIcon, Square2StackIcon, TrashIcon, UserGroupIcon, UserIcon } from '@heroicons/vue/24/outline';
import { Link, router } from '@inertiajs/vue3';
import { SlidePanel, useSlidePanels } from '@vue-interface/slide-panel';
import type { Agency, AgencyUser, Audit, Can, LengthAwarePaginator, ListUser, SubscriberList, User } from 'types';
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import type { ComponentExposed } from 'vue-component-type-helpers';
import { useToast } from 'vue-toastification';
import AdminLayout from '../Layouts/AdminLayout.vue';
import axios from '../axios';
import ActionButton from '../components/ActionButton.vue';
import Banner from '../components/Banner.vue';
import DetailsView from '../components/DetailsView.vue';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import Show from '../components/Show.vue';
import UserAgencyForm from '../components/UserAgencyForm.vue';
import UserListForm from '../components/UserListForm.vue';
import BadgeDescription from '../components/audits/BadgeDescription.vue';
import Description from '../components/audits/Description.vue';
import IconDescription from '../components/audits/IconDescription.vue';
import Trend from '../components/metrics/Trend.vue';
import Value from '../components/metrics/Value.vue';
import { route } from '../composables/routes';
import { titleCase } from '../composables/titleCase';
import { echo } from '../plugins/Echo';

const props = defineProps<{
    agencyCan: Can;
    agencies?: LengthAwarePaginator<Agency & { pivot: AgencyUser }>;
    authUser: User;
    listCan: Can;
    lists?: LengthAwarePaginator<SubscriberList & { pivot: ListUser }>;
    user: User;
    userAgencies?: (Agency & { pivot: AgencyUser })[]
    userLists?: (SubscriberList & { pivot: ListUser })[],
    audits?: LengthAwarePaginator<Audit>;
}>();

const only = ['agencies', 'lists', 'userAgencies', 'userLists', 'audits'];

defineOptions({
    layout: AdminLayout
});

const toast = useToast();
const { open, close } = useSlidePanels();

const agencyUser = ref<AgencyUser|undefined>();
const agenciesSearch = ref<ComponentExposed<typeof SearchableListGroup<Agency>>>();

const listUser = ref<ListUser|undefined>();
const listsSearch = ref<ComponentExposed<typeof SearchableListGroup>>();
    
agenciesSearch.value?.reload();

function onClickSendActivation(user: User) {
    if(confirm('Are you sure you want to send an activation email?')) {
        router.put(route('users.activation-email', { user: user.id }));
    }
}

function onClickCopyActivationUrl(user: User) {
    navigator.clipboard.writeText(user.activation_url).then(() => {
        toast.info('The activation link has been copied to your clipboard!');
    }, () => {
        toast.error('Unable to copy the activation link to your clipboard.');
    });
}

function onClickUpdateAgencyUser(agency: Agency & { pivot: AgencyUser }) {
    agencyUser.value = agency.pivot;
    
    open('user-agency-form');
}

function onSubmitUserAgencyForm() {
    close('user-agency-form');

    agenciesSearch.value?.reload();
}

function onClickUpdateListUser(list: SubscriberList & { pivot: ListUser }) {
    listUser.value = list.pivot;

    open('user-list-form');
}

function onSubmitUserListForm() {
    close('user-list-form');

    listsSearch.value?.reload();
}

function onClickRemoveAgencyUser(user: User, agency: Agency & { pivot: AgencyUser }) {
    if(confirm(`Are you sure you want to remove ${agency.name} from ${user.email}?`)) {
        axios.delete(route('api.users.agencies.destroy', { user: user.id, agency: agency.id }))
            .then(() => {
                agenciesSearch.value?.reload();
                listsSearch.value?.reload();
            });
    }
}

function onClickRemoveListUser(user: User, list: SubscriberList & { pivot: ListUser }) {
    if(confirm(`Are you sure you want to remove ${list.name} from ${user.email}?`)) {
        axios.delete(route('api.users.lists.destroy', { user: user.id, list: list.id }))
            .then(() => {
                listsSearch.value?.reload();
            });
    }
}

function onClickDeleteUser(user: User) {
    if(confirm(`Are you sure you want to delete ${user.email}?`)) {
        router.delete(route('users.destroy', { user: user.id }));
    }
}

function impersonate(user: User) {
    if(confirm(`Are you sure you want to login as ${user.name}?`)) {
        window.location.href = `/impersonate/${user.id}`;
    }
}

onBeforeMount(() => {
    echo.private(`App.Models.User.${props.user.id}`)
        .listen('.UserUpdated', () => router.reload({ only: [...only, 'user'] }))
        .listen('.UserDeleted', () => router.reload({ only: [...only, 'user'] }))
        .listen('.AuditCreated', () => router.reload({ only: [...only, 'user'] }))
        .listen('.AuditUpdated', () => router.reload({ only: [...only, 'user'] }))
        .listen('.AuditDeleted', () => router.reload({ only: [...only, 'user'] }));
});

onBeforeUnmount(() => {
    echo.private(`App.Models.User.${props.user.id}`)
        .stopListening('.UserUpdated')
        .stopListening('.UserDeleted')
        .stopListening('.AuditCreated')
        .stopListening('.AuditUpdated')
        .stopListening('.AuditDeleted');
});
</script>

<template>
    <Show
        :icon="UserIcon"
        :title="user.name"
        :description="user.email"
        :badges="user.super_admin ? ['Super Admin'] : []">
        <template #actions>
            <ActionButton>
                <Link
                    :href="route('users.edit', { user: user.id })"
                    class="group flex items-center">
                    <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit User
                </Link>
                <button
                    v-if="user.activated_at && user.id !== authUser.id"
                    class="group flex items-center"
                    @click="impersonate(user)">
                    <LockOpenIcon class="my-1 mr-3 h-5 w-5" /> Login as User
                </button>
                <button
                    v-if="!user.activated_at"
                    class="group flex items-center"
                    @click="onClickSendActivation(user)">
                    <EnvelopeIcon class="my-1 mr-3 h-5 w-5" /> Re-Send Activation Email
                </button>
                <button
                    v-if="!user.activated_at"
                    class="group flex items-center"
                    @click="onClickCopyActivationUrl(user)">
                    <Square2StackIcon class="my-1 mr-3 h-5 w-5" /> Copy Activation Link
                </button>
                <button
                    v-if="!user.super_admin"
                    class="group flex items-center"
                    @click="open('user-agency-form')">
                    <BuildingOfficeIcon class="my-1 mr-3 h-5 w-5" /> Assign to Agency
                </button>
                <button
                    v-if="!user.super_admin"
                    class="group flex items-center"
                    @click="open('user-list-form')">
                    <UserGroupIcon class="my-1 mr-3 h-5 w-5" /> Assign to List
                </button>
                <hr>
                <button
                    class="group flex items-center"
                    @click="onClickDeleteUser(user)">
                    <TrashIcon class="my-1 mr-3 h-5 w-5" /> Delete User
                </button>
            </ActionButton>
        </template>

        <Banner
            v-if="!user.super_admin && !user.has_assignments"
            class="!bg-amber-300 dark:!bg-purple-300 dark:!text-neutral-800">
            <InformationCircleIcon class="h-8 w-8" />
            This user is not assigned to any Agencies or Lists.
        </Banner>
        
        <div
            class="grid auto-cols-fr grid-flow-col gap-4">
            <Trend
                url="metrics/sends/trend"
                :params="{user: user.id}"
                class="flex-1" />
            <Value
                v-if="!user.super_admin"
                url="metrics/lists/value"
                :params="{user: user.id}"
                class="flex-1" />
        </div>

        <SlidePanel
            name="user-agency-form"
            @close="agencyUser = undefined">
            <UserAgencyForm
                :agency-user="agencyUser"
                namespace="user-agencies"
                :user="user"
                :user-agencies="userAgencies"
                @success="onSubmitUserAgencyForm" />
        </SlidePanel>

        <div class="flex flex-col gap-y-12">
            <SearchableListGroup
                ref="agenciesSearch"
                size="md"
                singular="agency"
                plural="agencies"
                namespace="agencies"
                :only="only"
                :response="agencies"
                :icons="{
                    default: BuildingOfficeIcon
                }"
                delete-label="remove"
                :routes="{
                    index: route('users.show', { user: user.id }),
                    show: agency => route('agencies.show', { agency: agency.id })
                }"
                :can="{
                    view: agency => agency.can?.view,
                    update: agency => agency.can?.update,
                    delete: agency => agency.can?.delete,
                    // create: agencyCan.create
                }"
                :badges="agency => agency.pivot?.role ? [titleCase(agency.pivot.role)] : []"
                :echo="{
                    listen: ({ reload }) => {
                        echo.private(`App.Models.User.${user.id}`)
                            .listen('.AgencyUserCreated', () => reload())
                            .listen('.AgencyUserUpdated', () => reload())
                            .listen('.AgencyUserDeleted', () => reload())

                    },
                    stopListening: () => {
                        echo.private(`App.Models.User.${user.id}`)
                            .stopListening('.AgencyUserCreated')
                            .stopListening('.AgencyUserUpdated')
                            .stopListening('.AgencyUserDeleted',)
                    }
                }">
                <template #create-action>
                    <button
                        class="btn flex h-8 w-8 items-center rounded-full p-0 outline-none focus:ring active:ring dark:text-neutral-400 dark:ring-rose-500 dark:hover:text-neutral-200"
                        @click="open('user-agency-form')">
                        <PlusCircleIcon class="h-full w-full" />
                    </button>
                </template>
                <template #list-actions-links="{ model: agency }">
                    <button
                        class="group flex items-center capitalize"
                        @click="onClickUpdateAgencyUser(agency)">
                        <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit Role
                    </button>
                    <hr>
                    <button
                        class="group flex items-center capitalize"
                        @click="onClickRemoveAgencyUser(user, agency)">
                        <TrashIcon class="my-1 mr-3 h-5 w-5" /> Remove Agency
                    </button>
                </template>
                <template #icon="{ model: agency }">
                    <div
                        class="relative">
                        <BuildingOfficeIcon class="h-8 w-8" />
                        <ExclamationCircleIcon
                            v-if="!agency.pivot?.accessed_at"
                            title="Never Accessed"
                            class="absolute right-0 top-0 h-5 w-5 -translate-y-1/3 translate-x-1/4 fill-sky-400 stroke-white dark:fill-lime-300 dark:stroke-neutral-800" />
                    </div>
                </template>
            </SearchableListGroup>

            <SlidePanel
                name="user-list-form"
                @close="listUser = undefined; agencyUser = undefined">
                <UserListForm
                    :list-user="listUser"
                    :user="user"
                    :user-lists="userLists"
                    namespace="user-lists"
                    @success="onSubmitUserListForm" />
            </SlidePanel>

            <SearchableListGroup
                ref="listsSearch"
                size="md"
                singular="list"
                plural="lists"
                namespace="lists"
                :only="only"
                :response="lists"
                :icons="{
                    default: UserGroupIcon
                }"
                :routes="{
                    index: route('users.show', { user: user.id }),
                    show: list => route('agencies.lists.show', {
                        agency: list.agency_id,
                        list: list.id
                    })
                }"
                :can="{
                    view: list => list.can?.view,
                    update: list => list.can?.update,
                    delete: list => list.can?.delete,
                    // create: listCan.create,
                }"
                :badges="list => list.pivot?.role ? [titleCase(list.pivot.role)] : []"
                :echo="{
                    listen: ({ reload }) => {
                        echo.private(`App.Models.User.${user.id}`)
                            .listen('.ListUserCreated', () => reload())
                            .listen('.ListUserUpdated', () => reload())
                            .listen('.ListUserDeleted', () => reload())

                    },
                    stopListening: () => {
                        echo.private(`App.Models.User.${user.id}`)
                            .stopListening('.ListUserCreated')
                            .stopListening('.ListUserUpdated')
                            .stopListening('.ListUserDeleted')
                    }
                }">
                <template #create-action>
                    <button
                        class="btn flex h-8 w-8 items-center rounded-full p-0 outline-none focus:ring active:ring dark:text-neutral-400 dark:ring-rose-500 dark:hover:text-neutral-200"
                        @click="open('user-list-form')">
                        <PlusCircleIcon class="h-full w-full" />
                    </button>
                </template>
                <template #list-actions-links="{ model:list }">
                    <button
                        class="group flex items-center capitalize"
                        @click="onClickUpdateListUser(list)">
                        <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit Role
                    </button>
                    <hr>
                    <button
                        class="group flex items-center capitalize"
                        @click="onClickRemoveListUser(user, list)">
                        <TrashIcon class="my-1 mr-3 h-5 w-5" /> Remove List
                    </button>
                </template>
                <template #icon="{ model: list }">
                    <div
                        class="relative">
                        <UserGroupIcon class="h-8 w-8" />
                        <ExclamationCircleIcon
                            v-if="!list.pivot?.accessed_at"

                            title="Never Accessed"
                            class="absolute right-0 top-0 h-5 w-5 -translate-y-1/3 translate-x-1/4 fill-sky-400 stroke-white dark:fill-lime-300 dark:stroke-neutral-800" />
                    </div>
                </template>
            </SearchableListGroup>

            <DetailsView
                :data="user"
                :fields="[
                    { name: 'logged_in_at', label: 'Last Login', type: Date, format: 'PPPp' },
                    { name: 'activated_at', label: 'Activated', type: Date, format: 'PPPp' },
                    { name: 'updated_at', label: 'Updated', type: Date, format: 'PPPp' },
                    { name: 'created_at', label: 'Created', type: Date, format: 'PPPp' },
                    { name: 'timezone_label', label: 'Timezone' },
                ]" />

            <SearchableListGroup
                size="md"
                header="Activity"
                namespace="audits"
                singular="audit"
                plural="audits"
                :filters="false"
                :only="only"
                :response="audits"
                :icons="{
                    default: ClipboardDocumentListIcon
                }">
                <template #icon="{ model }">
                    <IconDescription
                        v-if="model.description?.icon"
                        :description="model.description.icon" />
                </template>
                <template #badges="{ model }">
                    <BadgeDescription
                        v-if="model.description?.badge"
                        :description="model.description.badge" />
                </template>
                <template #title="{ model }">
                    <Description
                        v-if="model.description"
                        :description="model.description" />
                    <template v-else>
                        Audit description not available.
                    </template>
                </template>
            </SearchableListGroup>
        </div>
    </Show>
</template>