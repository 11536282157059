<script lang="ts" setup>
import { ArrowUpTrayIcon, MagnifyingGlassIcon, PencilSquareIcon, PlusIcon, UserIcon } from '@heroicons/vue/24/outline';
import { Link, router } from '@inertiajs/vue3';
import { InputField } from '@vue-interface/input-field';
import { debounce, pickBy } from 'lodash-es';
import type { Agency, Can, Subscriber, SubscriberList, User } from 'types';
import { ref, watch } from 'vue';
import SubscriberIcon from '../../svg/subscriber-icon.svg';
import ActionButton from '../components/ActionButton.vue';
import Flatfile from '../components/Flatfile.vue';
import Header from '../components/Header.vue';
import ListGroup from '../components/ListGroup.vue';
import ListGroupItem from '../components/ListGroupItem.vue';
import SubscriberForm from '../components/SubscriberForm.vue';
import Tab from '../components/Tab.vue';
import Tabs from '../components/Tabs.vue';
import Value from '../components/metrics/Value.vue';
import { route } from '../composables/routes';

const props = defineProps<{
    agency: Agency
    list: SubscriberList
    subscriber?: Subscriber;
    authUser: User
    states: any
    can: Can<['upload']>
    queryParams: {
        email?: string
    }
}>();

const email = ref(props.queryParams.email);

watch(email, debounce(() => {
    router.get(route('subscribers.index', { agency: props.agency.slug, list: props.list.slug }), pickBy({
        email: email.value,
    }), {
        preserveState: true,
    });
}, 300));
</script>

<template>
    <div>
        <Header
            size="lg"
            :icon="SubscriberIcon"
            title="Subscribers" />

        <div class="grid auto-cols-fr grid-flow-col gap-4 pb-8">
            <Value
                url="metrics/subscribes/value"
                :params="{
                    list: list.id,
                }" />
            <Value
                url="metrics/unsubscribes/value"
                :params="{
                    list: list.id,
                }" />
        </div>

        <Tabs>
            <Tab
                v-if="can.viewAny"
                header="Search Email"
                :icon="MagnifyingGlassIcon">
                <InputField
                    v-model="email"
                    class="mb-4 input-field-lg"
                    placeholder="Search Email" />
                <ListGroup>
                    <ListGroupItem
                        v-if="subscriber"
                        :icon="UserIcon"
                        :title="subscriber.email"
                        :href="route('subscribers.show', { agency: agency.slug, list: list.slug, subscriber: subscriber.id })">
                        <template #list-actions>
                            <ActionButton size="sm">
                                <Link
                                    v-if="can.update"
                                    :href="route('subscribers.edit', { agency: agency.slug, list: list.slug, subscriber: subscriber.id })"
                                    class="group flex items-center">
                                    <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit Subscriber
                                </Link>
                            </ActionButton>
                        </template>
                    </ListGroupItem>
                    <ListGroupItem
                        v-else-if="email"
                        :icon="UserIcon">
                        No subscriber found. Please search using the full email address.
                    </ListGroupItem>
                </ListGroup>
            </Tab>
            <Tab
                v-if="can.create"
                :icon="PlusIcon"
                header="Add Subscriber">
                <SubscriberForm
                    :states="states"
                    :url="route('subscribers.store', { agency: agency.slug, list: list.slug })" />
            </Tab>
            <Tab
                v-if="can.upload"
                :icon="ArrowUpTrayIcon"
                header="Upload File">
                <Flatfile
                    :auth-user="authUser"
                    :list="list" />
            </Tab>
        </Tabs>
    </div>
</template>