<script setup lang="ts">
import { usePage } from '@inertiajs/vue3';
import { CheckboxField } from '@vue-interface/checkbox-field';
import { intersection } from 'lodash-es';
import { reactive, watchEffect } from 'vue';

export type UserAbilities = Record<string,Record<string,boolean>>;

const props = defineProps<{
    modelValue?: UserAbilities
}>();

const emit = defineEmits<{
    'update:modelValue': [value: UserAbilities]
}>();

export type UserAbilityChild = {
    label: string;
    name: string[];
    depends?: string[];
};

export type UserAbilityGroup = {
    name: string;
    label: string;
    children: UserAbilityChild[]
};

const page = usePage<{
    abilities: UserAbilityGroup[]
}>();

function isGroupChecked(group: string) {
    for(const value of Object.values(model[group] ?? {})) {
        if(value) {
            return true;
        }
    }

    return false;
}

function isPermissionChecked(group: string, permission: UserAbilityChild) {
    for(const name of permission.name) {
        if(!model[group][name]) {
            return false;
        }
    }

    return true;
}

const model = reactive(page.props.abilities.reduce<UserAbilities>(
    (carry, { name, children }) => {
        return Object.assign(
            carry, {
                [name]: children
                    .map(({ name }) => name)
                    .flat(1)
                    .filter((value, index, array) => array.indexOf(value) === index)
                    .reduce((carry, key) => {
                        return Object.assign(carry, {
                            [key]: props.modelValue?.[name]?.[key] ?? false
                        });
                    }, {})
            });
    }, {}
));

function onClickGroup(event: Event, group: string) {
    const checked = (event.target as HTMLInputElement).checked;
    
    for(const key in model[group]) {
        model[group][key] = checked;
    }
}

function onClickPermission(event: Event, group: UserAbilityGroup, permission: UserAbilityChild) {
    const checked = (event.target as HTMLInputElement).checked;

    for(const name of permission.name) {
        model[group.name][name] = checked;
    }

    if(checked && permission.depends) {
        for(const name of permission.depends) {
            model[group.name][name] = true;
        }
    }
    else if(!checked) {
        for(const child of group.children) {
            if(!intersection(permission.name, child.depends).length) {
                continue;
            }

            for(const name of child.name) {
                model[group.name][name] = false;
            }
        }
    }
}

watchEffect(() => {
    emit('update:modelValue', model);
});
</script>

<template>
    <div class="flex flex-col gap-2">
        <div
            v-for="group in page.props.abilities"
            :key="group.name"
            class="flex flex-col gap-1">
            <label>
                <CheckboxField
                    :model-value="isGroupChecked(group.name)"
                    :label="group.label"
                    @click="onClickGroup($event, group.name)" />
            </label>
            <div
                v-for="(permission, i) in group.children"
                :key="`${group.name}-${i}`" 
                class="ml-4 ">
                <label>
                    <CheckboxField
                        type="checkbox"
                        :model-value="isPermissionChecked(group.name, permission)"
                        :label="permission.label"
                        @click="onClickPermission($event, group, permission)" /></label>
            </div>
        </div>
    </div>
</template>