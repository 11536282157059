<script lang="ts" setup>
import { InputField } from '@vue-interface/input-field';
import type { User } from 'types';
import GuestLayout from '../../Layouts/GuestLayout.vue';
import { Card } from '@vue-interface/card';
import FormLayout from '../../components/FormLayout.vue';
import { useForm } from 'laravel-precognition-vue-inertia';
import Banner from '../../components/Banner.vue';
import { UserIcon } from '@heroicons/vue/24/outline';
import { route } from '../../composables/routes';

const props = defineProps<{
    user: User;
}>();

const url = route('activate.store');
const method = 'post';

const form = useForm(method, url, {
    token: props.user.activation_token,
    password: undefined,
    password_confirmation: undefined,
});

function onSubmit() {
    form.submit(method, url);
}

defineOptions({
    layout: GuestLayout
});
</script>

<template>
    <Card class="mx-auto w-[22rem]">
        <h1 class="mb-3 text-center font-bold">
            Create Password
        </h1>
        <p class="mb-3 text-center">
            Please create a password for your account.
        </p>

        <FormLayout
            :submit="onSubmit"
            :button-block="true"
            button-label="Submit">
            <div 
                class="flex flex-col gap-y-3">
                <Banner>
                    <UserIcon
                        class="h-8 w-8" />
                    <div>
                        <h1 class="font-bold">
                            {{ user.name }}
                        </h1>
                        <h2 class="">
                            {{ user.email }}
                        </h2>
                    </div>
                </Banner>

                <InputField
                    id="password"
                    v-model="form.password"
                    type="password"
                    label="Password"
                    :error="form.errors.password" />

                <InputField
                    id="password_confirmation"
                    v-model="form.password_confirmation"
                    type="password"
                    label="Re-enter Password"
                    :error="form.errors.password_confirmation" />
            </div>
        </FormLayout>
    </Card>
</template>