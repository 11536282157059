<script lang="ts" setup>
import { Link } from '@inertiajs/vue3';
import { CheckboxField } from '@vue-interface/checkbox-field';
import { InputField } from '@vue-interface/input-field';
import GuestLayout from '../../Layouts/GuestLayout.vue';
import { Card } from '@vue-interface/card';
import FormLayout from '../../components/FormLayout.vue';
import { route } from '../../composables/routes';
import { useForm } from 'laravel-precognition-vue-inertia';

const props = defineProps<{
    email: string,
    token: string
}>();

const url = route('password.update');
const method = 'post';

const form = useForm(method, url, {
    email: props.email,
    password: undefined,
    password_confirmation: undefined,
    remember: false,
    token: props.token
});

function onSubmit() {
    form.submit(method, url, {
        onSuccess: () => {
            window.location.href = '/';
        }
    });
}

defineOptions({
    layout: GuestLayout
});
</script>

<template>
    <Card class="mx-auto w-[22rem]">
        <h1 class="mb-3 text-center font-bold">
            Reset Password
        </h1>

        <FormLayout
            :submit="onSubmit"
            :form="form"
            button-label="Reset Password"
            :button-block="true">
            <InputField
                v-model="form.password"
                name="password"
                type="password"
                label="Password"
                :error="form.errors.password"
                class="mb-3" />

            <InputField
                v-model="form.password_confirmation"
                type="password"
                name="password_confirmation"
                label="Password Confirmation"
                :error="form.errors.password_confirmation"
                class="mb-3" />

            <CheckboxField
                v-model="form.remember"
                name="remember"
                label="Remember me"
                class="mb-3"
                :error="form.errors.remember" />
        </FormLayout>   
    </Card>
    <div class="flex justify-center gap-x-2">
        <Link
            class="text-rose-500 hover:underline"
            :href="route('login')">
            Back to Login
        </Link>
    </div>
</template>