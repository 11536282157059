<script lang="ts" setup>
import { Pulse } from '@vue-interface/activity-indicator';
import { SelectField } from '@vue-interface/select-field';
import type { MgDataVariable, SubscriberList } from 'types';
import { computed, onMounted, ref, watchEffect } from 'vue';
import axios from '../../axios';
import { formatListValue, formatReadableValue } from './MailingIdField.vue';

const props = defineProps<{
    id: string,
    name: string,
    item: MgDataVariable,
    index: number,
    errors: any,
    modelValue?: string|string[],
    list: SubscriberList
}>();

const content = ref();
const activity = ref(false);

async function getJobs() {
    activity.value = true;

    return await axios.get('mg/campaign/marketing/jobs', {
        params: {
            list_id: props.list.id
        },
    }).then(response => {
        content.value = response.data;

        return response.data;
    })
        .finally(() => {
            activity.value = false;
        });
}

const emit = defineEmits<{
    'update:modelValue': [value: string[]],
    'update:formattedValue': [value: string],
    'update:readableValue': [value: string[]]
}>();

const model = computed({
    get() {
        if(!props.modelValue) {
            return [];
        }

        if(Array.isArray(props.modelValue)) {
            return props.modelValue;
        }

        return [props.modelValue];
    },
    set(value: string[]) {
        emit('update:modelValue', value);
    }
});


const isDefault = computed(() => {
    return JSON.stringify(model.value) === JSON.stringify([props.item.defaultValue]);
});

watchEffect(() => {
    if(isDefault.value) {
        return emit('update:formattedValue', props.item.defaultValue);
    }

    emit('update:formattedValue', model.value.map(value => `'${value}'`).join(', '));

    if(content.value) {
        emit('update:readableValue', content.value
            .filter((i: any) => model.value.includes(String(i.id)))
            .map((i: any) => formatReadableValue(i)));
    }
});

onMounted(async () => {
    await getJobs();

    emit('update:modelValue', model.value);
});
</script>

<template>
    <div class="flex items-center gap-2">
        <SelectField
            :id="id"
            v-model="model"
            :activity="activity"
            :indicator="Pulse"
            indicator-size="sm"
            multiple
            class="w-full"
            :name="`options.mg_data_variables.${index}.value`"
            :error="errors[name]">
            <option
                v-for="i in content"
                :key="i.id"
                :value="String(i.id)">
                {{ formatListValue(i) }}
            </option>
        </SelectField>
        <slot
            v-if="!isDefault"
            name="reset" />
    </div>
</template>