<script lang="ts" setup>
import { Link, usePage } from '@inertiajs/vue3';
import { Card } from '@vue-interface/card';
import { CheckboxField } from '@vue-interface/checkbox-field';
import { InputField } from '@vue-interface/input-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import GuestLayout from '../../Layouts/GuestLayout.vue';
import channels, { type RequiredChannelProps } from '../../channels';
import FormLayout from '../../components/FormLayout.vue';
import { route } from '../../composables/routes';
import { echo } from '../../plugins/Echo';

const url = route('login');
const method = 'post';

const form = useForm(method, url, {
    email: undefined,
    password: undefined,
    remember: false
});

function onSubmit() {
    form.submit(method, url, {
        onSuccess() {
            const { props } = usePage<RequiredChannelProps>();

            channels({ echo, props });
        }
    });
}

defineOptions({
    layout: GuestLayout
});
</script>

<template>
    <Card class="mx-auto w-[22rem]">
        <h1 class="mb-3 text-center font-bold">
            Welcome to Alchemy!
        </h1>

        <FormLayout
            :submit="onSubmit"
            :form="form"
            button-label="Login"
            :button-block="true">
            <InputField
                id="email"
                v-model="form.email"
                name="email"
                label="Email"
                placeholder="you@example.com"
                :error="form.errors.email"
                class="mb-3" />

            <InputField
                v-model="form.password"
                type="password"
                name="password"
                label="Password"
                :error="form.errors.password"
                class="mb-3" />

            <CheckboxField
                v-model="form.remember"
                name="remember"
                label="Remember me"
                class="mb-3"
                :error="form.errors.remember" />
        </FormLayout>
    </Card>
    <div class="flex gap-x-2">
        <p class="text-neutral-500">
            Forget your password?
        </p>
        <Link
            class="text-rose-500 hover:underline"
            :href="route('password.forgot')">
            Reset Password
        </Link>
    </div>
</template>