<script lang="ts" setup>
import type { Send, User } from 'types';
import { route } from '../composables/routes';
import useResourceForm from '../composables/useResourceForm';
import Tags from './Tags.vue';

const props = defineProps<({
    description: string,
    send: Send;
    authUser: User
})>();

const url = route('api.mg.transactional-job', { send: props.send.id });

const { form, errors, post } = useResourceForm({
    data: {
        emails: [props.authUser.email]
    },
    url
});

defineExpose({
    post
});
</script>

<template>
    <form>
        <h1
            v-if="description"
            class="mb-3 flex items-center">
            {{ description }}
        </h1>

        <Tags
            v-model="form.emails"
            name="emails"
            :errors="errors" />
    </form>
</template>