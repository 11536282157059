import Axios, { AxiosError, type AxiosResponse } from 'axios';
import { useToast } from 'vue-toastification';
import { echo } from './plugins/Echo';

const toast = useToast();

// const token: HTMLMetaElement|null = document.head.querySelector('meta[name="csrf-token"]');


// if(!token) {
//     throw new Error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
// }

const axios = Axios.create({
    baseURL: '/api/',
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
});

function toastFromResponse(response?: AxiosResponse) {
    if(response?.headers.success) {
        return toast.success(response.headers.success);
    }
    else if(response?.headers.error) {
        return toast.error(response.headers.error);
    }
    else if(response?.headers.warning) {
        return toast.warning(response.headers.warning);
    }
    else if(response?.headers.info) {
        return toast.info(response.headers.info);
    }
    else if(response?.headers.default) {
        return toast(response.headers.default);
    }

    return;
}

function toastFromError(e: AxiosError<any,any>) {
    if(e.response?.status === 422) {
        return;
    }
    if(e.response?.data.message) {
        return toast.error(e.response?.data.message);
    }
    else if(e.message) {
        return toast.error(e.message);
    }
}

axios.interceptors.request.use(request => {
    request.headers['X-Socket-Id'] = echo.socketId();
    
    return request;
});

axios.interceptors.response.use((response) => {
    toastFromResponse(response);
    
    return response;
}, (e: AxiosError) => {
    if(toastFromResponse(e.response) === undefined) {
        toastFromError(e);
    }

    throw e;
});

export default axios;