<script lang="ts" setup>
import { Card } from '@vue-interface/card';
import { InputField } from '@vue-interface/input-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { Agency, Instance } from '../types';
import Autocomplete from './Autocomplete.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';

const props = defineProps<{
    instances?: Instance[];
    instance?: Instance;
    url: string;
    agency?: Agency;
}>();

const method = props.agency?.id ? 'put' : 'post';

const form = useForm(method, props.url, Object.assign({
    name: null,
    instance_id: props.instance?.id,
}, props.agency));

function submit() {
    form.submit();
}
</script>

<template>
    <FormLayout
        :submit="submit"
        :button-label="!agency?.id ? 'Submit' : 'Save'"
        :activity="form.processing"
        :disabled="form.processing">
        <Card>
            <FormField
                label="Name"
                description="The agency, client or group name.">
                <InputField
                    id="name"
                    v-model="form.name"
                    name="name"
                    :error="form.errors.name"
                    @change="form.validate('name')" />
            </FormField>
            <FormField
                label="MessageGears Instance"
                description="The MessageGears instance used to send emails.">
                <Autocomplete
                    id="instance_id"
                    v-model="form.instance_id"
                    name="instance_id"
                    namespace="instances"
                    only="instances"
                    :response="instances"
                    :error="form.errors.instance_id"
                    @update:model-value="form.validate('instance_id')" />
            </FormField>
        </Card>
    </FormLayout>
</template>