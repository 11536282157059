<script lang="ts" setup>
import { Head } from '@inertiajs/vue3';
import { useColorMode } from '@vueuse/core';
import DripMarkIcon from '../../svg/drip-mark-icon.svg';

const mode = useColorMode();
</script>

<template>
    <Head>
        <meta
            name="color-scheme"
            :content="mode">
    </Head>
    <main class="flex min-h-screen flex-col items-center justify-center gap-y-4 bg-neutral-50 dark:bg-neutral-900">
        <div class="flex justify-center">
            <DripMarkIcon class="h-10 w-10" />
        </div>
        <slot />
    </main>
</template>