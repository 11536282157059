<script lang="ts" setup>
import { ArrowLeftCircleIcon, ArrowLeftOnRectangleIcon, BuildingOfficeIcon, ChevronDownIcon, ClipboardDocumentListIcon, InformationCircleIcon, UserCircleIcon, UserGroupIcon, UserIcon } from '@heroicons/vue/24/outline';
import { Link, router } from '@inertiajs/vue3';
import { BtnDropdown } from '@vue-interface/btn-dropdown';
import { SlidePanel, useSlidePanels } from '@vue-interface/slide-panel';
import type { Agency, SubscriberList, User } from 'types';
import { ref } from 'vue';
import DripMarkIcon from '../../svg/drip-mark-icon.svg';
import MessageGearsIcon from '../../svg/message-gears-icon.svg';
import { route } from '../composables/routes';
import Avatar from './Avatar.vue';
import Breadcrumbs from './Breadcrumbs.vue';
import SubscriberLists from './SubscriberLists.vue';

export type Breadcrumb = {
    title?: string,
    url?: string,
    current: boolean,
    data?: any
};

defineProps<{
    authUser: User
    list?: SubscriberList
    agency?: Agency
    canViewAny?: any
    canView?: any
    breadcrumbs?: Breadcrumb[]
    hideBreadcrumbs?: boolean
    hideLists?: boolean
}>();

const isListPanelShowing = ref(false);

router.on('before', () => {
    close('lists');
});

const { open, close } = useSlidePanels();

function onClickList() {
    if(!isListPanelShowing.value) {
        open('lists');
    }
    else {
        close('lists');
    }
}
</script>

<template>
    <nav
        :class="{
            '!mb-4': breadcrumbs?.length,
        }"
        class="font-heading mb-6 flex h-16 items-center justify-between px-5 md:px-8 shadow-sm bg-white text-neutral-800 antialiased dark:border-neutral-800 dark:bg-neutral-950 dark:text-neutral-200">
        <div class="flex w-full items-center justify-between">
            <div class="flex h-full items-center">
                <Link
                    :href="!!agency && !!list ? route('dashboard', { agency: agency.slug, list: list.slug }) : route('home')"
                    class="mr-4 flex items-center text-lg font-bold text-neutral-900 outline-none dark:text-neutral-200">
                    <DripMarkIcon class="h-8 w-8" />
                </Link>
                <div
                    id="navigation"
                    class="flex flex-1 gap-4 [&_a]:capitalize [&_a:hover]:hover:text-rose-500 [&_a.active]:font-bold [&_a.active]:text-rose-500">
                    <slot>
                        <template v-if="!!agency && !!list">
                            <Link
                                v-if="list.options.enable_sends && canViewAny?.send"
                                :href="route('sends.index', { agency: agency.slug, list: list.slug })"
                                :class="{'active': $page.url.startsWith(`/${agency.slug}/${list.slug}/sends`)}">
                                Sends
                            </Link>
                            <Link
                                v-if="list.options.enable_audience && canViewAny?.subscriber"
                                :href="route('subscribers.index', { agency: agency.slug, list: list.slug })"
                                :class="{'active': $page.url.startsWith(`/${agency.slug}/${list.slug}/subscribers`)}">
                                Audience
                            </Link>
                            <Link
                                v-if="canViewAny?.template"
                                :href="route('templates.index', { agency: agency.slug, list: list.slug })"
                                :class="{'active': $page.url.startsWith(`/${agency.slug}/${list.slug}/templates`)}">
                                Templates
                            </Link>
                        </template>
                    </slot>
                </div>
            </div>

            <div class="flex h-full items-center space-x-5">
                <button
                    v-if="list"
                    class="flex items-end dark:text-neutral-200"
                    @click="onClickList">
                    <div class="relative flex max-w-[15rem]">
                        <UserGroupIcon class="mr-2 h-5 w-5 shrink-0" />
                        <div
                            class="truncate">
                            {{ list?.name }}
                        </div>
                    </div>
                    <ChevronDownIcon
                        class="my-1 ml-2 h-5 w-5 shrink-0 -rotate-90 transition-transform duration-200"
                        :class="{'rotate-0': isListPanelShowing}" />
                </button>

                <Link
                    v-else-if="$page.url !== '/'"
                    :href="route('home')"
                    class="flex items-center gap-1 dark:bg-neutral-800 dark:text-neutral-300 bg-neutral-100 text-neutral-600 py-2 px-3 text-sm rounded-lg">
                    <ArrowLeftCircleIcon class="w-5 h-5" /> Exit
                </Link>
                
                <BtnDropdown
                    ref="dropdown"
                    align="right">
                    <template #button="{ $button, onClickToggle, onBlur }">
                        <button
                            :ref="$button"
                            class="flex items-center rounded-full outline-none ring-rose-200 focus:ring dark:ring-rose-500"
                            @click="onClickToggle"
                            @blur="onBlur">
                            <Avatar :auth-user="authUser" />
                        </button>
                    </template>
                    <!-- <Link
                        v-if="!!agency && canView.agency"
                        :href="route('agencies.show', { agency: agency.id })"
                        class="group flex items-center">
                        <CogIcon class="my-1 mr-3 h-5 w-5" /> Agency Settings
                    </Link> -->
                    <!-- <hr v-if="(!!list && canView.list) || (!!agency && canView.agency)"> -->
                    <!-- <div
                        v-if="!authUser.super_admin"
                        class="dropdown-header !py-0 !pl-4">
                        Settings
                    </div> -->
                    <template v-if="agency || list">
                        <Link
                            v-if="list && canView?.list"
                            :href="route('agencies.lists.show', { agency: agency?.id, list: list?.id })"
                            class="group flex items-center"
                            :class="{'!text-rose-500': $page.url.match(/^\/admin\/agencies(\/\d+)\/lists\/\d+$/)}">
                            <!-- <CogIcon class="my-1 mr-3 h-5 w-5" /> List Settings -->
                            <UserGroupIcon class="my-1 mr-3 h-5 w-5" /> List Settings
                        </Link>
                        <Link
                            v-if="canView?.agency"
                            :href="route('agencies.show', { agency: agency?.id })"
                            class="group flex items-center"
                            :class="{'!text-rose-500': $page.url.match(/^\/admin\/agencies(\/\d+)$/)}">
                            <BuildingOfficeIcon class="my-1 mr-3 h-5 w-5" /> Agency
                        </Link>
                        <hr v-if="canView?.list || !authUser.super_admin && canView?.agency">
                    </template>
                    <Link
                        v-if="canViewAny?.instance"
                        :href="route('instances.index')"
                        :class="{'!text-rose-500': $page.url.startsWith(`/admin/instances`)}"
                        class="group flex items-center">
                        <MessageGearsIcon class="my-1 mr-3 h-5 w-5" /> Instances
                    </Link>
                    <template v-if="authUser.super_admin">
                        <Link
                            :href="route('agencies.index')"
                            :class="{'!text-rose-500': $page.url.match(/^\/admin\/agencies$/)}"
                            class="group flex items-center">
                            <BuildingOfficeIcon class="my-1 mr-3 h-5 w-5" /> Agencies
                        </Link>
                    </template>
                    <Link
                        v-if="authUser.super_admin"
                        :href="route('users.index')"
                        :class="{'!text-rose-500': $page.url.startsWith(`/admin/users`)}"
                        class="group flex items-center">
                        <UserIcon class="my-1 mr-3 h-5 w-5" /> Users
                    </Link>
                    <!-- <hr v-if="canViewAny?.instance || canViewAny?.agency || authUser.super_admin"> -->
                    <Link
                        v-if="canViewAny?.audit"
                        :href="route('activity')"
                        :class="{'!text-rose-500': $page.url.startsWith(`/admin/activity`)}"
                        class="group flex items-center">
                        <ClipboardDocumentListIcon class="my-1 mr-3 h-5 w-5" /> Activity Log
                    </Link>
                    <hr v-if="canViewAny?.audit">
                    <Link
                        :href="route('help')"
                        :class="{'!text-rose-500': $page.url.startsWith(`/help`)}"
                        class="group flex items-center">
                        <InformationCircleIcon class="my-1 mr-3 h-5 w-5" /> Help
                    </Link>
                    <Link
                        :href="route('account.edit')"
                        :class="{'!text-rose-500': $page.url.startsWith(`/account`)}"
                        class="group flex items-center">
                        <UserCircleIcon class="my-1 mr-3 h-5 w-5" /> My Account
                    </Link>
                    <a
                        :href="route('logout')"
                        class="group flex items-center">
                        <ArrowLeftOnRectangleIcon class="my-1 mr-3 h-5 w-5" /> Logout
                    </a>
                </BtnDropdown>
            </div>
        </div>
    </nav>
    <Breadcrumbs
        v-if="!hideBreadcrumbs"
        class="px-5 md:px-8"
        :breadcrumbs="breadcrumbs" />
    <SlidePanel
        name="lists"
        class="w-[28rem]"
        @open="isListPanelShowing = true"
        @close="isListPanelShowing = false">
        <SubscriberLists :auth-user="authUser" />
    </SlidePanel>
</template>