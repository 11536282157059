<script setup lang="ts">
import { ref, type Directive } from 'vue';

// const throttled = throttle((fn: Function) => {
//     return fn();
// }, 100);

const props = defineProps<{
    ghost?: boolean;
    id?: string;
}>();

const emit = defineEmits<{
    dragend: [e: DragEvent];
    dragenter: [e: DragEvent];
    dragleave: [e: DragEvent];
    dragover: [e: DragEvent];
    dragstart: [e: DragEvent];
    drop: [e: DragEvent];
    mousedown: [e: Event];
    mouseup: [e: Event];
}>();

defineSlots<{
    default?: (props: {
        el: Element;
        handle: DragHandle;
    }) => any
}>();

const el = ref<Element>();

const draggable = ref(false);

const handle: DragHandle = ({
    onMousedown: e => {
        draggable.value = true;

        emit('mousedown', e);
    },
    onMouseup: e => {
        draggable.value = false;

        emit('mouseup', e);
    }
});

function onDragenter(e: DragEvent) {
    emit('dragenter', e);
}

function onDragstart(e: DragEvent) {
    if(!props.ghost) {
        e.dataTransfer?.setDragImage(ghostImage, 0, 0);
    }
    
    emit('dragstart', e);
}

function onDragend(e: DragEvent) {
    draggable.value = false;
    
    emit('dragend', e);
}
</script>

<script lang="ts">
export type DragHandle = {
    onMousedown: (e: Event) => void;
    onMouseup: (e: Event) => void;
}

export const vDragHandle: Directive<any, DragHandle> = (el: Element, { arg, value }) => {
    if(arg) {
        el.setAttribute('data-handle-id', arg);
    }

    el.addEventListener('pointerdown', value.onMousedown);
    el.addEventListener('pointerup', value.onMouseup);
};

export const ghostImage = new Image(0, 0);
    
ghostImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
ghostImage.style.position = 'absolute';
ghostImage.style.transitionDelay = '0s';
</script>

<template>
    <div
        ref="el"
        :data-drag-id="id"
        :draggable="draggable"
        :class="{
            'user-select-none cursor-move': draggable
            // '[&_*]:pointer-events-none': isDraggedOver,
            // '[&]:before:content(\'\') [&]:before:border-t-2 [&]:before:border-t-blue-500 [&]:before:block [&]:before:absolute [&]:before:top-0 [&]:before:w-full relative': isDraggedTop,
            // '[&]:after:content(\'\') [&]:after:border-b-2 [&]:after:border-b-blue-500 [&]:after:block [&]:after:absolute [&]:after:bottom-[-2px] [&]:after:w-full relative': isDraggedBottom
        }"
        @dragenter.prevent="onDragenter"
        @dragend.prevent="onDragend"
        @dragover.prevent="e => emit('dragover', e)"
        @dragstart="onDragstart">
        <slot
            v-if="el"
            v-bind="{ handle, el }" />
    </div>
</template>