<script lang="ts" setup>
import { ArrowTrendingDownIcon, ArrowTrendingUpIcon } from '@heroicons/vue/24/outline';
import { onMounted, ref } from 'vue';
import axios from '../../axios';
import Card from '../Card.vue';
import MetricHeader from './MetricHeader.vue';
import { ActivityIndicator, Pulse } from '@vue-interface/activity-indicator';

const props = withDefaults(defineProps<{
    url: string,
    params?: object,
    defaultRange?: string|number,
}>(), {
    // url: '',
    // params: null,
    params: undefined,
    defaultRange: 'week'
});

let activity = ref(false);
let data = ref();
let ranges = ref();
let title = ref('');
let selected = ref(props.defaultRange);

async function load() {
    activity.value = true;

    return await axios.get(props.url, {
        params: Object.assign({
            range: selected.value
        }, props.params)
    }).then(async(response) => {
        data.value = response.data;
        ranges.value = response.data.ranges;
        title.value = response.data.title;

        return data;
    }).finally(() => activity.value = false);
}

onMounted(async() => await load());
</script>

<template>
    <Card
        class="h-48">
        <template #internal-header>
            <MetricHeader
                v-model="selected"
                :default-range="props.defaultRange"
                :ranges="ranges"
                :title="title"
                @update:model-value="load" />
        </template>

        <ActivityIndicator
            v-if="activity"
            center
            :type="Pulse" />

        <div
            v-else-if="data"
            class="flex grow flex-col justify-between">
            <div class="flex grow items-center text-[3.25rem]">
                {{ data.value }}
            </div>
            <slot name="percent_changed">
                <div class="flex items-center">
                    <ArrowTrendingUpIcon
                        v-if="data.percent_changed > 0"
                        class="w-8 text-green-400" />
                    <div
                        v-else-if="data.percent_changed == 0"
                        class="text-2xl text-neutral-500">
                        --
                    </div>
                    <ArrowTrendingDownIcon
                        v-else-if="data.percent_changed < 0"
                        class="w-8 text-red-400" />
                    <span class="ml-2 font-bold">
                        {{ data.percent_changed }}%  {{ data.percent_changed >= 0 ? 'Increase' : 'Decrease' }}
                    </span>
                </div>
            </slot>
        </div>
    </Card>
</template>