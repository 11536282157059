<script lang="ts" setup>
import { EllipsisHorizontalIcon } from '@heroicons/vue/24/outline';
import { BtnDropdown } from '@vue-interface/btn-dropdown';
import { onBeforeUnmount, onMounted, ref } from 'vue';

withDefaults(defineProps<{
    size?: 'sm'|'md'|'lg';
}>(), {
    size: 'lg'
});

const menu = ref();
const show = ref(false);
let observer: MutationObserver | null = null;

const updateShow = () => {
    show.value = !!menu.value?.$el.querySelector('.dropdown-menu')?.children?.length;
};

onMounted(() => {
    observer = new MutationObserver(updateShow);
    observer.observe(menu.value.$el, { childList: true, subtree: true });
    updateShow();
});

onBeforeUnmount(() => {
    if(observer) {
        observer.disconnect();
    }
});
</script>

<template>
    <BtnDropdown
        v-show="show"
        ref="menu"
        align="right">
        <template #button="{ $button, onBlur, onClickToggle, expanded }">
            <slot
                name="button"
                :on-blur="onBlur"
                :on-click-toggle="onClickToggle"
                :expanded="expanded">
                <button
                    id="action-button"
                    :ref="$button"
                    class="flex items-center justify-center rounded-full p-0 text-center outline-none transition-transform focus:ring-2 active:ring-2 ring-rose-500"
                    :class="{
                        'rotate-90': expanded,
                        'focus:ring ring-rose-500': size === 'lg'
                    }"
                    @blur="onBlur"
                    @click="onClickToggle">
                    <EllipsisHorizontalIcon
                        :class="{
                            'h-6 w-6': size === 'sm',
                            'h-8 w-8': size === 'md',
                            'h-10 w-10': size === 'lg',
                        }" />
                </button>
            </slot>
        </template>
        <slot />
    </BtnDropdown>
</template>