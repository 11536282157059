<script lang="ts" setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import { useColorMode } from '@vueuse/core';
import { format, isEqual, isValid, parseISO } from 'date-fns';
import type { MgDataVariable } from 'types';
import { computed, watchEffect } from 'vue';

const props = defineProps<{
    id: string,
    name: string,
    item: MgDataVariable,
    index: number,
    errors: any,
    modelValue: string
}>();

const emit = defineEmits<{
    'update:modelValue': [value: Date | string],
    'update:formattedValue': [value: string],
    'update:readableValue': [value: string]
}>();

const mode = useColorMode();

const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const isDefault = computed(() => {
    if(isValid(new Date(props.item.defaultValue))) {
        return isEqual(parseISO(model.value), parseISO(props.item.defaultValue));
    }

    return model.value === props.item.defaultValue;
});

watchEffect(() => {
    if(isValid(new Date(model.value))) {
        emit('update:formattedValue', format(new Date(model.value), 'yyyy-MM-dd'));
        emit('update:readableValue', format(new Date(model.value), 'PPP'));
    }
});

</script>

<template>
    <div class="flex items-center gap-x-2">
        <VueDatePicker
            v-model="model"
            utc
            :is-24="false"
            auto-apply
            :enable-time-picker="false"
            :month-change-on-scroll="false"
            :dark="mode === 'dark'"
            :clearable="false" />
        <slot
            v-if="!isDefault"
            name="reset" />
    </div>
</template>