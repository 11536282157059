<script lang="ts" setup>
import { InputField } from '@vue-interface/input-field';
import type { MgDataVariable } from 'types';
import { computed, watchEffect } from 'vue';

const props = defineProps<{
    id: string,
    name: string,
    item: MgDataVariable,
    index: number,
    errors: any,
    modelValue: string
}>();

const emit = defineEmits<{
    'update:modelValue': [value: string],
    'update:formattedValue': [value: string],
    'update:readableValue': [value: string]
}>();

const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const isDefault = computed(() => {
    return model.value === props.item.defaultValue;
});

watchEffect(() => {
    emit('update:formattedValue', model.value);
    emit('update:readableValue', model.value);
});

</script>

<template>
    <div class="flex items-start gap-x-2">
        <InputField
            :id="id"
            v-model="model"
            :name="name"
            :error="errors[name]"
            class="w-full" />
        <slot
            v-if="!isDefault"
            name="reset" />
    </div>
</template>