<script setup lang="ts">
import { Dots, type ActivityIndicator } from '@vue-interface/activity-indicator';
import { ref } from 'vue';

const props = defineProps<{
    canCreateAgency: boolean
}>();

const activity = ref(true);

function onLoad() {
    activity.value = false;
}

</script>

<template>
    <div class="w-full p-8">
        <h1 class="font-bold text-3xl text-rose-500 mb-4">
            Help
        </h1>
        <p class="text-gray-700 mb-6">
            Welcome to the help page. Here, you can find documentation links to guide you through various aspects of the app.
        </p>

        <ActivityIndicator v-if="activity" class="p-8" center :type="Dots"/>

        <div v-show="!activity" class="flex-col space-y-12">
            <div>
                <iframe src="https://embed.getguru.com/card/iq474daT/Create-and-Schedule-Send-in-Alchemy" @load="onLoad" frameBorder="0" allowFullScreen class="w-full h-[800px]"></iframe>
            </div>

            <div>
                <iframe src="https://embed.getguru.com/card/igjgGkjT/Editing-or-Adding-Subscribers-in-Alchemy" @load="onLoad" frameBorder="0" allowFullScreen class="w-full h-[800px]"></iframe>
            </div>

            <div v-if="canCreateAgency">
                <iframe src="https://embed.getguru.com/card/cr4kLEbi/Create-an-Agency-in-Alchemy" @load="onLoad" frameBorder="0" class="w-full h-[800px]" allowFullScreen></iframe>
            </div>
        </div>
    </div>
</template>

<!-- <style scoped>
.ghq-EmbedCardHome .ghq-EmbedCardHome__constrained-width-scrolled {
    max-width: 100% !important;
    width: 100%;
}

/* .ghq-EmbedCardHome .ghq-EmbedCardHome__constrained-width-scrolled */
</style> -->