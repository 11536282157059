<script lang="ts" setup>
import { InformationCircleIcon, MagnifyingGlassIcon, UserPlusIcon } from '@heroicons/vue/24/outline';
import { InputField } from '@vue-interface/input-field';
import { SelectField } from '@vue-interface/select-field';
import type { Agency, AgencyUser, User } from 'types';
import { ref } from 'vue';
import { route } from '../composables/routes';
import useResourceForm from '../composables/useResourceForm';
import Autocomplete from './Autocomplete.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';
import Tab from './Tab.vue';
import Tabs from './Tabs.vue';

const props = defineProps<{
    agency: Agency;
    agencyUsers?: (User & { pivot: AgencyUser })[];
    agencyUser?: AgencyUser;
    user?: User;
}>();

const userRef = ref(props.user);
const emit = defineEmits(['success', 'error']);
const activity = ref(false);

const url = route('api.agencies.users.store', {
    agency: props.agency.id
});

const { form, errors, request } = useResourceForm(url, {
    method: 'post',
    data: {
        email: null,
        name: null,
        role: null,
        user_id: null,
        ...props.agencyUser
    }
});

function onSubmit() {
    activity.value = true;
    request()
        .then((response) => {
            emit('success', response);
        }).catch((error) => {
            emit('error', error);
        }).finally(() => {
            activity.value = false;
        });
}

function onSelect(e: MouseEvent, model: User) {
    userRef.value = model;
    form.email = null;
    form.user_id = userRef.value.id;
}
</script>

<template>
    <div class="w-[25rem]">
        <div class="flex flex-col gap-y-1">
            <h2 class="text-3xl font-semibold">
                {{ agencyUser?.role ? 'Edit Permissions' : `Assign User to Agency` }}
            </h2>
        </div>

        <FormLayout
            :submit="onSubmit"
            :button-block="true"
            :button-label="agencyUser?.role ? 'Save' : 'Submit'"
            :disabled="activity"
            :activity="activity">
            <Tabs
                v-if="!agencyUser?.role"
                flex
                class="mt-6">
                <Tab
                    header="Search for User"
                    :icon="MagnifyingGlassIcon">
                    <FormField
                        label="Name"
                        description="The user you wish to assign to the agency."
                        block>
                        <Autocomplete
                            id="name"
                            v-model="form.name"
                            name="name"
                            namespace="agency-users"
                            only="agencyUsers"
                            :response="agencyUsers"
                            :errors="errors"
                            :selector="(model: User) => model.id === form.user_id"
                            @select="onSelect" />
                    </FormField>
                </Tab>
                <Tab
                    header="Create User"
                    :icon="UserPlusIcon">
                    <div
                        class=" mt-4 flex items-center gap-3 rounded bg-amber-50 p-3 text-base shadow dark:bg-violet-600">
                        <InformationCircleIcon class="h-10 w-10 text-neutral-800 dark:text-white" />
                        <p>
                            You are creating a new user and assigning them to this agency.
                        </p>
                    </div>

                    <FormField
                        label="Name"
                        description="The name of the new user."
                        block>
                        <InputField
                            id="name"
                            ref="email"
                            v-model="form.name"
                            name="name"
                            :errors="errors" />
                    </FormField>   
                    <FormField
                        label="Email"
                        description="The email of the new user."
                        block>
                        <InputField
                            id="email"
                            ref="email"
                            v-model="form.email"
                            name="email"
                            :errors="errors" />
                    </FormField>
                </Tab>
            </Tabs>
            <FormField
                label="Role"
                description="The role will determine how the user may interact with the agency."
                block>
                <SelectField
                    id="role"
                    v-model="form.role"
                    name="role"
                    :errors="errors">
                    <option value="admin">
                        Admin
                    </option>
                    <option value="user">
                        User
                    </option>
                </SelectField>
            </FormField>
        </FormLayout>
    </div>
</template>