<script lang="ts" setup>
import { BuildingOfficeIcon, ChevronDownIcon, ExclamationCircleIcon, MagnifyingGlassIcon, UserGroupIcon } from '@heroicons/vue/24/outline';
import { Link } from '@inertiajs/vue3';
import { ActivityIndicator, Pulse } from '@vue-interface/activity-indicator';
import { InputField } from '@vue-interface/input-field';
import { debounce } from 'lodash-es';
import type { Agency, SubscriberList, User } from 'types';
import { onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import axios from '../axios';
import { route } from '../composables/routes';
import { echo } from '../plugins/Echo';
import Accordion from './Accordion.vue';
import ListGroup from './ListGroup.vue';
import ListGroupItem from './ListGroupItem.vue';

const props = defineProps<{
    authUser: User;
}>();

type AgencyGroups = {
    agency: Agency,
    lists: SubscriberList[]
}[];

const debouncer = debounce((fn, ...args) => fn(...args), 250);
const groups = ref<AgencyGroups>([]);
const activity = ref(true);
const keywords = ref();
const searching = ref(false);
const loaded = ref(false);

watch([keywords], () => {
    searching.value = true;
    debouncer(() => {
        load().finally(() => {
            searching.value = false;
        });
    });
});


async function load() {
    activity.value = true;

    return await axios.get(route('api.lists.index'), {
        params: {
            q: keywords.value
        },
        baseURL: '/',
    })
        .then(response => {

            // @todo: fix the sockets for this which was using the old useEcho.
            // bind(response.data);

            // const sortedGroups = response.data.groups
            //     .sort((a: any, b: any) => a.agency.name.localeCompare(b.agency.name))
            //     .map((group: any) => {
            //         group.lists.sort((a: any, b: any) => a.name.localeCompare(b.name));
            //         return group;
            //     });

            groups.value = response.data.groups;
            // store.totalNew = response.data.total_new;
            activity.value = false;

            return response.data.groups;
        })
        .finally(() => {
            activity.value = false;
        });
}

onBeforeMount(() => {
    echo.private(`App.Models.User.${props.authUser.id}`)
        .listen('UserAssignedToAgency', () => load())
        .listen('UserRemovedFromAgency', () => load())
        .listen('UserAssignedToList', () => load())
        .listen('UserRemovedFromList', () => load());
});

onBeforeUnmount(() => {
    echo.private(`App.Models.User.${props.authUser.id}`)
        .stopListening('UserAssignedToAgency')
        .stopListening('UserRemovedFromAgency')
        .stopListening('UserAssignedToList')
        .stopListening('UserRemovedFromList');
});

onMounted(async() => {
    await load().finally(() => {
        loaded.value = true;
    });
});
</script>

<template>
    <div>
        <slot name="header">
            <h2 class="mb-2 text-2xl">
                My Lists
            </h2>
            <p class="mb-4">
                Select a list to make it active.
            </p>
        </slot>

        <InputField
            v-model="keywords"
            :activity="searching"
            :indicator="Pulse"
            class="mb-6">
            <template #icon>
                <MagnifyingGlassIcon class="h-6 w-6" />
            </template>
        </InputField>

        <ActivityIndicator
            v-if="!loaded"
            :type="Pulse" />

        <div
            v-else
            class="flex max-w-md flex-col space-y-2">
            <div
                v-for="({agency, lists}, i) in groups"
                :key="`group-${i}`">
                <div>
                    <Accordion
                        ref="accordion"
                        :show="!!keywords">
                        <template #header="{ toggle, isShowing }">
                            <button
                                id="agency"
                                class="mb-2 flex w-full cursor-pointer items-center justify-between rounded bg-white p-3 shadow dark:bg-neutral-950"
                                @click="toggle">
                                <div class="flex">
                                    <BuildingOfficeIcon class="h-6 w-6 shrink-0" />
                                    <ExclamationCircleIcon
                                        v-if="agency.role && !agency.role.accessed_at"
                                        class="absolute h-4 w-4 -translate-y-1/2 translate-x-[0.7rem] fill-sky-400 stroke-white dark:fill-lime-300 dark:stroke-neutral-800" />

                                    <h3 class="pl-2">
                                        {{ agency.name }}
                                    </h3>
                                </div>

                                <button>
                                    <ChevronDownIcon
                                        :class="{
                                            '!rotate-0': isShowing
                                        }"
                                        class="ml-4 h-5 w-5 -rotate-90 transition-transform duration-200" />
                                </button>
                            </button>
                        </template>
                        <template #body>
                            <ListGroup>
                                <ListGroupItem
                                    v-for="list in lists"
                                    :key="`list-${list.id}`">
                                    <div class="flex justify-between">
                                        <Link
                                            :href="route('dashboard', { agency: agency.slug, list: list.slug })"
                                            class="text-rose-500 truncate">
                                            {{ list.name }}
                                        </Link>
                                        <div class="flex items-center gap-3">
                                            <span
                                                v-if="list.role && !list.role.accessed_at || list.agency.role && !list.agency.role.accessed_at"
                                                class="text-xs uppercase text-neutral-400 dark:text-neutral-300">New</span>
                                        </div>
                                    </div>
                                    <template #icon>
                                        <div class="relative">
                                            <div class="relative">
                                                <UserGroupIcon class="h-6 w-6 shrink-0" />
                                                <ExclamationCircleIcon
                                                    v-if="list.role && !list.role.accessed_at || list.agency.role && !list.agency.role.accessed_at"
                                                    class="absolute right-0 top-0 h-4 w-4 -translate-y-1/3 translate-x-1/4 fill-sky-400 stroke-white dark:fill-lime-300 dark:stroke-neutral-800" />
                                            </div>
                                        </div>
                                    </template>
                                </ListGroupItem>
                            </ListGroup>
                        </template>
                    </Accordion>
                </div>
            </div>
        </div>

        <div v-if="!groups.length && !activity && keywords">
            <ListGroupItem>
                No results found.
                <template #icon>
                    <UserGroupIcon class="h-8 w-8" />
                </template>
            </ListGroupItem>
        </div>
        
        <div v-else-if="!groups.length && !activity">
            <div class="mb-4 flex rounded bg-amber-50 p-3 text-base shadow dark:bg-violet-600">
                <ExclamationCircleIcon class="mr-2 h-6 w-6" /> You have not been assigned to any agencies or lists.
            </div>

            <h2 class="text-lg">
                What Should I Do Next?
            </h2>
            
            <p class="mb-4">
                Contact your administrator and tell them to assign you to lists in Alchemy.
            </p>
        </div>
    </div>
</template>