<script lang="ts" setup>
import type { SubscriberList, Template } from 'types';
import { ref } from 'vue';
import { route } from '../composables/routes';
import useResourceForm from '../composables/useResourceForm';
import Autocomplete from './Autocomplete.vue';

const props = defineProps<{
    template: Template;
    lists: SubscriberList[]
}>();

const list = ref<SubscriberList>();

const { form, errors, patch } = useResourceForm({
    data: {
        list_id: undefined
    },
    url: route('api.templates.update', { template: props.template.id })
});

function onSelect(_: Event, model: SubscriberList) {
    list.value = model;
}

defineExpose({
    patch
});
</script>

<template>
    <Autocomplete
        id="list_id"
        v-model="form.list_id"
        max-height="20rem"
        namespace="lists"
        only="lists"
        :response="lists"
        :errors="errors"
        @select="onSelect" />
</template>