<script setup lang="ts">
import { ArrowLeftOnRectangleIcon, BeakerIcon, BugAntIcon, BuildingOfficeIcon, CalendarDaysIcon, ClockIcon, EnvelopeIcon, InboxIcon, LockOpenIcon, NoSymbolIcon, PaperAirplaneIcon, RectangleGroupIcon, RocketLaunchIcon, Square3Stack3DIcon, UserGroupIcon, UserIcon, UserMinusIcon, UserPlusIcon } from '@heroicons/vue/24/outline';

import { type IconDescription } from 'types';
import type { Component } from 'vue';
import MessageGearsIcon from '../../../svg/message-gears-icon.svg';
import SubscriberIcon from '../../../svg/subscriber-icon.svg';

defineProps<{
    description: IconDescription
}>();

const icons: Record<string,Component|string> = {
    'ArrowLeftOnRectangleIcon': ArrowLeftOnRectangleIcon,
    'BeakerIcon': BeakerIcon,
    'BugAntIcon': BugAntIcon,
    'BuildingOfficeIcon': BuildingOfficeIcon,
    'CalendarDaysIcon': CalendarDaysIcon,
    'ClockIcon': ClockIcon,
    'EnvelopeIcon': EnvelopeIcon,
    'InboxIcon': InboxIcon,
    'LockOpenIcon': LockOpenIcon,
    'MessageGearsIcon': MessageGearsIcon,
    'NoSymbolIcon': NoSymbolIcon, 
    'PaperAirplaneIcon': PaperAirplaneIcon,
    'RectangleGroupIcon': RectangleGroupIcon,
    'RocketLaunchIcon': RocketLaunchIcon,
    'Square3Stack3DIcon': Square3Stack3DIcon,
    'SubscriberIcon': SubscriberIcon,
    'UserGroupIcon': UserGroupIcon,
    'UserIcon': UserIcon,
    'UserMinusIcon': UserMinusIcon,
    'UserPlusIcon': UserPlusIcon,
};
</script>

<template>
    <component
        :is="icons[description.name]"
        :class="{
            'w-4 h-4': description.size === 'sm',
            'w-8 h-8': description.size === 'md',
            'w-12 h-12': description.size === 'lg'
        }" />
</template>