<script lang="ts" setup>
import { BuildingOfficeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon, ClipboardDocumentListIcon } from '@heroicons/vue/24/outline';
import { Link, router } from '@inertiajs/vue3';
import type { Agency, Instance, LengthAwarePaginator, Audit } from 'types';
import { onBeforeMount, onBeforeUnmount } from 'vue';
import MessageGearsIcon from '../../svg/message-gears-icon.svg';
import AdminLayout from '../Layouts/AdminLayout.vue';
import ActionButton from '../components/ActionButton.vue';
import DetailsView, { ExternalLink } from '../components/DetailsView.vue';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import Show from '../components/Show.vue';
import Value from '../components/metrics/Value.vue';
import { route } from '../composables/routes';
import { echo } from '../plugins/Echo';
import BadgeDescription from '../components/audits/BadgeDescription.vue';
import Description from '../components/audits/Description.vue';
import IconDescription from '../components/audits/IconDescription.vue';

const props = defineProps<{
    agenciesParams?: Record<string,string>;
    agencies?: LengthAwarePaginator<Agency>;
    instance: Instance;
    audits?: LengthAwarePaginator<Audit>;
}>();

const only = ['agencies', 'audits'];

defineOptions({
    layout: AdminLayout
});

function onClickDelete(instance: Instance) {
    if(confirm('Are you sure you want to delete this instance?')) {
        router.delete(route('instances.destroy', { instance: instance.id }));
    }
}

onBeforeMount(() => {
    echo.private(`App.Models.Instance.${props.instance.id}`)
        .listen('.InstanceUpdated', () => router.reload({ only: [...only, 'instance'] }))
        .listen('.InstanceDeleted', () => router.reload({ only: [...only, 'instance'] }))
        .listen('.AgencyCreated', () => router.reload({ only: [...only, 'instance'] }))
        .listen('.AgencyUpdated', () => router.reload({ only: [...only, 'instance'] }))
        .listen('.AgencyDeleted', () => router.reload({ only: [...only, 'instance'] }));
});

onBeforeUnmount(() => {
    echo.private(`App.Models.Instance.${props.instance.id}`)
        .stopListening('.InstanceUpdated')
        .stopListening('.InstanceDeleted')
        .stopListening('.AgencyCreated')
        .stopListening('.AgencyUpdated')
        .stopListening('.AgencyDeleted');
});
</script>

<template>
    <Show
        :icon="MessageGearsIcon"
        :title="instance.name">
        <template #actions>
            <ActionButton>
                <Link
                    :href="route('instances.edit', { instance: instance.id })"
                    class="group flex items-center">
                    <PencilSquareIcon class="my-1 mr-3 h-5 w-5" /> Edit Instance
                </Link>
                <Link
                    :href="route('agencies.create', { instance_id: instance.id })"
                    class="group flex items-center">
                    <BuildingOfficeIcon class="my-1 mr-3 h-5 w-5" /> Create Agency
                </Link>
                <hr>
                <button
                    class="group flex items-center"
                    @click="() => onClickDelete(instance)">
                    <TrashIcon class="my-1 mr-3 h-5 w-5" /> Delete Instance
                </button>
            </ActionButton>
        </template>

        <div
            class="grid auto-cols-fr grid-flow-col gap-4">
            <Value
                url="metrics/agencies/value"
                :params="{instance: instance.id}"
                class="flex-1" />
            <Value
                url="metrics/lists/value"
                :params="{instance: instance.id}"
                class="flex-1" />
        </div>

        <SearchableListGroup
            size="md"
            singular="agency"
            plural="agencies"
            namespace="agencies"
            :only="only"
            :response="agencies"
            :icons="{
                default: BuildingOfficeIcon
            }"
            :routes="{
                index: route('instances.show', { instance: instance.id }),
                show: agency => route('agencies.show', { agency: agency.id }),
                edit: agency => route('agencies.edit', { agency: agency.id }),
                create: route('agencies.create', { instance_id: instance.id }),
                delete: agency => route('agencies.destroy', { agency: agency.id })
            }">
            <template #create-action>
                <Link
                    :href="route('agencies.create', { instance_id: instance.id })"
                    class="btn flex h-8 w-8 items-center rounded-full p-0 outline-none focus:ring active:ring dark:text-neutral-400 dark:ring-rose-500 dark:hover:text-neutral-200">
                    <PlusCircleIcon class="h-full w-full" />
                </Link>
            </template>
        </SearchableListGroup>

        <DetailsView
            header="Details"
            :data="instance"
            :fields="[
                { name: 'base_uri', label: 'Base URI', type: ExternalLink, href: instance.base_uri, target: '_blank' },
                { name: 'api_key', label: 'MessageGears Accelerator API Key' },
                { name: 'account_id', label: 'MessageGears Accelerator Account ID' },
                { name: 'cloud_api_key', label: 'Cloud API Key' },
                { name: 'cloud_account_id', label: 'Cloud Account ID' },
                { name: 'transactional_id', label: 'Transactional ID' },
                { name: 'options.table_suffix', label: 'Listelixr Table Suffix' },
                { name: 'updated_at', label: 'Updated', type: Date, format: 'PPPp' },
                { name: 'created_at', label: 'Created', type: Date, format: 'PPPp' },
            ]" />

<SearchableListGroup
            size="md"
            header="Activity"
            namespace="audits"
            :filters="false"
            :only="only"
            :response="audits"
            :icons="{
                default: ClipboardDocumentListIcon
            }">
            <template #icon="{ model }">
                <IconDescription
                    v-if="model.description?.icon"
                    :description="model.description.icon" />
            </template>
            <template #badges="{ model }">
                <BadgeDescription
                    v-if="model.description?.badge"
                    :description="model.description.badge" />
            </template>
            <template #title="{ model }">
                <Description
                    v-if="model.description"
                    :description="model.description" />
                <template v-else>
                    Audit description not available.
                </template>
            </template>
        </SearchableListGroup>
    </Show>
</template>