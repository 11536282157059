<script lang="ts" setup>
import { Card } from '@vue-interface/card';

const statuses = ['Draft', 'Scheduled', 'Queued', 'Active', 'Delivered', 'Failed', 'Template'];

</script>

<template>
    <Card class="flex">
        <div
            v-for="status in statuses"
            :key="status"
            class="!p-2 m-2"
            :class="{
                'badge bg-neutral-500': status === 'Draft',
                'badge bg-blue-500': status === 'Scheduled' || status === 'Queued',
                'badge bg-amber-400': status === 'Active',
                'badge bg-green-500': status === 'Delivered',
                'badge bg-green-800': status === 'Template',
                'badge bg-red-500': status === 'Failed'
            }">
            {{ status }}
        </div>
    </Card>
</template>