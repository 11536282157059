<script lang="ts" setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { ActivityIndicator, Pulse } from '@vue-interface/activity-indicator';
import { AxiosError } from 'axios';
import Chart from 'chart.js/auto';
import colors from 'tailwindcss/colors';
import { onMounted, ref, type Directive } from 'vue';
import axios from '../../axios';
import Card from '../Card.vue';
import MetricHeader from './MetricHeader.vue';
import { mouseLine } from './mouseLine';

const props = withDefaults(defineProps<{
    url: string,
    dataset?: object,
    layout?: object,
    params?: object,
    tooltip?: boolean,
    defaultRange?: string|number
}>(), {
    dataset: undefined,
    layout: undefined,
    params: undefined,
    tooltip: true,
    defaultRange: 'week',
});

const rose = colors.rose[500];
let selected = ref(props.defaultRange);
let coordinates: any = [];
let activity = ref(false);
let data = ref();
let ranges = ref();
let title = ref();
const error = ref<AxiosError>();
    
const vInit: Directive = {
    mounted: el => init(el)
};

function init(el: HTMLCanvasElement) {
    return new Chart<'line'>(el, {
        type: 'line',
        data: {
            datasets: [Object.assign({
                backgroundColor: 'transparent',
                borderColor: rose,
                borderWidth: 2,
                pointRadius: 0,
                pointBackgroundColor: rose,
                fill: true,
                tension: 0.3,
                data: coordinates
            }, props.dataset)]
        },
        plugins: [
            mouseLine
        ],
        options: {
            interaction: {
                mode: 'nearest',
                intersect: false,
                axis: 'x'
            },
            layout: {
                padding: 0.6
            },
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                mouseLine: {
                    color: rose
                },
                legend: {
                    display: false
                    
                },
                tooltip: {
                    enabled: props.tooltip,
                    displayColors: false
                }
            },
            scales: {
                x: {
                    display: false,
                },
                y: {
                    display: false
                }
            },
        }
    });
}

async function load() {
    activity.value = true;

    return await axios.get(props.url, {
        params: Object.assign({
            range: selected.value
        }, props.params)
    }).then(response => {
        data.value = response.data;
        
        ranges.value = response.data.ranges;
        title.value = response.data.title;

        coordinates = Object.entries(data.value?.trend).map(([x, y]) => ({ x, y }));

        return data;
    })
        .catch(e => {
            error.value = e;
        })
        .finally(() => {
            activity.value = false;
        });
}

onMounted(async() => await load());
</script>

<template>
    <Card
        class="h-48 !p-0">
        <template #internal-header>
            <MetricHeader
                v-if="ranges"
                v-model="selected"
                class="p-4"
                :default-range="props.defaultRange"
                :ranges="ranges"
                :title="title"
                @update:model-value="load" />
        </template>

        <ActivityIndicator
            v-if="activity"
            center
            :type="Pulse" />
        
        <div
            v-else-if="error"
            class="flex flex-col h-full justify-center items-center gap-y-2">
            <ExclamationTriangleIcon class="w-10 h-10 text-red-500" />
            {{ error.message }}
        </div>

        <div
            v-else-if="data && coordinates.length"
            class="overflow-hidden">
            <canvas v-init="data" />
        </div>

        <div
            v-else
            class="flex flex-col h-full justify-center items-center gap-y-2">
            No Data
        </div>
    </Card>
</template>