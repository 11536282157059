<template>
    <div>
        <slot name="header" />

        <div class="rounded shadow">
            <slot />
        </div>

        <slot name="footer" />
    </div>
</template>