<script lang="ts" setup>
import { useSlots, type VNode, type VNodeArrayChildren } from 'vue';
import AsteriskIcon from '../../svg/asterisk-icon.svg';

withDefaults(defineProps<{
    block?: boolean;
    label?: string;
    description?: string|undefined;
    required?: boolean;
}>(), {
    block: false,
    required: false,
    description: undefined,
    label: ''
});

function findIdInVNodes(vnodeArray?: VNode[]|VNodeArrayChildren): string | undefined {
    if(!vnodeArray) {
        return;
    }
    for(const vnode of vnodeArray) {
        if(typeof vnode !== 'object' || !vnode) {
            continue;
        }
        if('props' in vnode && vnode.props && 'id' in vnode.props) {
            return vnode.props.id;
        }
        if('children' in vnode && Array.isArray(vnode.children)) {
            const id = findIdInVNodes(vnode.children);
            if(id) {
                return id;
            }
        }
    }

    return;
}

const slots = useSlots();
const id = findIdInVNodes(slots.default?.());
</script>

<template>
    <section
        class="flex flex-wrap border-b gap-y-2 last:border-none dark:border-neutral-700"
        :class="{ 'py-4': block, 'px-3 py-2': !block }">
        <div
            class="w-full"
            :class="{ 'lg:m-0 lg:w-1/2 lg:pr-2': !block }">
            <div class="flex items-center gap-x-1">
                <slot
                    :id="id"
                    name="label">
                    <label
                        :for="id"
                        class="block font-medium text-base text-neutral-700 dark:text-neutral-200">{{ label }}</label>
                    <AsteriskIcon
                        v-if="required"
                        class="text-red-500 h-[0.6rem] w-[0.6rem]" />
                </slot>
            </div>
            <p
                v-if="description"
                class="w-5/6 text-neutral-500 dark:text-neutral-400"
                :class="{ 'mb-2': !!block }">
                {{ description }}
            </p>
        </div>
        <div
            class="flex w-full flex-col justify-center"
            :class="{ 'lg:w-1/2': !block }">
            <div class="input-field is-empty form-group">
                <slot />
            </div>
        </div>
    </section>
</template>