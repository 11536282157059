import type { AxiosResponse } from 'axios';
import { AxiosError, type AxiosRequestConfig } from 'axios';
import type { BaseModel } from 'types';
import { computed, isRef, reactive, ref, toRaw, unref, type ComputedRef, type Ref, type UnwrapNestedRefs } from 'vue';
import axios from '../axios';

type UseResourceFormContext<T> = {
    errors: Ref<Record<string,string[]>|undefined>,
    form: UnwrapNestedRefs<T>,
    isSubmitting: Ref<boolean>,
    request(config?: AxiosRequestConfig): Promise<AxiosResponse>,
    save(config?: AxiosRequestConfig<Partial<T>>): Promise<AxiosResponse<T>>,
    get(config?: AxiosRequestConfig<T>): Promise<AxiosResponse<T>>,
    post(config?: AxiosRequestConfig<Partial<T>>): Promise<AxiosResponse<T>>,
    patch(config?: AxiosRequestConfig<Partial<T>>): Promise<AxiosResponse<T>>,
    put(config?: AxiosRequestConfig<T>): Promise<AxiosResponse<T>>,
    destroy(config?: AxiosRequestConfig<T>): Promise<AxiosResponse<T>>
}

type UriParam = string|number|undefined;

export function uri(endpoint: string, args: () => UriParam[]) {
    return computed(() => [endpoint, ...args()].filter(arg => arg !== undefined).join('/'));
}

export function useResourceForm<T extends Partial<BaseModel>|object>(resource: string|[string, () => UriParam[]]|ComputedRef<string>): UseResourceFormContext<T>
export function useResourceForm<T extends Partial<BaseModel>|object>(resourceFormConfig: AxiosRequestConfig<T>): UseResourceFormContext<T>
export default function useResourceForm<T extends Partial<BaseModel>|object>(resource?: string|[string, () => UriParam[]]|ComputedRef<string>|AxiosRequestConfig<T>, resourceFormConfig?: AxiosRequestConfig<T>): UseResourceFormContext<T> {
    if(Array.isArray(resource)) {
        resource = uri(...resource);
    }
    else if(!(isRef(resource) || typeof resource === 'string')) {
        resourceFormConfig = resource as AxiosRequestConfig<T>;
        resource = undefined;
    }

    const form = reactive<T>((resourceFormConfig?.data ?? {}) as T);
    const errors = ref<Record<string,string[]>>();
    const isSubmitting = ref(false);

    async function request(config?: AxiosRequestConfig) {
        isSubmitting.value = true;
        
        const mergedConfig = Object.assign({
            baseURL: '/api/',
            url: resource && toRaw(unref(resource)),
            data: toRaw(form)
        }, resourceFormConfig, config);
        
        return axios.request(mergedConfig)
            .then(response => {
                errors.value = undefined;

                return response;
            })
            .catch((e: AxiosError<{errors: Record<string,string[]>}>) => {
                errors.value = e.response?.data.errors;

                throw e;
            })
            .finally(() => {
                isSubmitting.value = false;
            });
    }

    function get(config?: AxiosRequestConfig<T>): Promise<AxiosResponse<T>> {
        return request(Object.assign({}, config, { method: 'GET' }));
    }

    function destroy(config?: AxiosRequestConfig<T>): Promise<AxiosResponse<T>> {
        return request(Object.assign({}, config, { method: 'DELETE' }));
    }

    function post(config?: AxiosRequestConfig<Partial<T>>): Promise<AxiosResponse<T>> {
        return request(Object.assign({}, config, { method: 'POST' }));
    }

    function put(config?: AxiosRequestConfig<T>): Promise<AxiosResponse<T>> {
        return request(Object.assign({}, config, { method: 'PUT' }));
    }

    function patch(config?: AxiosRequestConfig<Partial<T>>): Promise<AxiosResponse<T>> {
        return request(Object.assign({}, config, { method: 'PATCH' }));
    }

    function save(config?: AxiosRequestConfig<Partial<T>>): Promise<AxiosResponse<T>> {
        if('id' in form && form.id) {
            return put(config as AxiosRequestConfig<T>);
        }
        
        return post(config);
    }

    return {
        save,
        errors,
        form,
        request,
        destroy,
        get,
        post,
        put,
        patch,
        isSubmitting
    };
}