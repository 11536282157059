<script lang="ts" setup>
import { ArrowUturnLeftIcon, ClipboardDocumentListIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import { SelectField } from '@vue-interface/select-field';
import type { Audit, LengthAwarePaginator } from 'types';
import { onBeforeMount, onBeforeUnmount } from 'vue';
import AdminLayout from '../Layouts/AdminLayout.vue';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import BadgeDescription from '../components/audits/BadgeDescription.vue';
import Description from '../components/audits/Description.vue';
import IconDescription from '../components/audits/IconDescription.vue';
import { route } from '../composables/routes';
import { echo } from '../plugins/Echo';

defineProps<{
    audits?: LengthAwarePaginator<Audit>,
    users?: {
        value: any;
        label: string;
    }[];
}>();

defineOptions({
    layout: AdminLayout
});

const only = ['audits', 'users'];

function restore(audit: Audit) {
    router.patch(route('audits.restore', { audit: audit.id }), {}, {
        only: ['audits', 'flash']
    });
}

onBeforeMount(() => {
    echo.private('super')
        .listen('.AuditCreated', () => router.reload({ only }))
        .listen('.AuditUpdated', () => router.reload({ only }))
        .listen('.AuditDeleted', () => router.reload({ only }));
});

onBeforeUnmount(() => {
    echo.private('super')
        .stopListening('.AuditCreated')
        .stopListening('.AuditUpdated')
        .stopListening('.AuditDeleted');
});
</script>

<template>
    <SearchableListGroup
        header="Activity"
        header-description="View and Filter Activity across Alchemy"
        plural="audits"
        :only="only"
        :sort-options="[
            { label: 'Most Recent', value: 'created_at,desc' },
            { label: 'Oldest First', value: 'created_at,asc' },
        ]"
        :searchable="false"
        :icons="{
            default: ClipboardDocumentListIcon,
        }"
        :response="audits">
        <template #icon="{ model }">
            <IconDescription
                v-if="model.description?.icon"
                :description="model.description.icon" />
        </template>
        <template #badges="{ model }">
            <BadgeDescription
                v-if="model.description?.badge"
                :description="model.description.badge" />
        </template>
        <template #title="{ model }">
            <Description
                v-if="model.description"
                :description="model.description" />
            <template v-else>
                Audit description not available.
            </template>
        </template>
        <template #left-filters="{ formData }">
            <SelectField
                v-model="formData.event"
                label="Event"
                class="min-w-[5rem]">
                <option :value="undefined">
                    All
                </option>
                <option value="created">
                    Created
                </option>
                <option value="updated">
                    Updated
                </option>
                <option value="deleted">
                    Deleted
                </option>
                <option value="restored">
                    Restored
                </option>
            </SelectField>
            <SelectField
                v-model="formData.type"
                label="Type"
                class="min-w-[5rem]">
                <option :value="undefined">
                    All
                </option>
                <option value="App\Models\Instance">
                    Instances
                </option>
                <option value="App\Models\Agency">
                    Agencies
                </option>
                <option value="App\Models\SubscriberList">
                    Lists
                </option>
                <option value="App\Models\Template">
                    Templates
                </option>
                <option value="App\Models\SavedRow">
                    Saved Rows
                </option>
                <option value="App\Models\Send">
                    Sends
                </option>
                <option value="App\Models\Subscriber">
                    Subscribers
                </option>
                <option value="App\Models\Subscription">
                    Subscriptions
                </option>
                <option value="App\Models\User">
                    Users
                </option>
            </SelectField>
            <SelectField
                v-model="formData.user_id"
                label="User"
                class="min-w-[5rem]">
                <option :value="undefined">
                    All
                </option>
                <option
                    v-for="user in users"
                    :key="user.value"
                    :value="user.value">
                    {{ user.label }}
                </option>
            </SelectField>
        </template>
        <template #list-actions="{ model }: { model: any }">
            <button
                v-if="model.auditable?.deleted_at"
                class="btn btn-xs btn-neutral-300 dark:btn-neutral-600"
                @click="restore(model)">
                <ArrowUturnLeftIcon class="m-1 h-4 w-4" /> Restore
            </button>
        </template>
    </SearchableListGroup>
</template>