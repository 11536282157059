<script lang="ts" setup>
import { PlusCircleIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { InputField } from '@vue-interface/input-field';
import { InputList } from '@vue-interface/input-list';
import { nextTick, ref } from 'vue';
import { focus } from '../utils/utils';

const props = defineProps<{
    modelValue: string[],
    name: string,
    errors?: Record<string, string[]>
    error?: string | undefined
}>();

const input = ref();
const tags = ref(props.modelValue);

function add(tags: string[], index: number) {
    if(tags[index] !== '') {
        tags.push('');
    }

    nextTick(() => {
        focus(`${props.name}.${tags.length - 1}`);
    });
}

</script>

<template>
    <InputList :items="tags">
        <template #default="{ index, isLastRow, remove }">
            <div class="flex items-start justify-center gap-2">
                <div class="my-1 flex w-full">
                    <InputField
                        :id="`${name}.${index}`"
                        ref="input"
                        v-model="tags[index]"
                        :name="`${name}.${index}`"
                        :errors="errors"
                        class="w-full" />
                </div>
                <div class="flex gap-2 mt-[.33rem]">
                    <button
                        v-if="isLastRow"
                        type="button"
                        class="btn btn-neutral-300 dark:btn-neutral-700"
                        @click="add(tags, index)">
                        <PlusCircleIcon class="h-6 w-6" />
                    </button>
                    <button
                        v-if="index !== 0"
                        type="button"
                        class="btn btn-neutral-300 dark:btn-neutral-700"
                        @click="remove">
                        <TrashIcon class="h-6 w-6 text-red-400" />
                    </button>
                </div>
            </div>
        </template>
    </InputList>
</template>