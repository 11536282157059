<script lang="ts" setup>
import { Link } from '@inertiajs/vue3';
import { computed, type Component } from 'vue';

const props = withDefaults(defineProps<{
    activity?: boolean;
    description?: string;
    title?: string;
    href?: string;
    canView?: boolean;
    icon?: Component | string;
}>(), {
    activity: false,
    description: undefined,
    title: undefined,
    href: undefined,
    icon: undefined,
    canView: true,
});

const isLink = computed(() => {
    return !!props.href && props.canView;
});

const component = computed(() => {
    if(isLink.value) {
        return Link;
    }

    return 'div';
});
</script>

<template>
    <div
        class="relative flex items-center justify-between border-b border-neutral-100 bg-white p-3 text-neutral-700 first:rounded-t last:rounded-b last:border-0 dark:border-neutral-900 dark:bg-neutral-800 dark:text-neutral-300"
        :class="{'animate-pulse bg-neutral-200 dark:bg-neutral-700': !!activity}">
        <div class="flex w-full items-center">
            <div
                class="mr-2 shrink-0">
                <slot name="icon">
                    <Component
                        :is="icon"
                        class="h-8 w-8" />
                </slot>
            </div>
            <!-- The div below this had overflow-hidden for some reason. Removing for now -->
            <div class="w-full leading-none">
                <slot>
                    <div class="flex items-center gap-2">
                        <Component
                            :is="component"
                            v-bind="{ href }"
                            class="font-heading m-0 block break-all"
                            :class="{'cursor-pointer text-rose-500 hover:underline': isLink}">
                            <slot name="title">
                                {{ title }}
                            </slot>
                        </Component>
                        <slot name="badges" />
                    </div>
                </slot>
                
                <span
                    v-if="$slots.description || description"
                    class="break-all text-xs text-neutral-500 dark:text-neutral-400">
                    <slot name="description">{{ description }}</slot>
                </span>

                <div
                    v-if="$slots.tags"
                    class="mt-1 flex gap-1">
                    <slot name="tags" />
                </div>
            </div>
        </div>
        <slot name="list-actions" />
    </div>
</template>