<script lang="ts" setup>
import { computed } from 'vue';
import type { User } from '../types';

const props = defineProps<{
    authUser: User
}>();

const initials = computed(
    () => (props.authUser.name || props.authUser.email)
        .split(' ')
        .slice(0, 2)
        .map(str => str.slice(0, 1))
        .join('')
);
</script>

<template>
    <div class="flex h-10 w-10 items-center justify-center rounded-full bg-rose-500 text-neutral-200 dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-700">
        {{ initials }}
    </div>
</template>