<script lang="ts" setup>
import { Card } from '@vue-interface/card';
import { InputField } from '@vue-interface/input-field';
import { LightSwitchField } from '@vue-interface/light-switch-field';
import { SelectField } from '@vue-interface/select-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { User } from 'types';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';

const props = defineProps<{
    user?: User,
    url: string,
}>();

const method = props.user?.id ? 'put' : 'post';

const timezones = {
    'America/Chicago': 'Central',
    'America/Denver': 'Mountain',
    'America/Los_Angeles': 'Pacific',
    'America/New_York': 'Eastern',
    'America/Phoenix': 'MST - AZ (except Navajo)',
};

const form = useForm(method, props.url, Object.assign({
    name: undefined,
    email: undefined,
    super_admin: false,
    timezone: undefined
}, props.user));
</script>

<template>
    <FormLayout
        :submit="form.submit"
        :disabled="form.processing"
        :activity="form.processing"
        :button-label="!user?.id ? 'Submit' : 'Save'">
        <Card>
            <FormField
                label="Name"
                description="The first and last name of the user.">
                <InputField
                    id="name"
                    v-model="form.name"
                    name="name"
                    :error="form.errors.name"
                    @change="form.validate('name')" />
            </FormField>
            <FormField
                label="Email"
                description="The email address of the user.">
                <InputField
                    id="email"
                    v-model="form.email"
                    name="email"
                    :error="form.errors.email"
                    @change="form.validate('email')" />
            </FormField>
            <FormField
                label="Super Admin"
                description="Super admin users have full access to Alchemy.">
                <div class="flex items-center gap-2">
                    <LightSwitchField
                        id="super_admin"
                        v-model="form.super_admin"
                        :disabled="user?.has_assignments"
                        :error="form.errors.super_admin"
                        class="light-switch-field-lg"
                        name="super_admin"
                        :on-value="true"
                        :off-value="false"
                        @update:model-value="form.validate('super_admin')" />

                    <label
                        v-if="user?.has_assignments"
                        class="text-sm text-rose-500">
                        Remove all Agency and List assignments before making this user a Super Admin.
                    </label>
                </div>
            </FormField>
            <FormField
                label="Timezone"
                description="Personalize the timezone by overriding the server default.">
                <SelectField
                    id="timezone"
                    v-model="form.timezone"
                    name="timezone"
                    :error="form.errors.timezone"
                    @update:model-value="form.validate('timezone')">
                    <option
                        v-for="(label, value) in timezones"
                        :key="value"
                        :value="value">
                        {{ label }}
                    </option>
                </SelectField>
            </FormField>
        </Card>
    </FormLayout>
</template>