<script lang="ts" setup>
import { ChevronRightIcon, HomeIcon } from '@heroicons/vue/24/outline';
import { Link } from '@inertiajs/vue3';
import type { Component } from 'vue';
import type { Breadcrumb } from './Navigation.vue';

const props = withDefaults(defineProps<{
    breadcrumbs?: Breadcrumb[]|undefined
}>(), {
    breadcrumbs: () => []
});

const icons: Record<string,Component> = {
    HomeIcon,
};

</script>

<template>
    <nav
        v-if="breadcrumbs.length > 1"
        aria-label="Breadcrumb"
        class="mb-6 dark:text-neutral-400">
        <ol class="flex items-center gap-x-1 text-sm">
            <template
                v-for="breadcrumb in props.breadcrumbs"
                :key="breadcrumb.url">
                <li>
                    <Link
                        v-if="breadcrumb.data?.icon"
                        :class="{
                            'text-rose-500': breadcrumb?.current
                        }"
                        :aria-current="breadcrumb?.current ? 'page' : undefined"
                        class="hover:underline"
                        :href="breadcrumb.url || '#'">
                        <Component
                            :is="icons[breadcrumb.data.icon]"
                            class="w-[1.15rem] h-[1.15rem]" />
                    </Link>
                    <Link
                        v-else
                        :class="{
                            'text-rose-500': breadcrumb?.current
                        }"
                        :aria-current="breadcrumb?.current ? 'page' : undefined"
                        class="hover:underline"
                        :href="breadcrumb.url?.replace(/(.+?)\?(.+)/, '$1') || '#'">
                        {{ breadcrumb.title }}
                    </Link>
                </li>
                <li v-if="!breadcrumb?.current">
                    <ChevronRightIcon class="w-3 h-3" />
                </li>
            </template>
        </ol>   
    </nav>
</template>