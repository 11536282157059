<script setup lang="ts">
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';

const props = defineProps<{
    label?: string
    reverse?: boolean
    value?: string | number | boolean
}>();

const computedValue = computed(() => {
    if(typeof props.value === 'boolean') {
        return props.value;
    }
    else if(typeof props.value === 'string') {
        return props.value.toLowerCase().trim() === 'true';
    }
    
    return props.value === 1;
});
</script>

<template>
    <div class="h5 mb-0">
        <XCircleIcon
            v-if="reverse ? computedValue === true : computedValue === false"
            class="h-6 w-6 text-red-500" />
        <CheckCircleIcon
            v-if="reverse ? computedValue === false : computedValue === true"
            class="h-6 w-6 text-emerald-500" />
    </div>
</template>