<script lang="ts" setup>
import { Pulse } from '@vue-interface/activity-indicator';
import { SelectField } from '@vue-interface/select-field';
import type { MgDataVariable, SubscriberList } from 'types';
import { computed, onMounted, ref, watchEffect } from 'vue';
import axios from '../../axios';

const props = defineProps<{
    id: string,
    name: string,
    item: MgDataVariable,
    index: number,
    errors: any,
    modelValue: string,
    list: SubscriberList
}>();

const content = ref();
const activity = ref(false);

async function getJobs() {
    activity.value = true;
    
    return await axios.get('mg/campaign/marketing/jobs', {
        params: {
            list_id: props.list.id
        },
    }).then(response => {
        content.value = response.data;

        return response.data;
    }).finally(() => {
        activity.value = false;
    });
}

const emit = defineEmits<{
    'update:modelValue': [value: string],
    'update:formattedValue': [value: string],
    'update:readableValue': [value: string]
}>();

const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', String(value));
    }
});

const isDefault = computed(() => {
    return model.value === props.item.defaultValue;
});

watchEffect(() => {
    if(content.value) {
        emit('update:readableValue', formatReadableValue(
            content.value?.find((i: any) => i.id == model.value))
        );
    }
    if(isDefault.value) {
        return emit('update:formattedValue', props.item.defaultValue);
    }
    emit('update:formattedValue', `'${model.value}'`);
});

onMounted(async () => {
    await getJobs();
});
</script>

<script lang="ts">
export function formatReadableValue(value: any) {
    if(!value) {
        return '';
    }
    return value.subjectLine;
}

export function formatListValue(value: any) {
    if(!value) {
        return '';
    }
    return `${value.id} - ${value.subjectLine.substring(0, 60)}`;
}
</script>

<template>
    <div class="flex items-start gap-x-2">
        <SelectField
            :id="id"
            v-model="model"
            :activity="activity"
            :indicator="Pulse"
            indicator-size="sm"
            :name="name"
            :error="errors[name]"
            class="w-full">
            <option>
                {{ item.defaultValue }}
            </option>
            <option
                v-for="i in content"
                :key="i.id"
                :value="i.id">
                {{ formatListValue(i) }}
            </option>
        </SelectField>
        <slot
            v-if="!isDefault"
            name="reset" />
    </div>
</template>