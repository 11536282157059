<script lang="ts" setup>
import { SelectField } from '@vue-interface/select-field';
import type { ListUser, SubscriberList, User } from 'types';
import { ref } from 'vue';
import { route } from '../composables/routes';
import useResourceForm from '../composables/useResourceForm';
import Autocomplete from './Autocomplete.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';
import UserAbilitiesField from './UserAbilitiesField.vue';

const props = defineProps<{
    list?: SubscriberList;
    listUser?: ListUser;
    user: User;
    userLists?: (SubscriberList & { pivot: ListUser })[];
}>();


const emit = defineEmits(['success', 'error']);
const activity = ref(false);

const url = route('api.users.lists.store', {
    user: props.user.id
});

const { form, errors, request } = useResourceForm(url, {
    method: 'post',
    data: {
        list_id: undefined,
        email: undefined,
        role: undefined,
        abilities: undefined,
        ...props.listUser
    }
});

function onSubmit() {
    activity.value = true;
    request().then((response) => {
        emit('success', response);
    }).catch((error) => {
        emit('error', error);
    }).finally(() => {
        activity.value = false;
    });
}
</script>

<template>
    <div class="w-[25rem]">
        <div class="flex flex-col gap-y-1">
            <h2 class="text-3xl font-semibold">
                {{ listUser?.role ? 'Edit Permissions' : 'Assign to List' }}
            </h2>
            <h3 class="text-neutral-400 dark:text-neutral-500 font-semibold">
                {{ list?.name }}
            </h3>
        </div>

        <FormLayout
            :submit="onSubmit"
            :button-block="true"
            :button-label="listUser?.role ? 'Save' : 'Submit'"
            :disabled="activity"
            :activity="activity">
            <FormField
                v-if="!listUser?.role"
                label="Name"
                description="The list you wish to assign the user."
                block>
                <Autocomplete
                    id="list_id"
                    v-model="form.list_id"
                    name="list_id"
                    namespace="user-lists"
                    only="userLists"
                    :description="model => model.agency?.name ?? ''"
                    :response="userLists"
                    :errors="errors" />
            </FormField>
            <FormField
                label="Role"
                description="The role will determine how the user may interact with the list."
                block>
                <SelectField
                    id="role"
                    v-model="form.role"
                    name="role"
                    :errors="errors">
                    <option value="admin">
                        Admin
                    </option>
                    <option value="user">
                        User
                    </option>
                </SelectField>
            </FormField>
            <FormField
                v-if="form.role === 'user'"
                label="Abilities"
                description="The abilities give users privileges in the app."
                block>
                <UserAbilitiesField v-model="form.abilities" />
            </FormField>
        </FormLayout>
    </div>
</template>