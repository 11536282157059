<script lang="ts" setup>
import { Pulse } from '@vue-interface/activity-indicator';
import { SelectField } from '@vue-interface/select-field';
import type { Instance } from 'types';
import { onMounted, ref, toRef, watch } from 'vue';
import axios from '../axios';

type FolderData = {
    id: number,
    name: string,
    children: FolderData[],
    parentId: number,
    path: string,
}

const emit = defineEmits<{
    (e: 'update:modelValue', value: any): void,
    (e: 'activity', value: boolean): void,
    (e: 'change', value: any): void,
}>();

const props = defineProps<{
    id?: string | number | undefined,
    name: string,
    instance: Instance|undefined,
    errors?: any,
    error?: any,
    modelValue: any,
}>();

const activity = ref(false);
const folders = ref<FolderData[]>([]);
const options = ref<{ label: string; value: number }[]>([]);
const modelId = ref<string | number | undefined>(props.modelValue ? props.modelValue.id : undefined);
const instance = toRef(props, 'instance');

function extractFolders(data: any) {
    return (data || []).reduce((carry: any[], folder: any) => {
        return carry.concat([folder, ...extractFolders(folder.children)]);
    }, []);
}

function extractOptions(data: any[], depth:number = 0): any[] {
    return (data || []).reduce((carry, folder) => {
        return carry.concat([{
            label: `${Array(depth + 1).join('--')} ${folder.name}`.trim(),
            value: folder.id,
        }, ...extractOptions(folder.children, depth + 1)]);
    }, []);
}

async function fetch(): Promise<void> {
    if(!props.instance) {
        return;
    }
    activity.value = true;
    return await axios.get('mg/folders', {
        params: {
            instance_id: props.instance.id
        }
    }).then(response => {
        folders.value = extractFolders(response.data);
        options.value = extractOptions(response.data);
    }).finally(() => {
        activity.value = false;
    });
}

watch(modelId, (value: any) => {
    const match = folders.value.find(({ id }: { id: number }) => {
        return id == parseInt(value);
    });
    emit('update:modelValue', match);
});

watch(instance, () => {
    modelId.value = undefined;
    fetch();
});

watch(activity, (value: any) => {
    emit('activity', value);
});

onMounted(async() => {
    await fetch();
});

</script>

<template>
    <SelectField
        :id="id?.toString()"
        v-model="modelId"
        :name="name"
        :disabled="activity"
        :activity="activity"
        :indicator="Pulse"
        :errors="errors"
        :error="error"
        indicator-size="xs"
        @change="value => $emit('change', value)">
        <option
            v-for="folder in options"
            :key="folder.value"
            :value="folder.value">
            {{ folder.label }}
        </option>
    </SelectField>
</template>