<script lang="ts" setup>
import { CheckCircleIcon, ClipboardDocumentListIcon, NoSymbolIcon, PencilSquareIcon, TrashIcon, UserMinusIcon, UserPlusIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { Link, router } from '@inertiajs/vue3';
import { Card } from '@vue-interface/card';
import { SelectField } from '@vue-interface/select-field';
import { format } from 'date-fns';
import type { Agency, Audit, Can, Kill, LengthAwarePaginator, Subscriber, SubscriberList, Subscription, User } from 'types';
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import SubscriberIcon from '../../svg/subscriber-icon.svg';
import axios from '../axios';
import Header from '../components/Header.vue';
import MultiselectField from '../components/MultiselectField.vue';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import Show from '../components/Show.vue';
import BadgeDescription from '../components/audits/BadgeDescription.vue';
import Description from '../components/audits/Description.vue';
import IconDescription from '../components/audits/IconDescription.vue';
import { route } from '../composables/routes';
import { echo } from '../plugins/Echo';

const props = defineProps<{
    agency: Agency
    audits?: LengthAwarePaginator<Audit>;
    authUser: User;
    list: SubscriberList;
    kills?: LengthAwarePaginator<Kill>;
    subscriber: Subscriber;
    subscriberCan: Can,
    subscriptions: Subscription[]
    subscription: Subscription
}>();

const only = ['audits', 'subscriber', 'subscription', 'kills'];

const subscriberMultiselect = ref();
const subscriptionMultiselect = ref();

async function onClickUnsubscribe() {
    if(confirm(`Are you sure you want to unsubscribe ${props.subscriber.email} from ${props.subscription.catalog?.name}?`)) {
        router.put(route('subscription.unsubscribe', {
            agency: props.agency.slug,
            list: props.list.slug,
            subscriber: props.subscriber.id,
            subscription: props.subscription.id
        }), {}, {
            only,
            preserveScroll: true
        });
    }
}

async function onClickResubscribe() {
    if(confirm(`Are you sure you want to resubscribe ${props.subscriber.email} from ${props.subscription.catalog?.name}?`)) {
        router.put(route('subscription.resubscribe', {
            agency: props.agency.slug,
            list: props.list.slug,
            subscriber: props.subscriber.id,
            subscription: props.subscription.id
        }), {}, {
            only,
            preserveScroll: true
        });
    }
}

async function onUpdateSubscriptionTags(tags: string[]) {
    router.put(route('subscription.update', {
        agency: props.agency.slug,
        list: props.list.slug,
        subscriber: props.subscriber.id,
        subscription: props.subscription.id
    }), {
        tags
    }, {
        only,
        preserveScroll: true
    });
}

function onUpdateSubscriberTags(tags: string[]) {
    router.put(route('subscribers.update', {
        agency: props.agency.slug,
        list: props.list.slug,
        subscriber: props.subscriber.id
    }), {
        tags
    }, {
        only,
        preserveScroll: true
    });    
}

function onDeleteKill(kill: Kill) {
    axios.delete(route('api.kills.destroy', { kill: kill.id }))
        .finally(() => {
            router.reload({
                only
            });
        });
}

function countValues(...args: any[]) {
    let count = 0;

    for(const arg of args) {
        if(arg) {
            count++;
        }
    }

    return count;
}

function reload(event: { model: Subscriber }) {
    if(event.model.id === props.subscriber.id) {
        router.reload({
            only
        });
    }
}

onBeforeMount(() => {
    echo.private(`App.Models.SubscriberList.${props.list.id}`)
        .listen('.SubscriberUpdated', reload)
        .listen('.SubscriberDeleted', reload);
});

onBeforeUnmount(() => {
    echo.private(`App.Models.SubscriberList.${props.list.id}`)
        .stopListening('.SubscriberUpdated')
        .stopListening('.SubscriberDeleted');
});
</script>

<template>
    <Show
        :icon="SubscriberIcon"
        title="Subscriber Details">
        <template #actions>
            <Link
                v-if="subscriberCan.update"
                :href="route('subscribers.edit', { agency: agency.slug, list: list.slug, subscriber: subscriber.id })"
                class="btn-primary btn capitalize">
                Edit Subscriber
            </Link>
        </template>
        
        <Card>
            <div>
                <div
                    v-if="subscriber.email"
                    class="border-b dark:border-neutral-700 p-3">
                    <div class="font-bold">
                        Email
                    </div>
                    <div>
                        {{ subscriber.email }}
                    </div>
                </div>

                <div
                    v-if="subscriber.first || subscriber.last"
                    class="border-b dark:border-neutral-700 p-3 grid"
                    :class="{
                        'grid-cols-1': countValues(subscriber.first, subscriber.last) === 1,
                        'grid-cols-2': countValues(subscriber.first, subscriber.last) === 2
                    }">
                    <div
                        v-if="subscriber.first"
                        class="font-bold">
                        First Name
                    </div>
                    <div
                        v-if="subscriber.last"
                        class="font-bold">
                        Last Name
                    </div>
                    <div v-if="subscriber.first">
                        {{ subscriber.first }}
                    </div>
                    <div v-if="subscriber.last">
                        {{ subscriber.last }}
                    </div>
                </div>

                <div
                    v-if="subscriber.street"
                    class="border-b dark:border-neutral-700 p-3">
                    <div class="font-bold">
                        Street
                    </div>
                    <div>
                        {{ subscriber.street }}
                    </div>
                </div>

                <div
                    v-if="subscriber.city || subscriber.state || subscriber.zip"
                    class="border-b dark:border-neutral-700 p-3 grid"
                    :class="{
                        'grid-cols-1': countValues(subscriber.city, subscriber.state, subscriber.zip) === 1,
                        'grid-cols-2': countValues(subscriber.city, subscriber.state, subscriber.zip) === 2,
                        'grid-cols-3': countValues(subscriber.city, subscriber.state, subscriber.zip) === 3
                    }">
                    <div
                        v-if="subscriber.city"
                        class="font-bold">
                        City
                    </div>
                    <div
                        v-if="subscriber.state"
                        class="font-bold">
                        State
                    </div>
                    <div
                        v-if="subscriber.zip"
                        class="font-bold">
                        Zip
                    </div>
                    <div v-if="subscriber.city">
                        {{ subscriber.city }}
                    </div>
                    <div v-if="subscriber.state">
                        {{ subscriber.state }}
                    </div>
                    <div v-if="subscriber.zip">
                        {{ subscriber.zip }}
                    </div>
                </div>

                <div
                    v-if="subscriber.phone"
                    class="border-b dark:border-neutral-700 p-3">
                    <div class="font-bold">
                        Phone
                    </div>
                    <div>
                        {{ subscriber.phone }}
                    </div>
                </div>

                <div
                    v-if="subscriber.channel"
                    class="border-b dark:border-neutral-700 p-3">
                    <div class="font-bold">
                        Channel
                    </div>
                    <div>
                        {{ subscriber.channel }}
                    </div>
                </div>

                <div
                    class="p-3">
                    <div class="flex gap-x-2 items-center justify-between mb-1">
                        <div>
                            <div class="font-bold">
                                Tags
                            </div>
                            <div
                                v-if="subscriberCan.update"
                                class="text-neutral-400 dark:text-neutral-500">
                                Type the tag and hit the enter key to save.
                            </div>
                        </div>
                        <PencilSquareIcon
                            v-if="subscriberCan.update"
                            class="h-5 w-5 cursor-pointer"
                            @click="subscriberMultiselect?.focus()" />
                    </div>
                    <div>
                        <MultiselectField
                            v-if="subscriberCan.update || subscriberCan.delete"
                            ref="subscriberMultiselect"
                            mode="tags"
                            :can-clear="false"
                            :options="subscriber.tags"
                            :create-option="true"
                            :searchable="true"
                            :show-options="false"
                            :allow-false="true"
                            :model-value="subscriber.tags"
                            @update:model-value="onUpdateSubscriberTags" />
                        <div v-else>
                            <div
                                v-for="tag in subscriber.tags"
                                :key="tag"
                                class="m-1 inline-flex items-center justify-center rounded bg-neutral-200 px-1 dark:bg-neutral-700">
                                {{ tag }}
                            </div>  
                        </div>
                    </div>
                </div>

                <template v-if="subscriber.subscriber_field">
                    <div
                        v-if="subscriber.subscriber_field.member !== null || subscriber.subscriber_field.type"
                        class="border-b dark:border-neutral-700 p-3 grid"
                        :class="{
                            'grid-cols-1': countValues(subscriber.subscriber_field.member !== null, subscriber.subscriber_field.type) === 1,
                            'grid-cols-2': countValues(subscriber.subscriber_field.member !== null, subscriber.subscriber_field.type) === 2,
                        }">
                        <div
                            class="font-bold">
                            Member
                        </div>
                        <div
                            v-if="subscriber.subscriber_field.type"
                            class="font-bold">
                            Type
                        </div>
                        <div>
                            <CheckCircleIcon
                                v-if="subscriber.subscriber_field.member"
                                class="text-emerald-500 h-6 w-6" />
                            <XCircleIcon
                                v-else-if="!subscriber.subscriber_field.member"
                                class="text-red-500 h-6 w-6" />
                        </div>
                        <div v-if="subscriber.subscriber_field.type">
                            {{ subscriber.subscriber_field.type }}
                        </div>
                    </div>

                    <div
                        v-if="subscriber.subscriber_field.fields"
                        class="grid grid-cols-2">
                        <div
                            v-for="([key, value], index) of Object.entries(subscriber.subscriber_field.fields).filter(([_, value]) => value !== null)"
                            :key="key"
                            :class="{
                                'p-3': index % 2 == 0,
                            }"
                            class="border-b last:border-none dark:border-neutral-700 pt-3">
                            <div class="font-bold capitalize">
                                {{ key.replaceAll('_', ' ') }}
                            </div>
                            <div class="">
                                {{ value }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </Card>

        <Card v-if="subscriptions?.length">
            <template #header>
                <div class="flex justify-between items-center">
                    <Header
                        title="Subscriptions"
                        :icon="UserPlusIcon" />
                    <div class="flex items-end gap-4">
                        <div v-if="subscriberCan.delete">
                            <button
                                class="btn-primary btn capitalize group !pl-2 flex items-center gap-x-1"
                                @click="subscription.unsub ? onClickResubscribe() : onClickUnsubscribe()">
                                <template v-if="subscription.unsub">
                                    <UserPlusIcon class="h-6 w-6" /> Resubscribe
                                </template>
                                <template v-else>
                                    <UserMinusIcon class="h-6 w-6" /> Unsubscribe
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
            </template>
            <div>
                <div
                    class="border-b dark:border-neutral-700 p-3">
                    <div class="font-bold mb-1">
                        Catalog
                    </div>
                    <div v-if="subscriptions.length > 1">
                        <SelectField
                            :model-value="subscription.id"
                            form-control-class="w-auto form-select"
                            @update:model-value="id => router.visit(route('subscription.show', {
                                agency: agency.slug,
                                list: list.slug,
                                subscriber: subscriber.id,
                                subscription: id
                            }), {
                                preserveScroll: true
                            })">
                            <option
                                v-for="item in subscriptions"
                                :key="item.id"
                                :value="item.id">
                                {{ item.catalog?.name }}
                            </option>
                        </SelectField>
                    </div>
                    <div v-else>
                        {{ subscription.catalog?.name }}
                    </div>
                </div>

                <div
                    class="border-b dark:border-neutral-700 p-3">
                    <div class="font-bold">
                        Lists
                    </div>
                    <div
                        v-for="item in subscription.lists" 
                        :key="item.id">
                        {{ item.name }}
                    </div>
                </div>

                <div
                    v-if="subscription.created_at"
                    class="border-b dark:border-neutral-700 p-3">
                    <div class="font-bold">
                        Subscribed Date
                    </div>
                    <div>
                        {{ format(new Date(subscription.created_at), 'PPPp') }}
                    </div>
                </div>

                <div
                    class="border-b dark:border-neutral-700 p-3 grid"
                    :class="`grid-cols-${!!subscription.unsub ? 2 : 1}`">
                    <div
                        class="font-bold">
                        Subscribed
                    </div>
                    <div
                        v-if="!!subscription.unsub"
                        class="font-bold">
                        Unsubscribed Date
                    </div>
                    <div>
                        <CheckCircleIcon
                            v-if="!subscription.unsub"
                            class="text-emerald-500 h-6 w-6" />
                        <XCircleIcon
                            v-else-if="!!subscription.unsub"
                            class="text-red-500 h-6 w-6" />
                    </div>
                    <div v-if="!!subscription.unsub">
                        {{ format(new Date(subscription.unsub), 'PPPp') }}
                    </div>
                </div>

                <div
                    v-if="subscription.last_delivered || subscription.delivered_count !== null"
                    class="border-b dark:border-neutral-700 p-3 grid"
                    :class="{
                        'grid-cols-1': countValues(subscription.last_delivered, subscription.delivered_count !== null) === 1,
                        'grid-cols-2': countValues(subscription.last_delivered, subscription.delivered_count !== null) === 2,
                    }">
                    <div
                        v-if="subscription.last_delivered"
                        class="font-bold">
                        Last Delivered
                    </div>
                    <div
                        v-if="subscription.delivered_count !== null"
                        class="font-bold">
                        Delivered Count
                    </div>
                    <div v-if="subscription.last_delivered">
                        {{ format(new Date(subscription.last_delivered), 'PPPp') }}
                    </div>
                    <div v-if="subscription.delivered_count !== null">
                        {{ subscription.delivered_count }}
                    </div>
                </div>

                <div
                    v-if="subscriber.last_click || subscription.click_count !== null"
                    class="border-b dark:border-neutral-700 p-3 grid"
                    :class="{
                        'grid-cols-1': countValues(subscriber.last_click, subscription.click_count !== null) === 1,
                        'grid-cols-2': countValues(subscriber.last_click, subscription.click_count !== null) === 2,
                    }">
                    <div
                        v-if="subscriber.last_click"
                        class="font-bold">
                        Last Click
                    </div>
                    <div
                        v-if="subscription.click_count !== null"
                        class="font-bold">
                        Click Count
                    </div>
                    <div v-if="subscriber.last_click">
                        {{ format(new Date(subscriber.last_click), 'PPPp') }}
                    </div>
                    <div v-if="subscription.click_count !== null">
                        {{ subscription.click_count }}
                    </div>
                </div>

                <div
                    v-if="subscription.last_open || subscription.open_count !== null"
                    class="border-b dark:border-neutral-700 p-3 grid"
                    :class="{
                        'grid-cols-1': countValues(subscription.last_open, subscription.open_count !== null) === 1,
                        'grid-cols-2': countValues(subscription.last_open, subscription.open_count !== null) === 2,
                    }">
                    <div
                        v-if="subscription.last_open"
                        class="font-bold">
                        Last Open
                    </div>
                    <div
                        v-if="subscription.open_count !== null"
                        class="font-bold">
                        Open Count
                    </div>
                    <div v-if="subscription.last_open">
                        {{ format(new Date(subscription.last_open), 'PPPp') }}
                    </div>
                    <div v-if="subscription.open_count !== null">
                        {{ subscription.open_count }}
                    </div>
                </div>

                <div class="p-3">
                    <div class="flex gap-x-2 items-center justify-between mb-1">
                        <div>
                            <div class="font-bold">
                                Tags
                            </div>
                            <div
                                v-if="subscriberCan.update"
                                class="text-neutral-400 dark:text-neutral-500">
                                Type the tag and hit the enter key to save.
                            </div>
                        </div>
                        <PencilSquareIcon
                            v-if="subscriberCan.update"
                            class="h-5 w-5 cursor-pointer"
                            @click="subscriptionMultiselect?.focus()" />
                    </div>
                    <div>
                        <MultiselectField
                            v-if="subscriberCan.update || subscriberCan.delete"
                            ref="subscriptionMultiselect"
                            :model-value="subscription.tags"
                            mode="tags"
                            :can-clear="false"
                            :options="subscription.tags"
                            :create-option="subscriberCan.update"
                            :searchable="true"
                            :show-options="false"
                            :allow-false="true"
                            @update:model-value="onUpdateSubscriptionTags" />
                        <div v-else>
                            <div
                                v-for="tag in subscription.tags"
                                :key="tag"
                                class="m-1 inline-flex items-center justify-center rounded bg-neutral-200 px-1 dark:bg-neutral-700">
                                {{ tag }}
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </Card>

        <SearchableListGroup
            size="md"
            singular="Suppression"
            plural="Suppressions"
            :only="only"
            :icons="{
                default: NoSymbolIcon
            }"
            :response="kills"
            :description="kill => format(new Date(kill.created_at), 'PPPp')"
            :title="model => model.reason"
            :sortable="false"
            :searchable="false">
            <template #tags="{ model }">
                <span class="badge bg-rose-500">
                    {{ model.type }}
                </span>
                <span
                    v-if="model.global"
                    class="badge bg-green-600">
                    global
                </span>
            </template>
            <template #list-actions="{ model }">
                <button
                    v-if="!model.global"
                    class="btn-primary btn group flex items-center capitalize"
                    @click="onDeleteKill(model)">
                    <TrashIcon class="size-6" /> Remove
                </button>
            </template>
        </SearchableListGroup>
        
        <SearchableListGroup
            size="md"
            :icons="{
                default: ClipboardDocumentListIcon
            }"
            header="Activity"
            plural="audits"
            :filters="false"
            :response="audits"
            :only="only">
            <template #icon="{ model }">
                <IconDescription
                    v-if="model.description?.icon"
                    :description="model.description.icon" />
            </template>
            <template #badges="{ model }">
                <BadgeDescription
                    v-if="model.description?.badge"
                    :description="model.description.badge" />
            </template>
            <template #title="{ model }">
                <Description
                    v-if="model.description"
                    :description="model.description" />
                <template v-else>
                    Audit description not available.
                </template>
            </template>
        </SearchableListGroup>
    </Show>
</template>