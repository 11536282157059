<script lang="ts" setup>
import Bee from '@mailupinc/bee-plugin';
import type { IBeeConfig, IEntityContentJson, IGetTokenPayload } from '@mailupinc/bee-plugin/dist/types/bee';
import { merge } from 'lodash-es';
import type { BaseModel, SubscriberList, User } from 'types';
import type { Ref } from 'vue';
import { computed, onMounted, ref, toRaw } from 'vue';

const props = withDefaults(defineProps<{
    modelValue?: Partial<IEntityContentJson>
    list: SubscriberList
    authUser: User
    config?: Partial<IBeeConfig>,
    model: BaseModel
}>(), {
    config: undefined,
    modelValue: () => ({})
});

const emit = defineEmits<{
    change: [template: IEntityContentJson],
    load: [template: IEntityContentJson],
    save: [template: IEntityContentJson, html: string],
    'update:modelValue': [newValue: IEntityContentJson]
}>();

const bee: Bee = new Bee();

const payload: IGetTokenPayload = {
    clientId: import.meta.env.VITE_BEE_PLUGIN_CLIENT_ID,
    clientSecret: import.meta.env.VITE_BEE_PLUGIN_CLIENT_SECRET
};

const template = ref<IEntityContentJson>();

const customFonts = computed(() => {
    if(!props.list || !props.list.options.custom_fonts) {
        return;
    }

    const nonNullableFonts = props.list.options.custom_fonts.filter(({ url, font_family }) => {
        return url && font_family;
    }) as {
        url: string,
        font_family: string
    }[];

    const googleFontWeights:Record<number,string> = {
        100: 'Thin',
        200: 'Extra-light',
        300: 'Light',
        400: 'Regular',
        500: 'Medium',
        600: 'Semi-bold',
        700: 'Bold',
        800: 'Extra-bold',
        900: 'Black'
    };
        
    return nonNullableFonts.map(font => {
        const params = (new URL(font.url)).searchParams;
        const family = params.get('family');
        const weight = Array.from(family?.match(/[100-900][0]+/g) ?? []);
         
        return {
            name: family?.split(':')[0] ?? font.font_family.split(',')[0].match(/(?<quote>'|")(\w+)\1/)?.[2],
            fontFamily: font.font_family,
            url: font.url,
            fontWeight: weight.map(value => parseInt(value))
                .reduce<Record<number,string>>((carry, value) => {
                    if(value in googleFontWeights) {
                        carry[value] = googleFontWeights[value];
                    }

                    return carry;
                }, {})
        };
    });
});

const beeConfig: Ref<IBeeConfig> = computed(() => merge({
    //uid allows us to segment images by list on the s3 bucket, original uid: 9FO4rOJlVo2H
    uid: `lid${props.list.id}`,
    container: 'bee-plugin-container',
    roleHash: props.authUser.super_admin ? 'administrator' : undefined,
    trackChanges: true,
    onLoad(value: IEntityContentJson) {
        emit('load', value);
    },
    onChange(value: string) {
        emit('update:modelValue', JSON.parse(value) as IEntityContentJson);
    },
    onSave: (value: string, html: string) => {
        template.value = JSON.parse(value) as IEntityContentJson;

        emit('save', template.value, html);
    },
    onError: (error: any) => {
        console.error(error);
    },
    onWarning: (error: any) => {
        console.warn(error);
    },
    editorFonts: {
        showDefaultFonts: true,
        customFonts: customFonts.value
    },
}, props.config));

async function start(template?: Partial<IEntityContentJson>) {
    await bee.start(toRaw(beeConfig.value), toRaw(template) as IEntityContentJson ?? {});
}

async function init() {
    await bee.getToken(payload.clientId, payload.clientSecret);
    
    start(props.modelValue);
};

onMounted(() => {
    init();
});

defineExpose({
    bee,
    start,
    template,
    toggleMergeTagsPreview: () => bee.toggleMergeTagsPreview()
}); 

//sm:scale-[.60] md:scale-[.80] lg:scale-[.95] xl:scale-100
</script>
<template>
    <div
        id="bee-plugin-container"
        class="h-[900px] w-full overflow-x-auto" />
</template>