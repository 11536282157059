<script lang="ts" setup>
import { Pulse } from '@vue-interface/activity-indicator';
import { SelectField } from '@vue-interface/select-field';
import type { MgDataVariable, SeedList, SubscriberList } from 'types';
import { computed, onMounted, ref, watchEffect } from 'vue';
import axios from '../../axios';

const props = defineProps<{
    id: string,
    name: string,
    item: MgDataVariable,
    index: number,
    errors: any,
    modelValue?: string,
    list: SubscriberList
}>();

const matchEmailLists = ref<SeedList[]>();
const activity = ref(false);

async function request() {
    activity.value = true;

    return await axios.get<SeedList[]>('elixr/match_email_lists', {
        params: {
            list_id: props.list.id
        }
    })
        .then(response => {
            matchEmailLists.value = response.data;

            return response.data;
        }).finally(() => {
            activity.value = false;
        });
}

const emit = defineEmits<{
    'update:modelValue': [value: string|undefined],
    'update:formattedValue': [value: number|string],
    'update:readableValue': [value: string]
}>();

const model = computed<string|undefined>({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const isDefault = computed(() => {
    return JSON.stringify(model.value) === JSON.stringify(props.item.defaultValue);
});

watchEffect(() => {
    if(isDefault.value) {
        emit('update:formattedValue', props.item.defaultValue);
    } else {
        emit('update:formattedValue', Number(model.value));
    }

    const value = matchEmailLists.value?.find(
        i => i.id === Number(model.value)
    );

    if(value) {
        emit('update:readableValue', value.name);
    }
});

onMounted(async () => {
    await request();
});
</script>

<template>
    <div class="flex items-center gap-2">
        <SelectField
            :id="id"
            v-model="model"
            :activity="activity"
            :indicator="Pulse"
            :name="`options.mg_data_variables.${index}.value`"
            class="w-full"
            :error="errors[name]">
            <option :value="item.defaultValue">
                {{ item.defaultValue }}
            </option>
            <option
                v-for="i in matchEmailLists"
                :key="i.id"
                :value="i.id">
                {{ i.name }}
            </option>
        </SelectField>
        <slot
            v-if="!isDefault"
            name="reset" />
    </div>
</template>