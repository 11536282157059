<script setup lang="ts">
import { ClipboardDocumentListIcon, DocumentDuplicateIcon, PencilSquareIcon, Square3Stack3DIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { Link, router } from '@inertiajs/vue3';
import type { Agency, Audit, LengthAwarePaginator, SavedRow, SubscriberList, Template } from 'types';
import axios from '../axios';
import ActionButton from '../components/ActionButton.vue';
import DetailsView, { Tags } from '../components/DetailsView.vue';
import HtmlPreview from '../components/HtmlPreview.vue';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import Show from '../components/Show.vue';
import BadgeDescription from '../components/audits/BadgeDescription.vue';
import Description from '../components/audits/Description.vue';
import IconDescription from '../components/audits/IconDescription.vue';
import { route } from '../composables/routes';

const props = defineProps<{
    audits?: LengthAwarePaginator<Audit>;
    agency: Agency;
    list: SubscriberList;
    template: Template;
    savedRow: SavedRow;
}>();

function onClickDelete() {
    if(confirm(`Are you sure you want to delete ${props.savedRow.name}?`)) {
        axios.delete(`saved-rows/${props.savedRow.id}`).then(() => {
            router.visit(route('templates.show', { template: props.template.id }));
        });
    }
}

function onClickDuplicate() {
    if(confirm(`Are you sure you want to duplicate ${props.savedRow.name}?`)) {
        router.post(route('templates.saved-rows.replicate', {
            agency: props.agency.slug,
            list: props.list.slug,
            template: props.template.id,
            saved_row: props.savedRow.id
        }));
    }
}

function modelableLabel(savedRow: SavedRow) {
    switch (savedRow.modelable_type) {
    case 'App\\Models\\Template':
        return 'Template';
    case 'App\\Models\\SubscriberList':
        return 'List';
    case 'App\\Models\\Agency':
        return 'Agency';
    }

    return 'Model';
}
</script>
<template>
    <Show
        :icon="Square3Stack3DIcon"
        :title="savedRow.name">
        <template #actions>
            <ActionButton>
                <Link
                    :href="route('templates.saved-rows.edit', {
                        agency: agency.slug,
                        list: list.slug,
                        template: template.id,
                        saved_row: savedRow.id
                    })"
                    class="group flex items-center">
                    <PencilSquareIcon
                        class="my-1 mr-3 h-5 w-5" /> Edit Saved Row
                </Link>
                <button
                    class="group flex items-center"
                    @click="onClickDuplicate">
                    <DocumentDuplicateIcon class="my-1 mr-3 h-5 w-5" /> Duplicate Saved Row
                </button>
                <hr>
                <button
                    class="group flex items-center"
                    @click="onClickDelete">
                    <TrashIcon class="my-1 mr-3 h-5 w-5" /> Delete Saved Row
                </button>
            </ActionButton>
        </template>

        <HtmlPreview :html="savedRow.html ?? savedRow.no_html_preview" />

        <DetailsView
            :data="savedRow"
            :fields="[
                { name: 'modelable.name', label: modelableLabel(savedRow) },
                { name: 'type', label: 'Type' },
                savedRow.type === 'feed_story' ? { name: 'feed_sources', label: 'Feed Sources', type: Tags } : undefined,
                savedRow.type === 'feed_story' ? { name: 'options.total_days_in_feed', label: 'Total Days in Feed' } : undefined,
                { name: 'updated_at', label: 'Updated', type: Date, format: 'PPPp' },
                { name: 'created_at', label: 'Created', type: Date, format: 'PPPp' },
            ]" />

        <SearchableListGroup
            header="Activity"
            plural="audits"
            size="md"
            :icons="{
                default: ClipboardDocumentListIcon
            }"
            :filters="false"
            :response="audits">
            <template #icon="{ model }">
                <IconDescription
                    v-if="model.description?.icon"
                    :description="model.description.icon" />
            </template>
            <template #badges="{ model }">
                <BadgeDescription
                    v-if="model.description?.badge"
                    :description="model.description.badge" />
            </template>
            <template #title="{ model }">
                <Description
                    v-if="model.description"
                    :description="model.description" />
                <template v-else>
                    Audit description not available.
                </template>
            </template>
        </SearchableListGroup>
    </Show>
</template>