import type { IEntityContentJson, IPluginModuleButton, IPluginModuleDivider, IPluginModuleHeading, IPluginModuleList, IPluginModuleParagraph } from '@mailupinc/bee-plugin/dist/types/bee';
import { ModuleTypes } from '@mailupinc/bee-plugin/dist/types/bee';
import { convert as htmlToText, type DomNode } from 'html-to-text';

export type ParserRule<T, M> = {
    type: string,
    handler: (module: M) => T
}

export type IPluginModuleText = {
    type: typeof ModuleTypes.TEXT;
    locked?: boolean;
    uuid: string;
    descriptor: {
        id?: string
        computedStyle: Partial<{
            hideContentOnMobile: boolean;
        }>
        style: Partial<{
            'padding-bottom': string;
            'padding-left': string;
            'padding-right': string;
            'padding-top': string;
        }>
        text: {
            computedStyle: Partial<{
                linkColor: string
            }>
            html: string
            style: Partial<{
                color: string
                fontFamily: string
                lineHeight: string
            }>
        };
    }
}

export function inlineText(text: string) {
    return htmlToText(text, {
        preserveNewlines: true,
        selectors: [{
            selector: 'span',
            format: 'inlineTag',
            options: {
                hideLinkHrefIfSameAsText: true
            }
        }]
    });
}

export function strlen(children: DomNode[], carry: number = 0): number {
    return children.reduce((carry, child) => {
        if(child.children?.length) {
            return strlen(child.children, carry);
        }

        return child.data?.length ?? 0;
    }, carry);
}

export function convert(html: string) {
    const document = new DOMParser().parseFromString(
        html?.replace(/<\/#(.+?)>/g, '&lt;/#$1&gt;') ?? '', 'text/html'
    );
    
    for(const elem of document.querySelectorAll<HTMLElement>('body > *')) {
        if(elem.style.display === 'none') {
            elem.remove();
        }
    }

    return htmlToText(document.documentElement.outerHTML, {
        preserveNewlines: true,
        whitespaceCharacters: '',
        formatters: {
            blocks(elem, walk, builder) {
                walk(elem.children, builder);
            },
            header(elem, walk, builder, formatOptions) {
                // builder.addLineBreak();
                builder.openBlock({ leadingLineBreaks: 1 });
                builder.addInline('# ');
                
                if(formatOptions.uppercase === true) {
                    builder.pushWordTransform(str => str.toUpperCase());
                    walk(elem.children, builder);
                    builder.popWordTransform();
                }
                else {
                    walk(elem.children, builder);
                }

                
                if(formatOptions.divider) {
                    builder.addLineBreak();
                    builder.addInline('-'.repeat(strlen(elem.children) + 2));
                }
                
                builder.closeBlock({ trailingLineBreaks: 1 });
            },
            mergeTag(elem, walk, builder) {
                builder.addInline(elem.attribs['data-bee-value']);
            },
            ol(elem, walk, builder, formatOptions) {
                builder.options.formatters.orderedList(elem, walk, builder, formatOptions);
            }
        },
        selectors: [{
            selector: 'h1',
            format: 'header',
            options: {
                divider: true,
                uppercase: false
            }
        },{
            selector: 'h2',
            format: 'header',
            options: {
                uppercase: true
            }
        },{
            selector: 'h3',
            format: 'header',
            options: {
                uppercase: false
            }
        },{
            selector: 'h4',
            format: 'header'
        },{
            selector: 'h5',
            format: 'header'
        },{
            selector: 'h6',
            format: 'header'
        }, {
            selector: '[data-bee-type="mergetag"]',
            format: 'mergeTag'
        }, {
            selector: 'li',
            format: 'blocks'
        },{
            selector: 'hr',
            options: {
                length: 40
            }
        },{
            selector: 'p',
            options: {
                leadingLineBreaks: 1,
                trailingLineBreaks: 1
            }
        },{
            selector: 'ol',
            options: {
                leadingLineBreaks: 0,
                trailingLineBreaks: 0
            }
        },{
            selector: 'ul',
            options: {
                leadingLineBreaks: 0,
                trailingLineBreaks: 0
            }
        }]
    }).split('\n').map(line => line.trim()).join('\n').replace(/\n\n+/g, '\n\n').trim();
}

export function useParser<T>(rules: ParserRule<T, any>[]) {
    function matchingRules(type: string) {
        const matches = [];

        for(const rule of rules) {
            if(rule.type === type) {
                matches.push(rule);
            }
        }

        return matches;
    }

    function parse(json: IEntityContentJson) {
        const response: (T|string)[] = [];

        for(const row of json.page.rows) {
            if(row.container.displayCondition?.before) {
                response.push(row.container.displayCondition.before);
            }

            for(const column of row.columns) {
                for(const module of column.modules) {
                    for(const rule of matchingRules(module.type)) {
                        response.push(rule.handler(module));
                    }
                }
            }

            if(row.container.displayCondition?.after) {
                response.push(row.container.displayCondition.after);
            }
        }

        return response.filter(value => value).join('\n\n').replace(/\n\n\n+/g, '\n\n');
    }

    return { parse };
}

const { parse } = useParser([{
    type: 'mailup-bee-newsletter-modules-text',
    handler(module: IPluginModuleText) {
        return convert(module.descriptor.text.html);
    }
}, {
    type: 'mailup-bee-newsletter-modules-paragraph',
    handler(module: IPluginModuleParagraph) {
        return convert(module.descriptor.paragraph.html);
    }
}, {
    type: 'mailup-bee-newsletter-modules-heading',
    handler(module: IPluginModuleHeading) {
        return convert(`<${module.descriptor.heading.title}>${module.descriptor.heading.text}</${module.descriptor.heading.title}>`);
    }
}, {
    type: 'mailup-bee-newsletter-modules-button',
    handler(module: IPluginModuleButton) {
        if(module.descriptor.button.href) {
            return `[${convert(module.descriptor.button.label)}](${module.descriptor.button.href})`;
        }

        return convert(module.descriptor.button.label);
    }
}, {
    type: 'mailup-bee-newsletter-modules-image',
    handler(module: any) {
        if(module.descriptor.image.href) {
            return `[Image](${module.descriptor.image.href})`;
        }
        
        return '[Image]';
    }
}, {
    type: 'mailup-bee-newsletter-modules-list',
    handler(module: IPluginModuleList) {
        return convert(module.descriptor.list.html);//.replace(/\#\[newline\]\#(\n+)?/gm, '\n');
    }
}, {
    type: 'mailup-bee-newsletter-modules-divider',
    handler(module: IPluginModuleDivider) {
        return convert('<hr>');
    }
}]);

export { parse };
