<script lang="ts" setup>
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import type { DatePickerMarker } from '@vuepic/vue-datepicker';
import VueDatePicker from '@vuepic/vue-datepicker';
import { useColorMode } from '@vueuse/core';
import axios from 'axios';
import { addMinutes, addSeconds, format, isBefore } from 'date-fns';
import colors from 'tailwindcss/colors';
import type { Agency, Send, SubscriberList } from 'types';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { route } from '../composables/routes';
import useResourceForm from '../composables/useResourceForm';

const props = defineProps<{
    agency: Agency
    list: SubscriberList
    send: Send
    url: string;
    description: string;
}>();

type Schedule = {
    scheduledSends: Send[],
    scheduledMarkers: DatePickerMarker[]
};

const scheduledSends = ref<Send[]>([]);
const scheduledMarkers = ref<DatePickerMarker[]>([]);

const mode = useColorMode();

const defaultDateTime = addMinutes(new Date(), 10);

const { form, errors, patch } = useResourceForm({
    data: {
        send_at: defaultDateTime.toISOString(),
    },
    url: props.url
});

let fiveMinutesFromNow = ref(addMinutes(new Date(), 5));

const interval = setInterval(() => {
    fiveMinutesFromNow.value = addSeconds(new Date(fiveMinutesFromNow.value), 1);
}, 1000);

const isTimeValid = computed(() => {
    const now = new Date(form.send_at);

    for(const send of scheduledSends.value) {
        if(!send.scheduled_block?.start || !send.scheduled_block?.end) {
            continue;
        }
        const start = new Date(send.scheduled_block.start);
        const end = new Date(send.scheduled_block.end);
        if(start <= now && end >= now) {
            return false;
        }
    }

    if(isBefore(new Date(now), new Date(fiveMinutesFromNow.value))) {
        return false;
    }

    return true;
});

function request() {
    axios.get<Schedule>(route('sends.schedule-json', {
        agency: props.agency.slug,
        list: props.list.slug,
        send: props.send.id
    }))
        .then(({ data }) => {
            scheduledSends.value = data.scheduledSends;

            scheduledMarkers.value = data.scheduledMarkers?.map(marker => {
                return {
                    ...marker,
                    color: colors.rose[500],
                    tooltip: marker.tooltip?.map(tooltip => {
                        return {
                            ...tooltip,
                            color: colors.rose[500]
                        };
                    })
                };
            });
        });
}

onMounted(() => {
    request();
});

onUnmounted(() => {
    clearInterval(interval);
});

defineExpose({
    patch
});
</script>

<template>
    <div
        class="flex flex-col gap-4">
        <h1>
            {{ description }}
        </h1>

        <VueDatePicker
            v-model="form.send_at"
            utc
            inline
            :markers="scheduledMarkers"
            :is-24="false"
            auto-apply
            :month-change-on-scroll="false"
            :dark="mode === 'dark'" />

        <div
            v-if="errors"
            class="text-red-500">
            {{ errors.send_at.join('\n') }}
        </div>

        <div
            class="p-2 rounded flex gap-x-2 justify-start items-center text-lg text-white"
            :class="{
                'bg-emerald-500': isTimeValid,
                'bg-red-500': !isTimeValid
            }">
            <template v-if="isTimeValid">
                <CheckCircleIcon
                    class="h-8 w-8 shrink-0" />
                {{ format(new Date(form.send_at), 'PPPp') }}
            </template>
            <template v-else>
                <ExclamationCircleIcon
                    class="w-8 h-8 shrink-0" />
                {{ format(new Date(form.send_at), 'PPPp') }}
            </template>
        </div>
    </div>
</template>

<style>
.dp__flex_display {
    display: block !important;
}

.dp__menu {
    border: none !important;
}

.dp__menu {
    border: none;
}

.dp__menu {
    border: none;
}
</style>