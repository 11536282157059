<script lang="ts" setup>
import { SelectField } from '@vue-interface/select-field';

const props = defineProps<{
    id: string,
    name: string,
    errors?: any,
    error?: string
    states: any
}>();

</script>

<template>
    <SelectField
        :id="id"
        :error="error"
        :errors="errors"
        :name="props.name">
        <option
            v-for="state in Object.keys(states)"
            :key="state"
            :value="state">
            {{ states[state] }}
        </option>
    </SelectField>
</template>