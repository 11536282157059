<script setup lang="ts" generic="T extends Object">
import { Link } from '@inertiajs/vue3';
import { format } from 'date-fns';
import { get } from 'lodash-es';
import DetailsBoolean from './DetailsBoolean.vue';
import { ExternalLink, Image, Relationship, Tags, type BooleanField, type CustomField, type DateField, type Field, type ImageField, type RelationshipField } from './DetailsView.vue';

const props = defineProps<{
    field: Field,
    data: T
}>();

const numberFormatter = new Intl.NumberFormat('en-US');

const value = get(props.data, props.field.name) ?? props.field.defaultValue;
</script>
<template>
    <template v-if="field.type === String || !field.type">
        <div class="break-words">
            {{ value ?? '&dash;' }}
        </div>
    </template>
    <template v-else-if="field.type === Number">
        <div class="break-words">
            {{ numberFormatter.format(value) ?? '&dash;' }}
        </div>
    </template>
    <template v-else-if="field.type === Array">
        <template
            v-for="item in value"
            :key="item">
            <ul>
                <li>{{ item }}</li>
            </ul>
        </template>
    </template>
    <template v-else-if="field.type === Boolean">
        <DetailsBoolean
            :value="value"
            :reverse="(field as BooleanField).reverse" />
    </template>
    <template v-else-if="field.type === Date && props.data">
        <template v-if="value">
            {{ format(new Date(value), (field as DateField).format) }}
        </template>
        <template v-else>
            &dash;
        </template>
    </template>
    <template v-else-if="field.type === Link">
        <div
            v-if="value"
            class="truncate">
            <Link
                :href="value"
                class="text-rose-500 hover:underline">
                {{ value }}
            </Link>
        </div>
        <template v-else>
            &dash;
        </template>
    </template>
    <template v-else-if="field.type === ExternalLink">
        <a
            v-if="value"
            :href="value"
            :target="field.target"
            class="text-rose-500 hover:underline">
            {{ value }}
        </a>
        <template v-else>
            &dash;
        </template>
    </template>
    <template v-else-if="field.type === Image">
        <img
            v-if="value?.url"
            :src="value.url"
            :class="(field as ImageField).class">
        <template v-else>
            &dash;
        </template>
    </template>
    <template v-else-if="field.type === Relationship">
        <Link
            v-if="value"
            :href="(field as RelationshipField).href"
            class="text-rose-500 hover:underline break-words">
            <template v-if="typeof (field as RelationshipField).title === 'function'">
                {{
                    // @ts-ignore
                    (field as RelationshipField).title(props.data)
                }}
            </template>
            <template v-else>
                {{ (field as RelationshipField).title ?? value }}
            </template>
        </Link>
        <template v-else>
            &dash;
        </template>
    </template>
    <template v-else-if="typeof field.type === 'object'">
        <Component
            :is="field.type"
            v-bind="(field as CustomField).props(value)" />    
    </template>
    <template v-else-if="field.type === Tags">
        <div
            v-for="tag in value"
            :key="tag"
            class="m-1 inline-flex items-center justify-center rounded bg-neutral-200 px-1 dark:bg-neutral-700">
            {{ tag.name }}
        </div>
    </template>
</template>