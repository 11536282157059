export function focus(id?: string|null) {
    if(id) {
        // console.log(document.getElementById(id));
        document.getElementById(id)?.focus();
    }
}

export function get(key: string, href?: string): string | null {
    const params = new URL(href ?? window.location.href).searchParams;

    if(!params.has(key)) {
        return null;
    }

    return params.get(key);
}