<script lang="ts" setup>
import FlatfileImporter from '@flatfile/adapter';
import type { RawRecordObject } from '@flatfile/adapter/build/main/interfaces';
import type { Results } from '@flatfile/adapter/build/main/results';
import { ArrowPathIcon, ArrowUpTrayIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import { ActivityIndicator, Pulse } from '@vue-interface/activity-indicator';
import { ProgressBar } from '@vue-interface/progress-bar';
import { useColorMode } from '@vueuse/core';
import { chunk } from 'lodash-es';
import type { SubscriberList, User } from 'types';
import { computed, ref } from 'vue';
import axios from '../axios';
import Banner from './Banner.vue';
import MultiselectField from './MultiselectField.vue';

const props = defineProps<{
    authUser: User
    list: SubscriberList
}>();

const mode = useColorMode();
const records = ref<RawRecordObject[]>([]);
const filename = ref();
const uploading = ref(false);
const currentRecord = ref(0);
const activity = ref(false);
const error = ref();
const tags = ref([]);

let importer: FlatfileImporter;
let chunkSize = 2000;

function init() {
    importer = new FlatfileImporter(import.meta.env.VITE_FLATFILE_LICENSE_KEY, {
        type: 'record',
        fields: [
            {
                'label': 'Email',
                'key': 'email',
                'validators': [
                    {
                        'validate': 'required'
                    }
                ]
            },
            {
                'label': 'First',
                'key': 'first'
            },
            {
                'label': 'Last',
                'key': 'last'
            },
            {
                'label': 'Street',
                'key': 'street'
            },
            {
                'label': 'City',
                'key': 'city'
            },
            {
                'label': 'State',
                'key': 'state'
            },
            {
                'label': 'Zip',
                'key': 'zip'
            },
            {
                'label': 'Phone',
                'key': 'phone'
            },
            {
                'label': 'Tags',
                'key': 'tags'
            },
        ],
        maxRecords: 15000,
        theme: setTheme(),
        devMode: import.meta.env.DEV,
    },{
        userId: String(props.authUser.id),
        name: props.authUser.name,
        email: props.authUser.email,
    });

    importer
        .requestDataFromUser()
        .then(results => {
            onData(importer, results);
        }).catch(e => {
            throw e; 
        });
}

function setTheme()  {
    const darkTheme = {
        global: {
            backgroundColor: '#212327',
            textColor: '#c2c3c3',
            primaryTextColor: '#c2c3c3',
            secondaryTextColor: '#c2c3c3',
            successColor: '#22c55e',
            warningColor: '#ef4444'
        },
        buttons: {
            primary: {
                backgroundColor: '#fe4265',
                color: '#fff',
                border: '0px',
            },
        }
    };
    const lightTheme = {
        buttons: {
            primary: {
                backgroundColor: '#fe4265',
                color: '#fff',
                border: '0px',
            },
        }
    };

    return mode.value === 'dark' ? darkTheme : lightTheme;
}

async function onData(importer: FlatfileImporter, results: Results) {
    if(results.validData === null) {
        importer.displayError(
            'The data could not be imported! Please try again later.'
        );
    }
    else {
        records.value = results.validData;
        filename.value = results.fileName;
        importer.close();
    }
}

async function handleSubmit() {
    uploading.value = true;

    const { data } = await axios.post('elixr/uploads/begin', {
        catalog: props.list.elixr_catalog_id,
        tags: tags.value.join(','),
        filename: filename.value,
        totalRecords: records.value.length,
        initiated: 'alchemy/flatfile'
    }).catch(e => {
        error.value = e.response.data.message;
        uploading.value = false;
        throw e;
    });

    if(data && !data.upload) {
        return;
    }

    const chunks = chunk(records.value, chunkSize);

    for(const chunk of chunks) {
        await axios.post(`elixr/uploads/${data.upload.id}/chunk`, {
            upload: data.upload.id,
            data: chunk,
        }).then(() => {
            currentRecord.value += chunk.length;
        }).catch(e => {
            error.value = e.response.data.message;
            uploading.value = false;
            throw e;
        });
    }

    await axios.post(`elixr/uploads/${data.upload.id}/complete`)
        .catch(e => {
            error.value = e.response.data.message;
            uploading.value = false;
            throw e;
        }).finally(() => {
            resetUploadProcess();
        });
}

function resetUploadProcess() {
    uploading.value = false;
    currentRecord.value = 0;
    records.value = [];
    tags.value = [];
    error.value = null;
}

const percent = computed(() => records.value.length === 0 ? 0 : currentRecord.value * 100 / records.value.length);


router.on('navigate', () => {
    importer?.close();
});
</script>

<template>
    <Banner
        v-if="error"
        class="bg-red-100 text-red-500 flex justify-center">
        <ExclamationTriangleIcon
            class="w-8 h-8" />
        <div>
            {{ error }}
        </div>
    </Banner>
    <div
        v-if="uploading"
        class="rounded bg-amber-50 p-6 dark:bg-neutral-800">
        <h2 class="pb-8 font-bold">
            Uploading...
        </h2>
                    
        <ProgressBar
            class="mx-auto mb-2 w-3/4"
            :value="percent"
            height="15px" />
        <div class="flex justify-center">
            <label>{{ `${Math.round(percent)}%` }}</label>
        </div>
    </div>
    <div
        v-else-if="activity"
        class="rounded bg-white p-6 shadow dark:bg-neutral-800">
        <div class="flex items-center justify-center">
            <ActivityIndicator
                label="Loading Flatfile Uploader..."
                :type="Pulse" />
        </div>
    </div>
    <div
        v-else
        class="space-y-4 rounded bg-white p-6 text-lg shadow dark:bg-neutral-800">
        <div
            v-if="records.length > 1"
            class="flex items-center">
            {{ records.length }} records ready to be uploaded.
        </div>
        <div
            v-else-if="records.length === 1"
            class="flex items-center">
            {{ records.length }} record ready to be uploaded.
        </div>
        <div
            v-else
            class="flex items-center">
            Click the Open Flatfile button to upload your file.
        </div>

        <button
            class="btn-primary btn flex"
            @click="init()">
            <ArrowPathIcon class="mr-2 h-6 w-6" /> Open Flatfile
        </button>

        <h3>Tags</h3>

        <MultiselectField
            v-model="tags"
            mode="tags"
            :create-option="true"
            :searchable="true"
            :options="[
                'Donor',
                'Survey',
                'Petition',
                'Recurring',
                'DM',
                'TM'
            ]"
            :allow-false="true" />
                
        <h3>Upload</h3>

        <button
            :disabled="records.length === 0"
            class="btn-secondary btn flex"
            @click="handleSubmit">
            <ArrowUpTrayIcon class="mr-2 h-6 w-6" /> Upload
        </button>
    </div>
</template>