<script lang="ts" setup>
import { Link, router } from '@inertiajs/vue3';
import { InputField } from '@vue-interface/input-field';
import GuestLayout from '../../Layouts/GuestLayout.vue';
import { Card } from '@vue-interface/card';
import FormLayout from '../../components/FormLayout.vue';
import { route } from '../../composables/routes';
import { useForm } from 'laravel-precognition-vue-inertia';

const url = route('password.email');
const method = 'post';

const form = useForm(method, url, {
    email: undefined,
    password: undefined,
    remember: false
});

function onSubmit() {
    form.submit(method, url, {
        onSuccess: () => {
            router.visit(route('password.forgot.confirmation'));
        }
    });
}

defineOptions({
    layout: GuestLayout
});
</script>

<template>
    <Card class="mx-auto w-[22rem]">
        <h1 class="mb-3 text-center font-bold">
            Send Password Reset Link
        </h1>

        <FormLayout
            :submit="onSubmit"
            :form="form"
            button-label="Send"
            :button-block="true">
            <InputField
                v-model="form.email"
                name="email"
                label="Email"
                placeholder="you@example.com"
                :error="form.errors.email"
                class="mb-3" />
        </FormLayout>
    </Card>
    <div class="flex justify-center gap-x-2">
        <Link
            class="text-rose-500 hover:underline"
            :href="route('login')">
            Back to Login
        </Link>
    </div>
</template>