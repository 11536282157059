<script lang="ts" setup>
import { ArrowsRightLeftIcon, DocumentDuplicateIcon, RectangleGroupIcon, StarIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import type { Agency, Can, SubscriberList, Template, User } from 'types';
import { useToast } from 'vue-toastification';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import { route } from '../composables/routes';
import { echo } from '../plugins/Echo';

const props = defineProps<{
    agency: Agency;
    authUser: User;
    can: Can;
    list: SubscriberList;
    templates?: any
}>();

const toast = useToast();
const only = ['templates', 'list'];

function replicate(template: Template) {
    if(confirm(`Are you sure you want to duplicate ${template.name}?`)) {
        router.post(route('templates.replicate', {
            agency: props.agency.slug,
            list: props.list.slug,
            template: template.id
        }));
    }
}

function setAsDefaultTemplate(template: Template) {
    router.patch(route('agencies.lists.update', { agency: props.agency.id, list: props.list.id }), {
        template_id: template.id
    }, {
        only,
        onSuccess: () => {
            toast.success(`${template.name} is now the default template.`);
        },
        preserveScroll: true
    });
}
</script>

<template>        
    <SearchableListGroup
        header="Templates"
        singular="template"
        plural="templates"
        :only="only"
        :response="templates"
        :icons="{
            default: RectangleGroupIcon
        }"
        :badges="(template: Template) => list?.template_id === template.id ? ['Default'] : []"
        :routes="{
            index: route('templates.index', {
                agency: agency.slug,
                list: list.slug
            }),
            show: template => route('templates.show', { 
                agency: agency.slug,
                list: list.slug,
                template: template.id
            }),
            edit: template => route('templates.edit', { 
                agency: agency.slug,
                list: list.slug,
                template: template.id
            }),
            create: route('templates.create', {
                agency: agency.slug,
                list: list.slug,
            }),
            delete: template => route('templates.destroy', { 
                agency: agency.slug,
                list: list.slug,
                template: template.id
            })
        }"
        :can="{
            view: template => template.can?.view,
            update: template => template.can?.update,
            create: can.create,
            delete: template => list.template_id !== template.id && template.can?.delete,
        }"
        :echo="{
            listen: ({ reload }) => {
                echo.private(`App.Models.SubscriberList.${list.id}`)
                    .listen('.TemplateCreated', reload)
                    .listen('.TemplateUpdated', reload)
                    .listen('.TemplateDeleted', reload)
            },
            stopListening: () => {
                echo.private(`App.Models.SubscriberList.${list.id}`)
                    .stopListening('.TemplateCreated')
                    .stopListening('.TemplateUpdated')
                    .stopListening('.TemplateDeleted')
            }
        }">
        <template #list-actions-links="{ model }">
            <button
                class="group flex items-center"
                @click="replicate(model)">
                <DocumentDuplicateIcon class="my-1 mr-3 h-5 w-5" /> Duplicate Template
            </button>
            <template v-if="list?.template_id !== model.id">
                <!-- <button
                    v-if="authUser.super_admin"
                    id="transfer"
                    class="group flex items-center">
                    <ArrowsRightLeftIcon class="my-1 mr-3 h-5 w-5" /> Transfer
                </button> -->
                <button
                    v-if="list.can?.update"
                    class="group flex items-center"
                    @click="setAsDefaultTemplate(model)">
                    <StarIcon class="my-1 mr-3 h-5 w-5" /> Set as List Default
                </button>
            </template>
        </template>
    </SearchableListGroup>
</template>