<script lang="ts" setup>
import { Card } from '@vue-interface/card';
import { InputField } from '@vue-interface/input-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import { vMaska } from 'maska';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';
import MultiselectField from './MultiselectField.vue';
import StateField from './StateField.vue';

const props = defineProps<{
    subscriber?: any,
    url: string,
    states: any
}>();

const method = props.subscriber?.id ? 'put' : 'post';

const form = useForm(method, props.url, Object.assign({
    first: undefined,
    last: undefined,
    email: undefined,
    zip: undefined,
    street: undefined,
    city: undefined,
    state: undefined,
    phone: undefined,
    tags: []
}, props.subscriber));

function onSubmit() {
    form.submit();
}
</script>

<template>
    <FormLayout
        :submit="onSubmit"
        :button-label="!subscriber?.id ? 'Submit' : 'Save'"
        :activity="form.processing"
        :disabled="form.processing">
        <Card>
            <FormField
                label="Email"
                class="flex items-center">
                <InputField
                    id="email"
                    v-model="form.email"
                    name="email"
                    :disabled="!!subscriber?.id"
                    :error="form.errors.email" />
            </FormField>
            <FormField
                label="First Name"
                class="flex items-center">
                <InputField
                    id="first"
                    v-model="form.first"
                    type="text"
                    name="first"
                    :error="form.errors.first" />
            </FormField>
            <FormField
                label="Last Name"
                class="flex items-center">
                <InputField
                    id="last"
                    v-model="form.last"
                    type="text"
                    name="last"
                    :error="form.errors.last" />
            </FormField>
            <FormField
                label="Street"
                class="flex items-center">
                <InputField
                    id="street"
                    v-model="form.street"
                    type="text"
                    name="street"
                    :error="form.errors.street" />
            </FormField>
            <FormField
                label="City"
                class="flex items-center">
                <InputField
                    id="city"
                    v-model="form.city"
                    type="text"
                    name="city"
                    :error="form.errors.city" />
            </FormField>
            <FormField
                label="State"
                class="flex items-center">
                <StateField
                    id="state"
                    v-model="form.state"
                    :states="states"
                    name="state"
                    :error="form.errors.state" />
            </FormField>
            <FormField
                label="Zip"
                class="flex items-center">
                <InputField
                    id="zip"
                    v-model="form.zip"
                    v-maska
                    data-maska="['#####', '#####-####']"
                    placeholder="12345-6789"
                    type="text"
                    name="zip"
                    :error="form.errors.zip" />
            </FormField>
            <FormField
                label="Phone"
                class="flex items-center">
                <InputField
                    id="phone"
                    v-model="form.phone"
                    v-maska
                    data-maska="(###) ###-####"
                    placeholder="(000) 000-0000"
                    type="text"
                    name="phone"
                    :error="form.errors.phone" />
            </FormField>
            <FormField
                label="Tags"
                description="Type the tag and hit the enter key to add the tag."
                class="flex items-center">
                <MultiselectField
                    id="tags"
                    v-model="form.tags"
                    mode="tags"
                    :can-clear="false"
                    :options="form.tags"
                    :create-option="true"
                    :searchable="true"
                    :show-options="false"
                    :allow-false="true" />
            </FormField>
        </Card>
    </FormLayout>
</template>