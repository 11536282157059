
export type RawRoute<T = string> = {
    'domain': null | string,
    'method': T,
    'uri': string,
    'name': null | string,
    'action': string,
    'middleware': string[]
}

export type Param = {
    name: string
    required: boolean
}

export type Route = RawRoute<string[]> & {
    params: Param[]
}

export type Routes = Route[];

let routes: Route[] = [];

const pattern = /\{(\w+)(\?)?\}/;

export function useRoutes(config: RawRoute[]) {
    routes = config.map(raw => {
        return ({
            ...raw, ...{
                method: raw.method.split('|'),
                params: (raw.uri.match(new RegExp(pattern, 'g')) || []).map(match => {
                    const matched = match.match(pattern) as RegExpMatchArray;

                    return {
                        name: matched[1],
                        required: !matched[2],
                    };
                }),
            }
        });
    });

    return route;
}

export function route(name: string | null, params: Record<string, any> = {}): string {
    const match = routes.find(route => route.name === name);

    if(!match) {
        console.warn(`Route ${name} not found.`);

        return '';
    }

    for(const param of match.params) {
        if(param.required && !params[param.name]) {
            console.warn(`${name} is missing the required parameter "${param.name}".`);

            return '';
        }
    }

    const routeParams = match.params.map(param => param.name);

    const url = new URL(match.uri, window.location.origin);

    for(const [key, value] of Object.entries(params)) {
        if(!routeParams.includes(key)) {
            url.searchParams.set(key, value);
        }
    }

    return decodeURI(url.href).replace(new RegExp(pattern, 'g'), (_, name) => {
        return params[name];
    });
}