<script lang="ts" setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { Link } from '@inertiajs/vue3';
import { computed } from 'vue';
import { route } from '../composables/routes';

const props = defineProps<{
    status: number,
}>();

const title = computed(() => {
    return {
        503: '503 Service Unavailable',
        500: '500 Server Error',
        404: '404 Page Not Found',
        403: '403 Unauthorized',
    }[props.status];
});
</script>

<template>
    <div class="flex flex-col items-center gap-4 font-bold dark:text-neutral-200">
        <ExclamationTriangleIcon class="h-16 w-16 text-yellow-500" />
        <a class="text-3xl font-semibold">
            {{ title }}
        </a>
        <Link
            v-if="props.status === 404 || props.status === 403"
            :href="route('home')"
            class="text-lg text-rose-500">
            &larr; Back to Home
        </Link>
    </div>
</template>