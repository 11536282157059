<script lang="ts" setup>
import { ArrowUturnLeftIcon, CodeBracketIcon, CursorArrowRaysIcon, ExclamationTriangleIcon, VariableIcon } from '@heroicons/vue/24/outline';
import { Link, router } from '@inertiajs/vue3';
import type { IEntityContentJson } from '@mailupinc/bee-plugin/dist/types/bee';
import { ActivityIndicator, Pulse } from '@vue-interface/activity-indicator';
import { Card } from '@vue-interface/card';
import { CheckboxField } from '@vue-interface/checkbox-field';
import { InputField } from '@vue-interface/input-field';
import { LightSwitchField } from '@vue-interface/light-switch-field';
import { Confirm } from '@vue-interface/modal';
import { TextareaField } from '@vue-interface/textarea-field';
import { useColorMode } from '@vueuse/core';
import { DecodeHrefAmpersands, ManipulateDom, ReplaceNonAsciiCharsWithEntities, decodeFreemarkerTags, encodeFreemarkerTags, run } from 'capsule-capsulate';
import { Editor } from 'capsule-editor';
import { defaultConfig, lint, type Hint } from 'capsule-lint';
import { basicDark } from 'cm6-theme-basic-dark';
import { basicLight } from 'cm6-theme-basic-light';
import { html_beautify } from 'js-beautify';
import { useForm } from 'laravel-precognition-vue-inertia';
import { debounce } from 'lodash-es';
import type { Agency, Can, MgDataVariable, Send, SubscriberList, Template, User } from 'types';
import { computed, nextTick, onMounted, ref, type Component } from 'vue';
import axios from '../axios';
import Banner from '../components/Banner.vue';
import { convert, parse } from '../composables/beeParser';
import { route } from '../composables/routes';
import { focus, get } from '../utils/utils';
import AutoSave from './AutoSave.vue';
import Autocomplete from './Autocomplete.vue';
import BeeEditor from './BeeEditor.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';
import BooleanField from './fieldtypes/BooleanField.vue';
import DateField from './fieldtypes/DateField.vue';
import DateTimeField from './fieldtypes/DateTimeField.vue';
import MailingIdField from './fieldtypes/MailingIdField.vue';
import MatchEmailListField from './fieldtypes/MatchEmailListField.vue';
import MultiMailingIdField from './fieldtypes/MultiMailingIdField.vue';
import MultiStateField from './fieldtypes/MultiStateField.vue';
import NumberField from './fieldtypes/NumberField.vue';
import SeedListField from './fieldtypes/SeedListField.vue';
import SelectField from './fieldtypes/SelectField.vue';
import StateField from './fieldtypes/StateField.vue';
import SuppressionListField from './fieldtypes/SuppressionListField.vue';
import TextField from './fieldtypes/TextField.vue';

const mode = useColorMode();

const theme = mode.value === 'dark'
    ? basicDark
    : basicLight;

const debounced = debounce(async (fn: Function, ...args: any[]) => {
    return fn(...args);
}, 500);

const props = defineProps<{
    agency: Agency;
    authUser: User;
    list: SubscriberList;
    send?: Send;
    template?: Template;
    templateCan: Can;
    templates?: Template[];
    url: string;
}>();

const method = props.send?.id ? 'put' : 'post';
const currentTemplate = ref<Template | undefined>(props.template);
const canSubmit = ref(false);
const bee = ref();
const activity = ref(false);
const lintErrors = ref<Hint[]>(lint(props.send?.html ?? '', {
    ...defaultConfig, ...{
        'valid-path-format': false
    }
}));

const fieldTypes = computed<Record<string, Component>>(() => ({
    TextField,
    NumberField,
    SelectField,
    StateField,
    MultiStateField,
    BooleanField,
    MailingIdField,
    MultiMailingIdField,
    DateField,
    DateTimeField,
    SeedListField,
    SuppressionListField,
    MatchEmailListField
}));

const form = useForm(method, props.url, {
    id: props.send?.id,
    name: props.send?.name,
    type: props.send?.type ?? 'bee',
    template_id: props.send?.template_id ?? currentTemplate.value?.id,
    template_json: props.send?.template_json ?? currentTemplate.value?.template_json,
    html: props.send?.html,
    text: props.send?.text,
    subject: props.send?.subject,
    options: Object.assign({}, props.send?.options, {
        preheader: props.send?.options.preheader ?? '',
        mg_from_name: props.send?.options.mg_from_name ?? props.list.options.mg_from_name,
        mg_reply_to_address: props.send?.options.mg_reply_to_address ?? props.list.options.mg_reply_to_address,
        mg_data_variables: props.send?.options.mg_data_variables ?? [],
        auto_generate_text: props.send?.options.auto_generate_text ?? true
    }),
});

const textEditor = ref<typeof Editor>();
const selectedVariables = ref(form.options.mg_data_variables?.slice() ?? []);

function onSelectTemplate(e: Event, template: Template) {
    if(form.template_id === template.id) {
        return;
    }
    if(form.template_id && form.template_id !== template.id && !confirm('Are you sure you want to change the template? This will remove the current HTML and Text, and use HTML and Text from the selected template.')) {
        e.preventDefault();

        return;
    }
    form.template_id = template.id;
    form.template_json = template.template_json;
    form.html = template.html;
    form.text = undefined;

    currentTemplate.value = template;

    if(form.id) {
        canSubmit.value = false;
        nextTick(() => {
            bee.value.start(template.template_json);
        });
    }
}

function onLoadBee() {
    canSubmit.value = true;
}

function onChangeBee(templateJson: IEntityContentJson) {
    form.template_json = templateJson;

    if(form.options.auto_generate_text) {
        form.text = parse(templateJson);
    }

    debounced(() => {
        props.authUser.options.auto_save && router.patch(props.url, {
            template_json: templateJson as any,
        }, {
            preserveScroll: true,
            only: ['templates', 'send']
        });
    });
}

function onChangeHtml() {
    if(form.options.auto_generate_text) {
        form.text = convert(form.html);
    }

    debounced(() => saveHtml());
}

function saveHtml() {
    props.authUser.options.auto_save && router.patch(props.url, {
        html: form.html,
    }, {
        preserveScroll: true,
        only: ['send']
    });
}

function onChangeType() {
    if(form.type === 'html') {
        form.template_id = null;
    }
    else {
        form.template_id = currentTemplate.value?.id;
    }
}

async function onSubmit() {
    form.processing = true;

    if(props.send?.id && props.send.type === 'bee') {
        bee.value?.bee.save();
    } else {
        form.html = await replaceNonAsciiCharsWithEntities(form.html);

        form.submit(method, props.url, {
            onError: function (e: Error) {
                form.processing = false;
                
                const field = Object.keys(e)?.[0];
                if(field) {
                    focus(field);
                }
            },
            only: ['send', 'errors', 'templates', 'template', 'flash']
        });
    }
}

// async function updatePlainTextFromApi() {
//     return axios.post(route('sends.plain-text', {
//         agency: props.agency?.slug,
//         list: props.list?.slug,
//         send: props.send?.id
//     })).then((response) => {
//         return form.text = response.data;
//     });
// }

async function replaceNonAsciiCharsWithEntities(src: string) {
    return run(src, [
        new ManipulateDom([
            new ReplaceNonAsciiCharsWithEntities
        ]),
        new DecodeHrefAmpersands()
    ]).catch(e => {
        axios.patch(`${props.agency.slug}/${props.list.slug}/sends/${props.send?.id}/lint-error`, {
            message: e.message
        }, {
            baseURL: '/'
        });

        return src;
    });
}

async function onSaveBee(templateJson: IEntityContentJson, html: string) {
    form.template_json = templateJson;
    form.html = await replaceNonAsciiCharsWithEntities(html);

    await form.submit(method, props.url, {
        onError(e: Error) {
            form.processing = false;

            const field = Object.keys(e)?.[0];

            if(field) {
                focus(field);
            }
        },
        only: ['send', 'errors', 'templates', 'template', 'flash']
    });
}

function resetTextFromHtml() {
    if(confirm('Are you sure you want to reset the Text from the Editor?')) {
        form.options.auto_generate_text = true;
        form.text = convert(form.html);
    }
}

const beeConfig = computed(() => {
    let config = {
        mergeTags: props.list.options.smart_merge_tags
    };

    if(currentTemplate.value?.saved_rows?.length) {
        config = Object.assign(
            config,
            {
                rowsConfiguration: {
                    emptyRows: false,
                    defaultRows: false,
                    externalContentURLs: currentTemplate.value?.external_content_urls
                }
            }
        );
    }

    return config;
});

async function getMgDataVariables() {
    if(!props.send?.id) {
        return;
    }

    activity.value = true;

    return await axios.get(route('api.send-mg-data-variables', { send: props.send.id }))
        .then(response => {
            form.options.mg_data_variables = response.data;

            return response.data;
        }).finally(() => {
            activity.value = false;
        });
}

function onConfirmDataVariablesSelection(_: any, context: { close: Function }) {
    form.options.mg_data_variables = selectedVariables.value.slice();
    context.close();
}

function onUpdateModelValueVariablesSelect(value: boolean, variable: MgDataVariable, index: number) {
    const match = selectedVariables.value.find((i: MgDataVariable) => i.id === variable.id);
    if(value && !match) {
        selectedVariables.value.push({ ...variable, value: variable.defaultValue });
    } else if(!value) {
        const indexed = selectedVariables.value.indexOf(match);

        selectedVariables.value.splice(indexed, 1);
    }

    selectedVariables.value = selectedVariables.value.sort((a: MgDataVariable, b: MgDataVariable) => {
        const indexA = props.list.options.mg_data_variables?.findIndex(variable => variable.id === a.id) ?? -1;
        const indexB = props.list.options.mg_data_variables?.findIndex(variable => variable.id === b.id) ?? -1;
        return indexA - indexB;
    });
}

function onUploadFile(field: HTMLInputElement) {
    if(!field.files?.[0]) {
        return;
    }

    const reader = new FileReader();

    reader.readAsText(field.files[0]);

    reader.onload = function () {
        form.html = reader.result;
        form.text = convert(form.html);
    };
}

async function onClickFormat() {
    const encoded = encodeFreemarkerTags(form.html);

    const html = html_beautify(encoded, {
        indent_size: 2,
        end_with_newline: false
    });
    
    form.html = decodeFreemarkerTags(html);

    debounced(() => saveHtml());
}

onMounted(async () => {
    const field = get('field');

    if(bee.value === undefined) {
        canSubmit.value = true;
    }

    if(form.options.auto_generate_text && form.template_json) {
        form.text = props.send?.type === 'bee'
            ? parse(form.template_json)
            : convert(form.html);
    }

    if(!field && !form.name) {
        focus('name');
    }

    if(field) {
        setTimeout(() => {
            const element = document.getElementById(field);
            if(element) {
                const intersectionObserver = new IntersectionObserver((entries) => {
                    let [entry] = entries;
                    if(entry.isIntersecting) {
                        element?.focus({ preventScroll: true });
                    }
                });

                intersectionObserver.observe(element);

                element?.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'center' });
            }
        }, 100);
    }

    await getMgDataVariables();
});
</script>

<template>
    <Confirm
        title="Select Data Variables"
        close-button
        :colors="{
            info: 'bg-rose-100 text-rose-400',
        }"
        trigger="#selectVariables, #selectVariablesLink"
        :icon="VariableIcon"
        @confirm="onConfirmDataVariablesSelection">
        <div
            v-for="(variable, index) in list.options.mg_data_variables?.filter(i => !!i.type)"
            :key="index"
            class="m-1">
            <div class="border-b py-2">
                <CheckboxField
                    :checked="!!form.options.mg_data_variables.find((i: MgDataVariable) => i.id === variable.id)"
                    :label="variable.label"
                    @update:model-value="(value: any) => onUpdateModelValueVariablesSelect(value, variable, index)" />
                <p class="text-neutral-500">
                    {{ variable.helpText }}
                </p>
            </div>
        </div>
    </Confirm>

    <div>
        <FormLayout
            :submit="onSubmit"
            :button-label="form.id ? 'Save' : 'Create'"
            :disabled="!canSubmit || form.processing"
            :activity="form.processing">
            <div class="flex flex-col gap-y-8">
                <Card class="bg-white dark:bg-neutral-950 border border-neutral-200 dark:border-neutral-700">
                    <template #header>
                        <h2 class="p-2 font-bold text-lg border-b border-neutral-200 dark:border-neutral-700">
                            Send Details
                        </h2>
                    </template>
                    <template #body>
                        <FormField
                            label="Name"
                            description="The name of the send.">
                            <InputField
                                id="name"
                                v-model="form.name"
                                name="name"
                                autocomplete="off"
                                :error="form.errors.name"
                                @change="form.validate('name')" />
                        </FormField>
                        <FormField
                            v-if="list.options.enable_html && !send?.id"
                            label="Custom HTML"
                            description="Do you want to use your own custom html?">
                            <LightSwitchField
                                v-model="form.type"
                                class="light-switch-field-md"
                                off-value="bee"
                                on-value="html"
                                @update:model-value="onChangeType" />
                        </FormField>
                        <FormField
                            v-if="!send?.id && form.type === 'bee'"
                            label="Template"
                            description="The template of the send.">
                            <Autocomplete
                                id="template_id"
                                v-model="form.template_id"
                                name="template_id"
                                namespace="templates"
                                only="templates"
                                :badges="(template: Template) => list?.template_id === template.id ? ['Default'] : []"
                                :response="templates"
                                :error="form.errors.template_id"
                                @select="onSelectTemplate">
                                <template #no-results>
                                    <div class="flex justify-center gap-x-2">
                                        <div>
                                            No templates found.
                                        </div>
                                        <Link
                                            v-if="templateCan.create"
                                            class="hover:underline text-rose-500"
                                            :href="route('templates.create', {
                                                agency: agency.slug,
                                                list: list.slug
                                            })">
                                            Create Template
                                        </Link>
                                    </div>
                                </template>
                            </Autocomplete>
                        </FormField>
                        <FormField
                            v-else-if="!send?.id"
                            label="Custom HTML"
                            description="You can optionally upload your own HTML or copy paste in the editor.">
                            <input
                                type="file"
                                accept="text/html, text/htm"
                                @change="onUploadFile($event.target as HTMLInputElement)">
                        </FormField>
                    </template>
                </Card>

                <template v-if="form.id">
                    <Card class="bg-white border dark:bg-neutral-950 border-neutral-200 dark:border-neutral-700">
                        <template #header>
                            <div
                                class="flex justify-between items-center p-2 border-b border-neutral-200 dark:border-neutral-700">
                                <div class="flex flex-col justify-center">
                                    <h2 class="font-bold text-lg">
                                        Editor
                                    </h2>
                                </div>
                                <div class="flex gap-x-4 items-center">
                                    <button
                                        v-if="send?.type === 'bee' && list?.options.smart_merge_tags?.some(item => item.value !== null)"
                                        class="hover:underline text-rose-500"
                                        @click.prevent="bee.toggleMergeTagsPreview()">
                                        Toggle Merge Tags
                                    </button>
                                    <span
                                        v-if="form.id && list?.options.smart_merge_tags?.some(item => item.value !== null)"
                                        class="text-neutral-500">|</span>
                                    <div
                                        v-if="form.id"
                                        title="When enabled, content in the editor will automatically be saved as you make changes."
                                        class="flex items-center justify-end gap-x-4">
                                        <div class="flex items-center justify-end gap-x-2">
                                            <label for="autosave">
                                                Auto Save
                                            </label>
                                            <AutoSave
                                                id="autosave"
                                                :only="['templates']"
                                                :auth-user="authUser" />
                                        </div>
                                        <button
                                            v-if="send?.type === 'html'"
                                            type="button"
                                            class="btn btn-secondary btn-xs"
                                            @click="onClickFormat">
                                            <CodeBracketIcon class="size-4" /> Format
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #body>
                            <Banner
                                v-if="lintErrors.length && send?.type === 'bee'"
                                class="bg-red-600 dark:bg-red-600 text-white rounded-none">
                                <ExclamationTriangleIcon
                                    class="size-6" />
                                <div class="flex items-center justify-between flex-1">
                                    <div>
                                        <div
                                            v-for="(error, i) in lintErrors"
                                            :key="i"
                                            class="flex-1">
                                            {{ error.message }}
                                        </div>
                                    </div>
                                </div>
                            </Banner>

                            <BeeEditor
                                v-if="form.type === 'bee'"
                                ref="bee"
                                v-model="form.template_json"
                                :auth-user="authUser"
                                :list="list"
                                :config="beeConfig"
                                :model="form"
                                @load="onLoadBee"
                                @update:model-value="onChangeBee"
                                @save="onSaveBee" />
                            <Editor
                                v-else-if="form.type === 'html'"
                                v-model:filename="form.name"
                                v-model:content="form.html"
                                :toolbar="false"
                                :theme="theme"
                                :save-button="false"
                                class="[&_.cm-editor]:max-h-[60rem]"
                                @update:content="onChangeHtml" />
                        </template>
                    </Card>

                    <Card class="bg-white border dark:bg-neutral-950 border-neutral-200 dark:border-neutral-700">
                        <template #header>
                            <div
                                class="p-2 flex items-center justify-between border-b border-neutral-200 dark:border-neutral-700">
                                <h2 class="font-bold text-lg">
                                    Text
                                </h2>
                                <div class="flex gap-x-4 items-center">
                                    <div class="text-neutral-500">
                                        {{ form.options.auto_generate_text ? 'Auto Generated' : 'User Edited' }}
                                    </div>
                                    <span class="text-neutral-500">|</span>
                                    <a
                                        href="#"
                                        class="hover:underline text-rose-500"
                                        @click.prevent="resetTextFromHtml">
                                        Reset Text from Editor
                                    </a>
                                </div>
                            </div>
                        </template>
                        <template #body>
                            <Editor
                                ref="textEditor"
                                v-model:content="form.text"
                                :toolbar="false"
                                :theme="theme"
                                :save-button="false"
                                class="[&_.cm-editor]:max-h-[60rem]"
                                :ruleset="{
                                    'spec-char-escape': false
                                }"
                                @update:content="form.options.auto_generate_text = false" />
                            <!-- <Codemirror
                                ref="text"
                                v-model="form.text"
                                :style="{ height: '40em', width: '100%' }"
                                indent-with-tab
                                @change="form.options.auto_generate_text = false" /> -->
                        </template>
                    </Card>

                    <Card class="bg-white border dark:bg-neutral-950 border-neutral-200 dark:border-neutral-700">
                        <template #header>
                            <h2 class="p-2 font-bold text-lg border-b border-neutral-200 dark:border-neutral-700">
                                Details
                            </h2>
                        </template>
                        <template #body>
                            <FormField
                                required
                                label="Subject"
                                description="The subject line of the send.">
                                <InputField
                                    id="subject"
                                    v-model="form.subject"
                                    name="subject"
                                    :error="form.errors.subject" />
                            </FormField>
                            <FormField
                                label="Preheader"
                                description="The text below the subject line that gives a brief preview of the send.">
                                <TextareaField
                                    id="options.preheader"
                                    v-model="form.options.preheader"
                                    name="options.preheader"
                                    autogrow
                                    :error="form.errors['options.preheader']" />
                            </FormField>
                            <FormField
                                required
                                label="From Name"
                                description="The From Name of the send.">
                                <InputField
                                    id="options.mg_from_name"
                                    v-model="form.options.mg_from_name"
                                    name="options.mg_from_name"
                                    :error="form.errors['options.mg_from_name']" />
                            </FormField>
                            <FormField
                                label="Reply-To Address"
                                description="The Reply-To Email Address of the send.">
                                <InputField
                                    id="options.mg_reply_to_address"
                                    v-model="form.options.mg_reply_to_address"
                                    name="options.mg_reply_to_address"
                                    :error="form.errors['options.mg_reply_to_address']" />
                            </FormField>
                        </template>
                    </Card>

                    <Card
                        v-if="list.options.mg_data_variables?.some(i => !!i.type)"
                        class="bg-white border dark:bg-neutral-950 border-neutral-200 dark:border-neutral-700">
                        <template #header>
                            <div
                                class="p-2 flex items-center justify-between border-b border-neutral-200 dark:border-neutral-700">
                                <h2 class="font-bold text-lg">
                                    Data Variables
                                </h2>
                                <!-- add v-if="form.options.mg_data_variables.length" once modal can accept multiple triggers -->
                                <button
                                    id="selectVariablesLink"
                                    type="button"
                                    class="hover:underline text-rose-500 flex gap-1 items-center">
                                    <CursorArrowRaysIcon class="w-5 h-5" /> Select Variables
                                </button>
                            </div>
                        </template>
                        <template #body>
                            <template v-if="activity">
                                <div class="p-6 flex flex-col justify-center items-center gap-2">
                                    <ActivityIndicator
                                        size="sm"
                                        :type="Pulse" />
                                </div>
                            </template>
                            <template v-else-if="!form.options.mg_data_variables.length">
                                <div class="p-6 flex flex-col justify-center items-center gap-2">
                                    No data variables selected.

                                    <!-- <button
                                        id="selectVariablesLink"
                                        type="button"
                                        class="btn btn-xs btn-secondary">
                                        <CursorArrowRaysIcon class="w-4 h-4" /> Select Variables
                                    </button> -->
                                </div>
                            </template>
                            <template v-else>
                                <template
                                    v-for="(item, index) in form.options.mg_data_variables"
                                    :key="index">
                                    <FormField
                                        :label="item.label"
                                        :description="item.helpText">
                                        <Component
                                            v-bind="{
                                                list,
                                                item,
                                                index,
                                                name: `options.mg_data_variables.${index}.value`,
                                                id: `options.mg_data_variables.${index}.value`,
                                                errors: form.errors
                                            }"
                                            :is="fieldTypes[item.type]"
                                            v-if="fieldTypes[item.type]"
                                            v-model="form.options.mg_data_variables[index].value"
                                            :readable-value="form.options.mg_data_variables[index].readableValue"
                                            :formatted-value="form.options.mg_data_variables[index].formattedValue"
                                            @update:readable-value="(value: string) => {
                                                if (form.options.mg_data_variables[index]) {
                                                    form.options.mg_data_variables[index].readableValue = value
                                                }
                                            }"
                                            @update:formatted-value="(value: string) => {
                                                if (form.options.mg_data_variables[index]) {
                                                    form.options.mg_data_variables[index].formattedValue = value
                                                }
                                            }">
                                            <template #reset>
                                                <button
                                                    type="button"
                                                    title="Reset to default"
                                                    class="btn btn-neutral-300 dark:btn-neutral-700"
                                                    @click.prevent="item.value = item.defaultValue">
                                                    <ArrowUturnLeftIcon class="h-5 w-5" />
                                                </button>
                                            </template>
                                        </Component>
                                    </FormField>
                                </template>
                            </template>
                        </template>
                    </Card>
                </template>
            </div>
        </FormLayout>
    </div>
</template>