import { createInertiaApp } from '@inertiajs/vue3';
import { ActivityIndicatorPlugin, Spinner } from '@vue-interface/activity-indicator';
import { TooltipPlugin } from '@vue-interface/tooltip';
import { createApp, h } from 'vue';
import Toast, { POSITION } from 'vue-toastification';
import Layout from './Layouts/Layout.vue';
import { useRoutes, type RawRoute } from './composables/routes';
import Echo from './plugins/Echo';

createInertiaApp({
    progress: {
        delay: 500,
        includeCSS: true,
        showSpinner: false,
    },
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
        const page: any = pages[`./Pages/${name}.vue`];

        if(!page) {
            throw new Error(`Page not found: ./Pages/${name}.vue`);
        }

        page.default.layout = page.default.layout || Layout;

        return page;
    },
    setup({ el, App, props, plugin }) {
        useRoutes(props.initialPage.props?.routes as RawRoute[] ?? []);
    
        createApp({
            render: () => h(App, props)
        })
            .use(plugin)
            .use(Echo, props.initialPage.props)
            .use(Toast, {
                position: POSITION.BOTTOM_RIGHT,
                showCloseButtonOnHover: true,
                filterBeforeCreate: (toast: any, toasts: any) => {
                    if(toasts.filter((t: any) => t.content === toast.content).length !== 0) {
                        return false;
                    }
                    return toast;
                }
            })
            .use(ActivityIndicatorPlugin, {
                indicators: {
                    Spinner
                }
            })
            .use(TooltipPlugin)
            .mount(el);
    },
});