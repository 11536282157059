<script lang="ts" setup>
import { SelectField } from '@vue-interface/select-field';
import { ref } from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = withDefaults(defineProps<{
    defaultRange?: string|number,
    ranges: any,
    title: string,
}>(), {

});

let rangeValue =  ref(props.defaultRange);

function onUpdate() {
    emit('update:modelValue', rangeValue.value);
}
</script>

<template>
    <div
        class="flex flex-col">
        <div class="flex justify-between">
            <div>
                <h3 class="bold text-secondary mr-6 truncate dark:text-neutral-300">
                    {{ props.title }}
                </h3>
            </div>
            <SelectField
                v-if="ranges && ranges.length"
                v-model="rangeValue"
                class="select-field-sm min-w-[5rem]"
                @update:model-value="onUpdate">
                <option
                    v-for="{label, value} in props.ranges"
                    :key="value"
                    :selected="value === rangeValue"
                    :value="value">
                    {{ label }}
                </option>
            </SelectField>
        </div>
    </div>
</template>