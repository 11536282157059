<script lang="ts" setup>
import { ref, watchEffect } from 'vue';

const props = withDefaults(defineProps<{
    show?: boolean;
}>(), {
    show: false,
});

let isShowing = ref(props.show);

watchEffect(() => {
    isShowing.value = props.show;
});

function open() {
    isShowing.value = true;
}

function close() {
    isShowing.value = false;
}

function toggle() {
    isShowing.value = !isShowing.value;
}

</script>

<template>
    <slot
        name="header"
        v-bind="{ toggle, isShowing, close, open }" />
    <slot
        v-if="isShowing === true"
        name="body" />
</template>