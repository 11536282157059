<script setup lang="ts">
import { ComputerDesktopIcon, DevicePhoneMobileIcon } from '@heroicons/vue/24/outline';
// import { encodeHtmlEntities } from 'capsule-capsulate';
// import { parse } from 'capsule-lint';
import { computed, nextTick, onMounted, ref, watch, watchEffect } from 'vue';

const props = defineProps<{
    height?: number,
    width?: number,
    html: string,
    title?: string
}>();

type Dimension = {
    height?: number,
    width?: number
}

type Coordinates = {
    latitude: boolean|number,
    longitude: boolean|number
}

const container = ref<Dimension>({
    height: undefined,
    width: undefined
});

const content = ref<Dimension>({
    height: props.height,
    width: props.width
});

const dragging = ref<Dimension & Coordinates>({
    height: undefined,
    width: undefined,
    latitude: false,
    longitude: false
});

const isDragging = ref(false);
const isDiagonal = ref(false);
const startingLatitude = ref<number>();
const startingLongitude = ref<number>();
const el = ref<HTMLDivElement>();
const containerEl = ref<HTMLDivElement>();
// const html = computed(() => {
//     let src = props.html;

//     for(const item of src) {
//         if(!item.match(/<\/?#\w+/)) {
//             continue;
//         }
        
//         src = src.replace(item, item);
//     }

//     return src;
// });

const formattedDimension = computed(() => {
    const width = content.value.width ?? container.value.width ?? 0;
    const height = content.value.height ?? container.value.height ?? 0;

    return { width: width.toFixed(), height: height.toFixed() };
});

function dimensions(el?: Element) {
    if(!el) {
        return { height: 0, width: 0  };
    }

    const { height, width } = getComputedStyle(el);

    return {
        height: parseFloat(height.replace('px', '')),
        width: parseFloat(width.replace('px', ''))
    };
}
const isDesktop = computed(() => {
    return !content.value.width || content.value.width > 769;
});

const isMobile = computed(() => {
    return content.value.width && content.value.width < 480;
});

type MousedownFunctions = 'onMousemoveLatitude' | 'onMousemoveLongitude';

function onMousedown(e: MouseEvent, bind: MousedownFunctions|MousedownFunctions[]) {
    const events = Array.isArray(bind) ? bind : [bind];

    const map = {
        onMousemoveLatitude,
        onMousemoveLongitude
    };

    events.forEach(event => {
        document.documentElement.addEventListener('mousemove', map[event]);
    });

    const { height, width } = dimensions(containerEl.value);

    dragging.value.height = height;
    dragging.value.width = width;

    isDragging.value = true;
    isDiagonal.value = events.length > 1;

    startingLatitude.value = e.screenY;
    startingLongitude.value = e.screenX;

    document.documentElement.addEventListener('mouseup', onMouseup);
}

function onMousemoveLatitude(e: MouseEvent) {
    dragging.value.latitude = true;

    content.value.height = Math.max(345, (dragging.value.height ?? 0) - ((startingLatitude.value ?? 0) - e.screenY));
}

function onMousemoveLongitude(e: MouseEvent) {
    dragging.value.longitude = true;
    
    content.value.width = Math.max(345, Math.min(
        props.width || (container.value.width ?? 0), (dragging.value.width ?? 0) - (((startingLongitude.value ?? 0) - e.screenX) * 2)
    ));
}

function onMouseup() {
    isDragging.value = false;
    isDiagonal.value = false;
    dragging.value.latitude = false;
    dragging.value.longitude = false;
    
    document.documentElement.removeEventListener('mousemove', onMousemoveLatitude);
    document.documentElement.removeEventListener('mousemove', onMousemoveLongitude);
    document.documentElement.removeEventListener('mousemove', onMouseup);
}

function reset() {
    container.value = dimensions(containerEl.value);

    if(content.value.width && container.value.width && content.value.width > container.value.width) {
        content.value.width = container.value.width;
    }

    if(container.value.height && container.value.height > window.innerHeight) {
        content.value.height = undefined;
    }
}

watch(dragging, ({ latitude, longitude }) => {
    if(latitude && longitude) {
        document.body.style.cursor = 'nwse-resize';
    }
    else if(latitude) {
        document.body.style.cursor = 'ns-resize';
    }
    else if(longitude) {
        document.body.style.cursor = 'ew-resize';
    }
    else {
        document.body.style.cursor = 'inherit';
    }
}, { deep: true });

watchEffect(() => {   
    if(dragging.value.longitude || dragging.value.longitude) {
        return;
    }

    if(content.value.width === container.value.width) {
        content.value.width = undefined;
    }

    if(content.value.height === container.value.height) {
        content.value.height = undefined;
    }
});


onMounted(() => nextTick(reset));

window.onresize = reset;
</script>

<template>
    <div
        ref="el"
        class="h-full">
        <div class="pb-3">
            <div class="flex w-full justify-between items-start">
                <slot name="left-actions">
                    <div />
                </slot>
                <div class="flex gap-1 flex-1 justify-end">
                    <div class="text-xs p-2">
                        {{ formattedDimension.width }}px &times; {{ formattedDimension.height }}px
                    </div>
                    <button
                        class="btn btn-sm"
                        :class="isMobile ? 'btn-rose-500' : 'btn-neutral-600'"
                        @click="content.width = 345, content.height = 650">
                        <DevicePhoneMobileIcon class="h-6 w-6" />
                    </button>
                    <button
                        class="btn btn-sm"
                        :class="isDesktop ? 'btn-rose-500' : 'btn-neutral-600'"
                        @click="content.width = container.width, content.height = 800">
                        <ComputerDesktopIcon class="h-6 w-6" />
                    </button>
                </div>
            </div>
        </div>
        <div class="relative h-full bg-neutral-100 dark:bg-neutral-900">
            <div
                ref="containerEl"
                :style="{width: content.width ? `${content.width}px` : undefined, height: content.height ? `${content.height}px` : '800px'}"
                class="relative mx-auto grid w-full grid-cols-[auto_1.25rem] grid-rows-[auto_1.25rem] gap-0">
                <div class="mx-auto flex h-full w-full bg-white">
                    <iframe
                        ref="iframe"
                        :srcdoc="html"
                        class="h-full w-full overflow-hidden"
                        :class="{'pointer-events-none': isDragging}" />
                </div>
                <div
                    class="bg-neutral-200 hover:bg-neutral-300 group-hover:opacity-100 dark:bg-neutral-800 dark:hover:bg-neutral-700"
                    :class="{'bg-neutral-300 dark:bg-neutral-700': isDiagonal || dragging.longitude, 'hover:cursor-ew-resize': !dragging.longitude && !dragging.latitude}"
                    @mousedown.prevent="e => onMousedown(e, 'onMousemoveLongitude')">
                    <div class="flex h-full w-full items-center justify-center gap-1">
                        <div class="h-6 w-[2px] rounded bg-neutral-400 dark:bg-neutral-100" />
                        <div class="h-6 w-[2px] rounded bg-neutral-400 dark:bg-neutral-100" /> 
                    </div>
                </div>
                <div
                    class="bg-neutral-200 hover:bg-neutral-300 group-hover:opacity-100 dark:bg-neutral-800 dark:hover:bg-neutral-700"
                    :class="{'bg-neutral-300 dark:bg-neutral-700': isDiagonal || dragging.latitude, 'hover:cursor-ns-resize': !dragging.longitude && !dragging.latitude}"
                    @mousedown.prevent="e => onMousedown(e, 'onMousemoveLatitude')">
                    <div class="flex h-full w-full flex-col items-center justify-center gap-1">
                        <div class="h-[2px] w-6 rounded bg-neutral-400 dark:bg-neutral-100" />
                        <div class="h-[2px] w-6 rounded bg-neutral-400 dark:bg-neutral-100" /> 
                    </div>
                </div>
                <div
                    class="bg-neutral-200 hover:bg-neutral-300 group-hover:opacity-100 dark:bg-neutral-800 dark:hover:bg-neutral-700"
                    :class="{'bg-neutral-300 dark:bg-neutral-700': isDiagonal, 'hover:cursor-nwse-resize': !dragging.longitude && !dragging.latitude}"
                    @mouseenter="() => !isDragging && (isDiagonal = true)"
                    @mouseleave="() => !isDragging && (isDiagonal = false)"
                    @mousedown.prevent="e => onMousedown(e, ['onMousemoveLatitude', 'onMousemoveLongitude'])">
                    <div class="relative flex h-full w-full -rotate-45 flex-col items-center justify-center gap-1">
                        <div class="h-[2px] w-1/2 rounded bg-neutral-400 dark:bg-neutral-100" />
                        <div class="h-[2px] w-1/2 rounded bg-neutral-400 dark:bg-neutral-100" /> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>